import Layout from "../../components/global/layout";
import Pagination from "react-pagination-js";
// import "./style.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { FiEdit3, FiFilter, FiPlus } from "react-icons/fi";
import { PiFileCsv } from "react-icons/pi";
import { AiOutlineEye } from "react-icons/ai";
import { BsTrash3 } from "react-icons/bs";
import { FaCopy } from "react-icons/fa";
import datepipeModel from "../../models/datepipemodel";
import { HiOutlineArrowDown, HiOutlineSearch } from "react-icons/hi";
import SelectDropdown from "../../components/common/SelectDropdown";
import environment from "../../environment";
import { MdClear } from "react-icons/md";
import { CostingTourConfig, CostingTourURL } from "./Api";
import { Tooltip } from "antd";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Fragment } from "react";

const Html = ({
  filter,
  reset,
  EnablestatusChange,EnableChangeStatus,
  setFilter,
  edit,
  copy,
  view,
  ChangeStatus,
  statusChange,
  pageChange,
  deleteItem,
  filters,
  loaging,
  data,
  exportfun,
  total = { total },
  sortClass,
  urlAllow,
  sorting,
}) => {
  const user = useSelector((state) => state.user);
  return (
    <Layout>
      <div className="max-w-[1420px]">
        <div className="flex items-center justify-between mb-6">
          <div className="flex flex-col gap-1">
            <h4 className="text-2xl font-semibold text-[#111827]">
              {CostingTourConfig.ListHeader}
            </h4>
            <p className="text-sm font-normal text-[#75757A]">
              Here you can see all about your {CostingTourConfig.ListHeader}
            </p>
          </div>
          <Link
            className="!px-3 text-sm font-normal hover:!text-white hover:no-underline text-white h-10 flex items-center justify-center gap-2 bg-primary rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed"
            to={CostingTourURL.add}>
            <FiPlus className="text-xl text-white" />
            Add {CostingTourConfig.AddHear}
          </Link>
        </div>

        <div className="bg-white shadow-box rounded-large w-full border border-grey">
          <div className="flex items-start gap-3 p-6">
          
            <div className="flex flex-wrap items-center gap-2.5 justify-end ml-auto">
             <div className="dropdown1 addDropdown1 mr-2">
                       <SelectDropdown
                  isSingle={true}
                  id="statusDropdown2"
                  displayValue="name"
                  placeholder="All"
                  intialValue={filters.isEnabled}
                  result={(e) => EnableChangeStatus(e.value)}
                  options={[
                    { id: "true", name: "Enable" },
                    { id: "false", name: "Disable" },
                  ]}
                />

                    </div>
              <div>
                <SelectDropdown
                  isSingle={true}
                  id="statusDropdown"
                  displayValue="name"
                  placeholder="All status"
                  intialValue={filters.status}
                  result={(e) => ChangeStatus(e.value)}
                  options={[
                    { id: "active", name: "Active" },
                    { id: "deactive", name: "Inactive" },
                  ]}
                />
              </div>
              {filters.search || filters.status||filters?.isEnabled ? (
                <>
                  <button
                    className="!px-4 text-sm font-normal text-white h-10 flex items-center justify-center gap-2 bg-primary rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed"
                    onClick={(e) => reset()}>
                    <MdClear className="text-xl text-white" />
                    Reset
                  </button>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="scrollbar w-full overflow-auto">
            <table className="w-full">
              <thead className="border-y border-[#EAECF0]">
                <tr className=" border-y border-[#EAECF0]">
                  <th
                    scope="col"
                    className="cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3"
                    onClick={(e) => sorting("title")}>
                    Title
                    <HiOutlineArrowDown className="shrink-0 inline text-sm" />
                  </th>
                  <th
                    scope="col"
                    className="cursor-pointer text-[#82838B] !border-l-0 text-center font-normal text-sm !border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-3"
                    onClick={(e) => sorting("createdAt")}>
                    Date Created
                    <HiOutlineArrowDown className="shrink-0 inline text-sm" />
                  </th>
                  <th
                    scope="col"
                    className="cursor-pointer text-[#82838B] text-center !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-3"
                    onClick={(e) => sorting("updatedAt")}>
                    Date Updated
                    <HiOutlineArrowDown className="shrink-0 inline text-sm" />
                  </th>
                  <th
                    scope="col"
                    className="cursor-pointer text-[#82838B] text-center !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-3">
                    Enable/Disable
                  </th>
                  <th
                    scope="col"
                    className="cursor-pointer text-[#82838B] text-center !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-3">
                    Status{" "}
                  </th>
                  <th
                    scope="col"
                    className="cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-3 text-center">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {!loaging &&
                  data &&
                  data.map((itm, i) => {
                    return (
                      <tr key={i}>
                        <td
                          className="hover:underline hover:opacity-80 !text-primary !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border border-[#EAECF0]"
                          onClick={(e) => view(itm.id)}>
                          {itm.title || "--"}
                        </td>
                        <td className="  !text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]">
                          {datepipeModel.date(
                            itm.createdAt,
                            user?.companyDateFormat || "--"
                          )}
                        </td>

                        <td className="  !text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]">
                          {datepipeModel.date(
                            itm.updatedAt,
                            user?.companyDateFormat
                          )}
                        </td>
                                <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]'>
                                            {/* <div className={`user_hours ${itm.status}`} */}
                                            <div className={`user_hours ${itm.isEnabled}`}
                                                onClick={() => EnablestatusChange(itm)}>
                                                <Tooltip placement="top" title="Enable / Disable">
                                                    <span className='contract'>
                                                        {itm.isEnabled ? 'Enable' : 'Disable'}
                                                    </span>
                                                </Tooltip>
                                            </div></td>
                        <td className="  !text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]">
                          <div onClick={() => statusChange(itm)}>
                            {itm.status == "deactive" ? (
                              <div className="bg-[#EEE] text-sm !px-3 h-[30px] flex items-center justify-center border border-[#EBEBEB] text-[#3C3E49A3] !rounded">
                                Inactive
                              </div>
                            ) : (
                              <div className="bg-[#ECF4EF] text-xs !px-3 h-[30px] flex items-center justify-center border border-[#94D5AE] text-[#11A74D] !rounded">
                                Active
                              </div>
                            )}
                          </div>
                        </td>
                        {/* commisionRate */}
                        {/* dropdown */}
                        <td className="  !text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]">
                          <div className="flex items-center justify-center gap-1.5">
                          
<div
                                  onClick={(e) => edit(itm.id)}
                                  className="border cursor-pointer !border-[#E9253129] hover:opacity-70 rounded-large bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl"
                                  title="Edit">
                                  <FiEdit3 />
                                </div>
<div
                                  onClick={() => deleteItem(itm.id)}
                                  className="border cursor-pointer !border-[#E9253129] hover:opacity-70 rounded-lg bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl"
                                  title="Delete">
                                  <BsTrash3 />
                                </div>
<button
                              onClick={() => copy(itm.id)}
                              className="border !border-[#94D5AE] rounded-lg bg-[#ECF4EF] hover:opacity-70 w-10 h-10 text-[#11a74da4] flex items-center justify-center text-xl"
                              title="Copy">
                              <FaCopy />
                            </button>
                          </div>
                        </td>
                        {/* end */}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>

          {!loaging && total == 0 ? (
            <div className="py-3 text-center">No Data</div>
          ) : (
            <></>
          )}
          {!loaging && total > filters.count ? (
            <div className="paginationWrapper flex items-center justify-between p-6">
              <div className="table_rowings w-96">
                {/* <p className="text-gray-600  text-md"> {PaginationTextView(filters.page, filters.count, total)}</p> */}
              </div>
              <Pagination
                currentPage={filters.page}
                totalSize={total}
                sizePerPage={filters.count}
                changeCurrentPage={pageChange}
              />
            </div>
          ) : (
            <></>
          )}
        </div>

        {loaging ? (
          <div className="loaderDiv text-center py-4">
            <img
              src="/assets/img/loader.gif"
              width="auto"
              height="auto"
              className="pageLoader"
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </Layout>
  );
};

export default Html;
