import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import Layout from '../../../components/global/layout';
import ApiClient from '../../../methods/api/apiClient';
import loader from '../../../methods/loader';
import './style.scss';
import { useSelector } from 'react-redux';
import datepipeModel from "../../../models/datepipemodel";
import SelectDropdown from '../../../components/common/SelectDropdown';
import { toast } from 'react-toastify';
import moment from 'moment';
import methodModel from '../../../methods/methods';
import pipeModel from '../../../models/pipeModel';
import { HiOutlineArrowDown } from 'react-icons/hi';
import { Tooltip } from 'antd';
import { AiOutlineEye } from 'react-icons/ai';
import { FiEdit3 } from 'react-icons/fi';
import { BsDownload } from 'react-icons/bs';
import environment from '../../../environment';
import axios from 'axios';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
const UserDetail = (p) => {
    const history = useNavigate()
    const user = useSelector(state => state.user)
    const { id, userId } = useParams()
    const [data, setData] = useState()
    const [planDetails, setplanDetails] = useState()
    const [trailDays, settrailDays] = useState()
    const [invoices, setInvoices] = useState([])
    const [CustomerUsers, setCustomerUsers] = useState([]);

    const getDetail = (did) => {
        loader(true)
        ApiClient.get(`api/affiliate`, { id: did }).then(res => {
            if (res.success) {
                setData(res.data)
                settrailDays('')
            }
            loader(false)
        })
    }
    const back = () => {
        history(-1)
    }

    const getPlanDetails = () => {
        ApiClient.get(`api/my/plan?id=${id}`).then(res => {
            if (res.success) {
                setplanDetails(res.data)
            }
        })
    }

    useEffect(() => {
        getDetail(userId ? userId : id)
        getPlanDetails()
        getInvoices()
    }, [id, userId])

   



    const view = (id) => {
        history("/customer/view/" + id)

        window.scrollTo(0, 0)
        setTimeout(() => {
            getDetail(id)
            getPlanDetails()
            GetCustomerUsers();
            getInvoices()

        }, 100);
    }
    const handleTrailDays = () => {
        let payload = {
            user_id: userId ? userId : id,
            extend_days: Number(trailDays)
        }
        ApiClient.put(`api/extend-trial`, payload).then(res => {
            if (res.success) {
                // toast.success(res.message)
                getPlanDetails()
                getDetail(userId ? userId : id)
            }
        })
    }

    const getInvoices = () => {
        let f = { userId: userId ? userId : id }
        ApiClient.get('api/invoices/list', f).then(res => {
            if (res.success) {
                setInvoices(res.data)
            }
        })
    }


    const GetCustomerUsers = () => {
        ApiClient.get(`api/account/users?addedBy=${id}`).then(res => {
            if (res.success) {
                setCustomerUsers([...res?.data]);
            }
        })
    }
    useEffect(() => {
        GetCustomerUsers()
    }, [id])
    const sanitizeAndParseHtml = (dirtyHtml) => {
        try {
          const trustedTypesPolicy = {
            createHTML(s) { return s; },
            createScriptURL(s) { return s; },
          };
      
          const cleanHtml = DOMPurify.sanitize(dirtyHtml, {
            TRUSTED_TYPES_POLICY: trustedTypesPolicy,
          });
      
          const parsedHtml = parse(cleanHtml);
      
          return parsedHtml;
        } catch (error) {
          console.error('Error sanitizing and parsing HTML:', error);
          return null;
        }
      };

    return (
        <Layout>
            <div className='bg-white shadow-box rounded-large w-full border border-grey p-4 mt-6'>
               
                <div className="grid grid-cols-12 gap-4">
                    <div className="sideclass col-span-12 md:col-span-12">


                <div className="flex items-center mb-8">
                    <div>
                        <a className='!px-4  py-2 flex items-center justify-center  rounded-full shadow-btn hover:bg-[#F3F2F5] border border-grey  transition-all    mr-3 h-12 w-12' to="/users" onClick={back}>   <i className="fa fa-angle-left text-lg"></i></a>
                    </div>
                    <div>
                        <h3 className="text-2xl font-semibold text-[#111827]">Dazhboards Affiliate Details</h3>
                        <p className="text-sm font-normal text-[#75757A]">Here you can see all about your  Affiliate Details</p>
                    </div>
                </div>



                 

                        <div className="grid grid-cols-12 gap-4">
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Name</label>
                                <div className='profiledetailscls'>{data && data.fullName}</div>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Email</label>
                                <div className='profiledetailscls'>{data && data.email || '--'}</div>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Mobile No</label>
                                <div className='profiledetailscls'>{data?.dialCode ? <>({data?.dialCode && data?.dialCode}) {data && data.mobileNo}</> : <>--</>}</div>
                            </div>
                            {data?.channels && <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Channels</label>
                                <div className='profiledetailscls'>{data?.channels?.map((itm) => itm?.name).join(",") || "--"}</div>
                            </div>}
                            {data?.affiliate_types && <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Affiliate Type</label>
                                <div className='profiledetailscls'>{data?.affiliate_types?.map((itm) => itm?.name).join(",") || "--"}</div>
                            </div>}
                            {data?.website && <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Website</label>
                                <div className='profiledetailscls'>{data?.website || "--"}</div>
                            </div>}
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">About Us</label>
                                <div className='profiledetailscls' 
                                
                                >{sanitizeAndParseHtml(data && data.aboutUs || "--")}</div>
                            </div>
                            {data?.social_profiles.length > 0 && <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Social Platforms</label>
                                {data?.social_profiles.map((itm) => <><div className='profiledetailscls'>{itm?.name || "--"}
                                </div>
                                    <div className='profiledetailscls'>{itm?.url || "--"}
                                    </div></>)}
                            </div>}
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Image</label>
                                <img className="h-10 w-10 rounded mr-2" src={methodModel.noImg(data?.image ? data?.image : '')} />                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Layout >

    );
};

export default UserDetail;
