import React, { useState } from 'react';
import Layout from '../../components/global/layout';
import Pagination from "react-pagination-js";
import './style.scss';
import { Link } from 'react-router-dom';
import methodModel from '../../methods/methods';
import datepipeModel from '../../models/datepipemodel';
import SelectDropdown from '../../components/common/SelectDropdown';
import DateRangePicker from '../../components/common/DateRangePicker';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Tooltip } from "antd";
import { PiFileCsv } from "react-icons/pi";
import { HiOutlineArrowDown } from "react-icons/hi";
import { FiEdit3, FiPlus } from "react-icons/fi";
import { AiOutlineEye } from "react-icons/ai";
import { BsTrash3 } from "react-icons/bs";
import { FaCopy } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { IoMdCheckmark } from "react-icons/io";
import statusModel from '../../models/status.model';
import MultiSelectDropdown from '../../components/common/MultiSelectDropdown';

const Html = ({
    sortClass,
    sorting,
    tab,
    tabChange,
    ChangeStatus,
    statusChange,
    pageChange,
    deleteItem,
    dynamicStyle = false,
    className = null,
    filters,
    filter,
    loaging,
    data,
    exportfun,
    edit,
    countries,
    view,
    total,
    plans,
    statusVerified,
    reset,
    channelsCategories,
    handleChannelSelection,
    affiliateTypeCategories
}) => {

   
    return (
        <Layout>
            <div className="flex justify-between items-center mb-6">


                <div>
                    <h3 className="text-2xl font-semibold text-[#111827]">
                        Dazhboards Affiliates
                    </h3>
                    <p class="text-sm font-normal text-[#75757A]">Here you can see all about your   Dazhboards Affiliates</p>
                </div>


                <div className="flex">
                    <div className="buttons_Section filterFlex">

                        {/* <button className="!px-2.5 text-[#3C3E49] text-sm font-normal py-2.5 flex items-center justify-center gap-2 bg-[#fff] rounded-large shadow-btn hover:bg-[#F3F2F5] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed" onClick={() => exportfun()}>
                            <PiFileCsv className="text-typo text-xl" />  Export CSV
                        </button> */}
                        <Link className="bg-primary leading-10 mr-3 h-10 flex items-center shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-large gap-2" to={`/affiliates/add`}>
                            <FiPlus className="text-xl text-white" />  Create new
                        </Link>


                    </div>

                    <div className='icons_tab'>
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation" >
                                <button title='Grid' class={`nav-link ${tab == 'grid' ? 'active' : ''}`} id="employee-tab" onClick={e => tabChange('grid')}>
                                    <i className="fa fa-th"></i> 
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button title='List' class={`nav-link staff ${tab == 'list' ? 'active' : ''}`} id="staff-tab" onClick={e => tabChange('list')}>
                                    <i className="fa fa-list"></i>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>


            <div className='bg-white shadow-box rounded-large w-full border border-grey mt-6'>
                <div className='selcitng p-4'>
                    <div className='flex justify-between items-center gap-4'>

                        <div className='flex flex-wrap gap-2'>
                            <div className=''>
                                <MultiSelectDropdown
                                    // isSingle={true}
                                    id="statusDropdown"
                                    displayValue="name"
                                    placeholder='All Channels'
                                    intialValue={filters.channels}
                                    theme='search'
                                    result={(e) =>
                                        handleChannelSelection(e.value)
                                      }
                                    options={channelsCategories}
                                />
                            </div>
                            <div className=''>
                                <MultiSelectDropdown
                                    isSingle={true}
                                    id="statusDropdown"
                                    displayValue="name"
                                    placeholder='All Affiliate Type'
                                    intialValue={filters.affiliate_types}
                                    theme='search'
                                    result={(e) =>
                                        filter({affiliate_types:e.value})
                                      }
                                    options={affiliateTypeCategories}
                                />
                            </div>
                            <div className='flex items-center gap-1'>



                                <Menu as="div" className="relative  list_box_active_state ml-auto">
                                    <div>
                                        <Menu.Button className="inline-flex w-full border justify-center gap-x-1.5 rounded-large bg-white px-3 py-2.5 text-sm font-normal  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200">
                                            {filters?.status ? filters?.status : 'All Status'}

                                            <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </Menu.Button>
                                    </div>

                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className={`${dynamicStyle ? "" : "max-h-60"}  focus:!outline-[#481196] focus:!outline text-sm absolute z-40 ${className ? className : " min-w-[260px]"
                                            }  right-0 shadow-lg !py-2 !mt-1.5 overflow-auto bg-white  rounded-large scrollbar`}>
                                            <div className="mt-2">
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <a className={filters?.status == '' ? 'text-gray-700 block px-4 py-2 text-sm active' : 'text-gray-700 block px-4 py-2 text-sm'} onClick={() => ChangeStatus("")}>All Status</a>
                                                    )}
                                                </Menu.Item>
                                                {statusModel.verifiedStatus.map((itm) => (<Menu.Item>
                                                    {({ active }) => (
                                                        <a className={filters?.approved == itm?.id ? 'text-gray-700 block px-4 py-2 text-sm active' : 'text-gray-700 block px-4 py-2 text-sm'} onClick={() => ChangeStatus(itm?.id)} >{itm?.name}</a>
                                                    )}
                                                </Menu.Item>))}

                                            </div>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                                {(filters?.status || filters.affiliate_types || filters.channels?.length > 0) && <button className='bg-primary leading-10  h-10 shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-large' onClick={reset}>Reset</button>
                                }
                            </div>


                        </div>

                     
                    </div>


                    <div className="dropdown addDropdown">

                    </div>

                </div>

                <div className='all_dat_style'>
                    {tab == 'grid' ? <>
                        <div className="cardList">
                            <div className='grid grid-cols-12 gap-4'>
                                {!loaging && data && data.map((itm, i) => {
                                    return <div className='col-span-12 md:col-span-6 xl:col-span-4 mb-4'>
                                        <div className='new_cards'>
                                            <div className='user_card'>
                                                <div className='user_detail' onClick={e => view(itm.id)}>
                                                    <img src={methodModel.userImg(itm.image)} className="user_imgs" />
                                                    <div className='user_name'>
                                                        <h4 className='user'>
                                                            {itm.fullName}
                                                        </h4>
                                                        <p className='user_info'>
                                                            {itm.email}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className={`user_hours ${itm.status}`} onClick={() => statusChange(itm)}>
                                                    <span className='contract'>
                                                        {itm.status}
                                                    </span>
                                                </div>
                                            </div>

                                            <div className='user_proff user_proff1'>
                                                <div className='id_name'>
                                                    <ul className='user_list'>
                                                        <li className='list_name'>
                                                            <a className='id'>
                                                                Role
                                                            </a>
                                                        </li>
                                                        <li className='list_name'>
                                                            <a className='id'>
                                                                Phone number
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className='detail_list'>
                                                    <ul className='user_list'>
                                                        <li className='list_names'>
                                                            <a className='id_name' onClick={e => view(itm.id)}>
                                                                {itm.role?.name}
                                                            </a>
                                                        </li>
                                                        <li className='list_names'>
                                                            <a className='id_name' onClick={e => view(itm.id)}>
                                                                <span className='call_icon'></span>
                                                                {itm.mobileNo ?
                                                                    <>
                                                                        <i class="fa fa-phone" aria-hidden="true"></i>
                                                                        {itm.dialCode} {itm.mobileNo}
                                                                    </>
                                                                    :
                                                                    ''
                                                                }
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                    </> : <>
                        <div className=" w-full bg-white rounded-lg ">
                            <div className="scrollbar w-full overflow-auto">


                                <table class="w-full">
                                    <thead className='border-b border-[#EAECF0]'>
                                        <tr className='border-b border-[#EAECF0]'>
                                            <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3' onClick={e => sorting('fullName')}>Contact Person<span className='ml-2'><HiOutlineArrowDown className="shrink-0 inline text-sm" /></span></th>
                                            <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3' onClick={e => sorting('affiliate_type')}>Affiliate Type<span className='ml-2'> <HiOutlineArrowDown className="shrink-0 inline text-sm" /></span></th>
                                            <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3' >Channels</th>
                                            {/* <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3' onClick={e => sorting('companyAddress')}>Account Status</th> */}
                                            <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3' onClick={e => sorting('companyAddress')}>Account Verification Status</th>
                                            <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3' >Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!loaging && data && data.map((itm, i) => {
                                            return <tr className=''>
                                                <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]' onClick={e => view(itm.id)}>
                                                    <div className='user_detail'>
                                                        <img src={methodModel.userImg(itm.image)} className="user_imgs" />
                                                        <div className='user_name'>
                                                            <h4 className='user'>
                                                                {itm.fullName}
                                                            </h4>
                                                            <p className='user_info'>
                                                                {itm.email}
                                                            </p>
                                                            <p className='user_info'>
                                                                {itm.mobileNo ? <>+{itm.mobileNo}</> : ''}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>{itm.affiliate_types?.map((itm) => itm?.name).join(",") || '--'}</td>
                                                <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]'>{itm.channels?.map((itm) => itm?.name).join(",") || '--'}</td>
                                                {/* <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]'>
                                                    <div className={`user_hours ${itm.status}`} onClick={() => statusChange(itm)}>
                                                        <span className='contract'>
                                                            {itm.status == 'deactive' ? 'Inactive' : 'Active'}
                                                        </span>
                                                    </div>
                                                </td> */}
                                                <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]'>{itm.approved || '--'}</td>
                                                <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]'>
                                                    <div className="flex items-center justify-center gap-1.5">
                                                        {itm?.approved == 'pending' ? <div className='flex gap-1 items-center'>
                                                            <Tooltip placement="top" title="Approved">
                                                                <button onClick={() => {
                                                                    statusVerified("approved", itm?.id)
                                                                }} className="border border-[#EBEBEB] text-white rounded-lg bg-[#008000a3] hover:opacity-70 w-10 h-10 text-typo flex items-center justify-center text-xl ">
                                                                    <IoMdCheckmark />
                                                                </button>
                                                            </Tooltip>
                                                            <Tooltip placement="top" title="Rejected">
                                                                <button onClick={() => statusVerified("unapproved", itm?.id)} className="border border-[#EBEBEB] rounded-lg bg-[#ff5151]  text-white hover:opacity-70 w-10 h-10 text-typo flex items-center justify-center text-xl">
                                                                    <IoMdClose />
                                                                </button>
                                                            </Tooltip>

                                                        </div> :
                                                            itm?.approved == 'unapproved' ?
                                                                <div>
                                                                    <Tooltip placement="top" title="Rejected">
                                                                        <div className="border border-[#EBEBEB] rounded-lg bg-[#ff5151] text-white hover:opacity-70 w-10 h-10 text-typo flex items-center justify-center text-xl"><IoMdClose /></div>
                                                                    </Tooltip>
                                                                </div> :
                                                                <div>
                                                                    <Tooltip placement="top" title="Approved">
                                                                        <div className="border border-[#EBEBEB] rounded-lg bg-[#008000a3] text-white hover:opacity-70 w-10 h-10 text-typo flex items-center justify-center text-xl"><IoMdCheckmark /></div>
                                                                    </Tooltip>
                                                                </div>
                                                        }
                                                        {/* <Tooltip placement="top" title="View">
                                                            <a className='border border-[#EBEBEB] rounded-large bg-[#FAFAFA] hover:opacity-70 w-10 h-10 text-typo flex items-center justify-center text-xl' title="View" onClick={e => view(itm.id)}>
                                                                <AiOutlineEye />
                                                            </a>
                                                        </Tooltip> */}
                                                        {itm?.approved == 'approved' && <Tooltip placement="top" title="Edit">
                                                           

                                                            <a className="border cursor-pointer border-primary hover:opacity-70 rounded-large bg-[#48119614] w-10 h-10 !text-primary flex items-center justify-center text-xl" title='Edit' onClick={e => edit(itm.id)}>
                                                        <FiEdit3 />
                                                    </a>
                                                        </Tooltip>}
                                                    </div>
                                                </td>
                                                {/* end */}
                                            </tr>
                                        })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>}
                </div>

                {!loaging && total == 0 ? <div className="py-3 text-center">No Data</div> : <></>}
                {
                    !loaging && total > filters.count ? <div className='paginationWrapper p-4  mt-15'>
                        {/* <span>Show {filters.count} from {total} Customers</span> */}
                        <Pagination
                            currentPage={filters.page}
                            totalSize={total}
                            sizePerPage={filters.count}
                            changeCurrentPage={pageChange}
                        />
                    </div> : <></>
                }

                {loaging ? <div className="text-center py-4">
                    <img src="/assets/img/loader.gif" className="pageLoader" />
                </div> : <></>}


            </div>




        </Layout>
    );
};

export default Html;
