import { useState, useEffect, useRef } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {useNavigate } from 'react-router-dom';
import methodModel from "../../methods/methods";
import Breadcrumb from "../../components/common/Breadcrumb";
import SelectDropdown from "../../components/common/SelectDropdown";
import { FaPlus } from "react-icons/fa";
import { BsTrash3 } from "react-icons/bs";
import { CostingTourAPIS, CostingTourConfig, CostingTourNormalInputs, CostingTourURL } from "./Api";
import { BiDollar } from "react-icons/bi";
import { FiPlus } from "react-icons/fi";
import DateRangePicker from "../../components/common/DateRangePicker";

const AddEditCostingTourTemplate = ({
  id = "",
  copy = false,
  custom = false,
  templateId = "",
  resellerId = "",
  result,
  detail,
}) => {
  const user = useSelector((state) => state.user);
  const [ShowFullScreen, setShowFullScreen] = useState(false);
  const [doc, setDoc] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const CostingDefault = { label: '', value: '', cal_type: '' }
  const [form, setform] = useState(
    { total_pax: '', total_cars: '', total_tour_price: '', variable_costs: [{ ...CostingDefault }], fixed_costs: [{ ...CostingDefault }] }
  );

  const history = useNavigate();
  const CostinDropOptions = [{ id: 'per-pax', name: 'Per Pax' }, { id: 'per-tour', name: 'Per Tour' }];
  useEffect(() => {
    let url = CostingTourAPIS.view;
    if (id) {
      if (custom) {
        url = "api/waiver";
      }

      loader(true);

      const response = (res) => {
        if (res.success && res.data) {
          let value = res.data;
          setform(res?.data)
        }
        loader(false);
      }
      ApiClient.get(url,{id}).then(Res=>{
        response(Res)
      })
    }
    if (templateId) {
      loader(true);

      const response = (res) => {
        if (res.success) {
          let value = res.data;
          setform({
            ...value, title: 'Copy of ' + value?.title
          });
        }
        loader(false);
      }
      ApiClient.get(url,{id:templateId}).then(Res=>{
        response(Res)
      })
    }

  }, [id, templateId]);

  const [ButtonDisabled, setButtonDisabled] = useState(true);
  const HandleChangeCosting = (e, index, type, orname = null, orvalue = null) => {
    setButtonDisabled(false)
    let name = ''
    let value = ''
    if (orname || orvalue) {

    } else {
      name = e.target.name;
      value = e.target.value
    }
    const previousData = form[type];
    previousData[index][orname ? orname : name] = orvalue ? orvalue : value
    setform(prev => ({ ...prev, [type]: [...previousData] }));
  }
  const HandleAddCosting = (type = 'variable_costs') => {
    const Data = form[type]
    setform({ ...form, [type]: [...Data, CostingDefault] })

  }
  const RemoveCosting = (index, type) => {
    const Data = form[type];

    setform(prev => {
      return { ...prev, [type]: Data.filter((item, indexo) => indexo != index) }
    })

  }

  const [CostingSubmitted, setCostingSubmitted] = useState(false)

  const UpdateCostingForm = (e) => {
    e.preventDefault();
    setCostingSubmitted(true);
    if (form?.fixed_costs.some((item) => item?.cal_type == "") || form?.variable_costs.some((item) => item?.cal_type == "")) {
      return
    }
    let url = CostingTourAPIS.add
    let method = 'post'
    let payload = { ...form }
    if (id) {
      url = CostingTourAPIS.edit
      method = 'put'
      payload['id'] = id
    } else {
      delete payload?.id
    }
    loader(true)
    ApiClient.allApi(url, payload, method).then(
      (res) => {
        if (res.success) {

          setButtonDisabled(true);
          history(-1)
        }
        loader(false)
      }
    );

  }

  const HandleCostingSimple = (e) => {
    const { name, value } = e.target;
    setform({ ...form, [name]: value })
  }

  return (
    <>


      {custom ? (
        <></>
      ) : (
        <>
          <Breadcrumb
            links={[
              {
                name: `${CostingTourConfig.ListHeader}`,
                link: CostingTourURL.list,
              },
            ]}
            currentPage={`${id ? "Edit" : "Add"
              } ${CostingTourConfig.AddHear}`}
          />
        </>
      )}

      <h5 className="text-typo mt-8 text-xl font-semibold mb-6">
        {id && !copy ? "Edit" : "Add"} {CostingTourConfig.AddHear}
      </h5>


      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 lg:col-span-12 xl:col-span-8">
          <div className="flex shadow-box mb-6 bg-white rounded-lg p-6">
            <form onSubmit={UpdateCostingForm}>
              <div>
                <div className="mb-3 grid grid-cols-12">
                  {CostingTourNormalInputs?.map((item) => <div className={item?.outerClass || ''}>
                    <label>{item?.label}</label>
                    <input
                      type={item?.type}
                      placeholder={item?.placeHolder}
                      className={`${item?.className} w-full `}
                      name={item?.name}
                      value={form[item?.name]}
                      onChange={e => HandleCostingSimple(e)}
                      required={item?.required}
                    />
                  </div>)}
                  {/* <div className="col-span-8 mb-2 lg:col-span-2">
                    <label>Select Start& End Date</label>
                    <DateRangePicker
                    buttonShow={true}
                      value={{
                        startDate: form?.startDate,
                        endDate: form?.endDate,
                      }}
                      onChange={e=>setform({...form,startDate:e?.startDate,endDate:e?.endDate})}
                    placeholder="Select Date"

                    />
                  </div> */}
                </div>
                <div className="shadow-box bg-white p-4 rounded-t-lg">
                  <div className="flex items-center mb-3 justify-between">
                    <h4 className="text-lg font-semibold">Variable Costs </h4>
                    <div className="">
                      <button type="button" title="Add More" className="!px-3.5 text-typo hover:!text-typo hover:no-underline text-sm font-medium py-3.5 flex items-center justify-center gap-2 bg-[#fff] rounded-lg shadow-btn hover:bg-[#F3F2F5] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed" onClick={e => HandleAddCosting('variable_costs')} ><FiPlus /></button>

                    </div>
                  </div>

                  {form?.variable_costs?.map((item, index) => (<div className="grid grid-cols-3 gap-4 mb-2">
                    <div>
                      <SelectDropdown
                        placeholder="Select Type"
                        options={CostinDropOptions}
                        intialValue={item?.cal_type}
                        result={e => HandleChangeCosting(e, index, 'variable_costs', 'cal_type', e?.value)}
                        className="w-full"
                      />
                      {CostingSubmitted && !item?.cal_type ? <span className="text-red-600 ml-2 mt-2 text-xs">Type is required</span> : null}
                    </div>
                    <div>
                      <input
                        placeholder="Enter Title here "
                        className="shadow-box bg-white w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary"
                        name="label"
                        value={item?.label}
                        onChange={e => HandleChangeCosting(e, index, 'variable_costs')}
                        required
                      />
                    </div>
                    <div className="flex items-start">
                      <div className="flex items-center relative">
                        <input
                          type="number"
                          placeholder="Enter value here "
                          className="shadow-box bg-white w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary"
                          name="value"
                          value={item?.value}
                          required
                          onChange={e => HandleChangeCosting(e, index, 'variable_costs')}
                        />
                        <span class=" absolute right-2 transition-all duration-200 ease-in-out group-focus-within:text-blue-500 text-gray-500"><BiDollar /></span>
                      </div>
                      {form?.variable_costs?.length == 1 ? <button
                        type="button"
                        style={{ visibility: 'hidden' }}
                        title="Delete"
                        onClick={() => RemoveCosting(index, 'variable_costs')}
                        className="  shrink-0   border cursor-pointer ml-3  !border-[#E9253129] hover:opacity-70 rounded-lg bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl"
                      >
                        <BsTrash3 />
                      </button> : <button
                        type="button"
                        title="Delete"
                        onClick={() => RemoveCosting(index, 'variable_costs')}
                        className="  shrink-0   border cursor-pointer ml-3  !border-[#E9253129] hover:opacity-70 rounded-lg bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl"
                      >
                        <BsTrash3 />
                      </button>}

                    </div>

                  </div>))}
                </div>
                <div className="shadow-box bg-white p-4 rounded-b-lg">
                  <div className="flex items-center mb-3 justify-between">
                    <h4 className="text-lg font-semibold">Fixed Costs </h4>
                    <button type="button" title="Add More" className="!px-3.5 text-typo hover:!text-typo hover:no-underline text-sm font-medium py-3.5 flex items-center justify-center gap-2 bg-[#fff] rounded-lg shadow-btn hover:bg-[#F3F2F5] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed" onClick={e => HandleAddCosting('fixed_costs')} >
                      <FiPlus />
                    </button>
                  </div>

                  {form?.fixed_costs?.map((item, index) => (<div className="grid grid-cols-3 gap-4 mb-3">
                    <div>
                      <SelectDropdown
                        placeholder="Select Type"
                        options={CostinDropOptions}
                        intialValue={item?.cal_type}
                        result={e => HandleChangeCosting(e, index, 'fixed_costs', 'cal_type', e?.value)}
                        className="w-full"
                      />
                      {CostingSubmitted && !item?.cal_type ? <span className="text-red-600 ml-2 mt-2 text-xs">Type is required</span> : null}
                    </div>
                    <div>
                      <input
                        placeholder="Enter Title here "
                        className="shadow-box bg-white w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary"
                        name="label"
                        value={item?.label}
                        onChange={e => HandleChangeCosting(e, index, 'fixed_costs')}
                        required
                      />
                    </div>
                    <div className="flex items-start">
                      <div className="flex items-center relative">
                        <input
                          type="number"
                          placeholder="Enter value here "
                          className="shadow-box bg-white w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary"
                          name="value"
                          value={item?.value}
                          required
                          onChange={e => HandleChangeCosting(e, index, 'fixed_costs')}
                        />
                        <span class=" absolute right-2 transition-all duration-200 ease-in-out group-focus-within:text-blue-500 text-gray-500"><BiDollar /></span>
                      </div>
                      {form?.fixed_costs?.length == 1 ? <button
                        type="button"
                        style={{ visibility: 'hidden' }}
                        title="Delete"
                        onClick={() => RemoveCosting(index, 'fixed_costs')}
                        className="  shrink-0   border cursor-pointer ml-3  !border-[#E9253129] hover:opacity-70 rounded-lg bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl"
                      >
                        <BsTrash3 />
                      </button> : <button
                        type="button"
                        title="Delete"
                        onClick={() => RemoveCosting(index, 'fixed_costs')}
                        className="  shrink-0    border cursor-pointer  ml-3 !border-[#E9253129] hover:opacity-70 rounded-lg bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl"
                      >
                        <BsTrash3 />
                      </button>}

                    </div>

                  </div>))}

                </div>







                <div className="flex justify-end pt-4">
                  <button
                    type="button"
                    onClick={e => history(-1)}
                    className="!px-4 text-typo text-sm font-medium py-2.5 flex items-center justify-center gap-2 bg-[#fff] rounded-lg shadow-btn hover:bg-[#F3F2F5] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed mx-2 pointer"
                  >
                    Back
                  </button>
                  <button type="submit" className={`!px-4 text-right font-normal text-white hover:!text-white hover:!no-underline h-10 flex items-center justify-center gap-2 !bg-primary rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] `}  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEditCostingTourTemplate;
