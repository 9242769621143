import React, { useEffect, useState } from 'react'
import ApiClient from '../../methods/api/apiClient';
import Layout from '../../components/global/layout';
import loader from '../../methods/loader';
import { HiOutlineArrowDown } from 'react-icons/hi';
import methodModel from '../../methods/methods';
import { IoIosClose } from 'react-icons/io';
import { FiUpload } from 'react-icons/fi';
import { Tooltip } from "antd";
import SelectDropdown from '../../components/common/SelectDropdown';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';

export default function KeySetting() {
    const history = useNavigate()
    const [FileData, setFileData] = useState({});
    const [DisabledButton, setDisabledButton] = useState(true);
    const [KeysList, setKeysList] = useState([]);
    const GetKeyLists = () => {
        loader(true)
        ApiClient.get(`api/mapped-keys`).then(res => {
            if (res.success) {
                let Key = methodModel.getPrams('platform');
                let FInalKey = Key == 'hakatours' ? 'hakatour' : Key == 'fareharbor' ? 'fareharbour' : Key
                setKeysList(res.data?.map((item) => {
                    return { id: item?.id, moduleName: item?.moduleName, dazhboardsKeyName: item?.dazhboardsKeyName, [FInalKey + 'KeyName']: item[`${FInalKey + 'KeyName'}`] }
                }))
            }
            loader(false)
        })


    }

    const GetFirstlyMappedKeys = () => {
        loader(true)
        ApiClient.get(`api/mapped-platform?booking_platform=${methodModel.getPrams('platform')}`).then(res => {
            if (res?.success) {
                setFileData({ fileName: res?.data?.fileName, date: res?.data?.updatedAt })
                setCSVForm({ ...CSVForm, csvName: res?.data?.fileLocation });
                setAllKeys(res?.data?.fileKeys?.map((item) => ({ id: item, name: item })));
            }
            loader(false)
        })
    }
    useEffect(() => {
        GetKeyLists()
        GetFirstlyMappedKeys()
    }, [])


    const HandleInputChange = (keyName, index, value, id = null) => {
        let data = KeysList
        let FInalData = data?.map((item) => {
            if (item?.id == id) {
                return { ...item, [keyName]: value }
            } else {
                return item
            }
        })
        console.log(FInalData)
        setKeysList([...FInalData]);
        setDisabledButton(false)

    }

    loader(false)
    const MappedKeySaver = (e) => {
        e.preventDefault();
        loader(true)
        ApiClient.put(`api/mapped-key`, KeysList).then(res => {
            if (res.success) {
                history(-1);
            }
            setDisabledButton(true)
            loader(false)
        })

    }
    function formatKey(key) {
        if (!key) {
            return key
        }
        // Replace underscores with spaces
        let formattedKey = key.replace(/_/g, ' ');

        // Split the key into words based on spaces and capital letters
        const words = formattedKey.match(/[A-Z][a-z]*|[a-z]+/g);

        // Capitalize each word and join them with spaces
        formattedKey = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

        return formattedKey;
    }



    const [CSVForm, setCSVForm] = useState({})
    const [AllKeys, setAllKeys] = useState([]);
    const [MappedKeys, setMappedKeys] = useState([]);
    const [UnMappedKeys, setUnMappedKeys] = useState([]);
    const [SubmitCsv, setSubmitCsv] = useState(false);
    const uploadCSVFile = (e) => {
        let files = e.target.files;
        let file = files.item(0);
        loader(true);
        console.log(file)
        setFileData({ fileName: file?.name, date: new Date() })
        setSubmitCsv(false)
        ApiClient.postFormData(`api/upload/booking/csv?bookingPlatform=${methodModel.getPrams('platform')}&isAdmin=true`, {
            file: file,
        }).then((res) => {
            if (res.filePath) {
                setCSVForm({ ...CSVForm, csvName: res?.filePath });
                setUnMappedKeys(res?.keysNotMapped?.map((item) => ({ rezdyKeyName: null, dazhboardsKeyName: item })))
                setAllKeys(res?.fileKeys?.map((item) => ({ id: item, name: item })));
                setMappedKeys(res?.alreadyMappedKeys)
            } else {
            }
            loader(false);
        });
    };


    const KeyListReturner = (data) => {
        const Modules = []
        data?.map((item) => {
            if (!Modules?.includes(item?.moduleName)) {
                Modules.push(item?.moduleName);
            }
        })
        let FinalData = Modules?.map((item) => {
            return { name: item, data: data?.filter((ip) => ip?.moduleName == item) }
        })
        return FinalData

    }

    const UnMappedKeysFinder = (keyName = '') => {
        let data = { id: keyName, name: keyName };
        const KeysData = AllKeys;
        let Key = methodModel.getPrams('platform');
        let FInalKey = Key == 'hakatours' ? 'hakatour' : Key == 'fareharbor' ? 'fareharbour' : Key
        const MappedKeys = KeysList.map((item) => item?.[`${FInalKey}KeyName`]);
        let UniqueKeys = KeysData.filter((item) => !MappedKeys?.includes(item?.id))
        if (KeysData.find((item) => item?.id == keyName)) {
            UniqueKeys.push({ id: keyName, name: keyName })
        }
        return UniqueKeys

    }
    console.log({ KeysList, AllKeys }, "THIS SI THE ALL KEYS LIST ");
    return (
        <div >
            <Layout>
                <div className="pprofile1">

                    <div className='flex items-center mb-8'>
                        <Tooltip placement="top" title="Back">
                            <button onClick={e => history(-1)} className="!px-4  py-2 flex items-center justify-center  rounded-full shadow-btn hover:bg-[#F3F2F5] border border-grey  transition-all    mr-3 h-12 w-12"><i className='fa fa-angle-left text-lg'></i></button>
                        </Tooltip>
                        <div>
                            <h3 className="text-2xl font-semibold text-[#111827]">
                                Keys Mapping Setting for {methodModel.getPrams('platform')}
                            </h3>
                        </div>
                    </div>


                    <div className='shadow-box w-full bg-white rounded-lg mt-6 border border-grey '>

                        <>
                            {CSVForm?.csvName ? <>
                                <div className="justify-center relative flex m-3">

                                    <img src="https://png.pngtree.com/png-clipart/20190630/original/pngtree-csv-file-document-icon-png-image_4177027.jpg" className="w-36 h-36" />

                                    <Tooltip placement='top' title="Delete">
                                        <p className="absolute right-0 border cursor-pointer !border-[#E9253129] hover:opacity-70 rounded-lg bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl" onClick={e => { setAllKeys([]); setUnMappedKeys([]); setMappedKeys([]); setCSVForm({ ...CSVForm, csvName: '' }); }}><IoIosClose className='text-2xl' /></p>

                                    </Tooltip>
                                    <br />
                                    <p className='text-sm ml-2'>

                                        {FileData?.fileName}<br />
                                        {moment(FileData?.date || new Date()).format('DD-MM-YYYY')}
                                    </p>
                                </div>

                            </> :
                                <div className='m-4'>
                                    <div className="flex items-center justify-center w-full h-56 border-[1.5px] border-grey rounded-large cursor-pointer"
                                    >
                                        <label
                                            htmlFor="dropzone-file"
                                            className="flex cursor-pointer flex-col items-center justify-center w-full h-full"
                                        >
                                            <div className="flex flex-col items-center justify-center gap-2 pt-5 pb-6 px-4 text-center">
                                                <FiUpload className="text-xl text-[#1C1B1F]" />
                                                <p className="text-sm font-light text-[#585858]">
                                                    Drag & drop files here, or click to select files
                                                </p>
                                                <p className="text-xs text-[#8F8F8F] font-light">
                                                    Supported File Types: .csv
                                                </p>
                                            </div>
                                            <input
                                                id="dropzone-file"
                                                type="file"
                                                multiple
                                                className="hidden"
                                                onChange={(e) => uploadCSVFile(e)}
                                            />
                                        </label>
                                    </div></div>}
                            {SubmitCsv && !CSVForm?.csvName && <p className="text-sm text-red-400 p-2">File  is required</p>}
                        </>


                        {CSVForm?.csvName && <table className="w-full">
                            <thead className="border-b border-[#EAECF0]">
                                <tr className="border-b border-[#EAECF0]">
                                    <th scope="col" className="cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3">
                                        Dazhboard Key Name

                                    </th>
                                    {KeysList.length > 0 && Object.keys(KeysList[0]).map(ObjectKeys => (
                                        (ObjectKeys !== 'id' && ObjectKeys !== 'moduleName' && ObjectKeys !== '_id' && ObjectKeys !== 'dazhboardsKeyName') && (
                                            <th scope="col" key={ObjectKeys} className="cursor-pointer capitalize text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3">
                                                {formatKey(ObjectKeys)}
                                                <span className="ml-2"></span>
                                            </th>
                                        )
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {KeyListReturner(KeysList)?.map((itemo, main) => (<>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="!text-typo   cursor-pointer !px-3.5 text-sm font-normal !py-4  text-left capitalize border-[#EAECF0] text-bold"><b className='text-sm'> {itemo?.name}</b></td>
                                    </tr>
                                    {itemo?.data?.map((item, mainIndex) => (
                                        <tr key={mainIndex}>
                                            <td className="!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left capitalize border-[#EAECF0]">
                                                {formatKey(item?.dazhboardsKeyName)}
                                            </td>
                                            {Object.keys(item).map((ObjectKeys) => (
                                                (ObjectKeys !== 'id' && ObjectKeys !== 'moduleName' && ObjectKeys !== '_id' && ObjectKeys !== 'dazhboardsKeyName') && (
                                                    <td key={ObjectKeys} className="!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]">
                                                        {ObjectKeys === 'dazhboardsKeyName' ? <input
                                                            required
                                                            disabled={ObjectKeys === 'dazhboardsKeyName'}
                                                            type="text"
                                                            value={item[ObjectKeys]}
                                                            onChange={e => HandleInputChange(ObjectKeys, mainIndex, e.target.value, item?.id)}
                                                            className="shadow-box border !border-grey bg-white w-full text-sm placeholder:text-gray-500 rounded-large h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary"
                                                        /> : <SelectDropdown
                                                            placeholder="Select Key"
                                                            theme='search'
                                                            intialValue={item[ObjectKeys]}
                                                            options={UnMappedKeysFinder(item[ObjectKeys])}
                                                            result={e => HandleInputChange(ObjectKeys, mainIndex, e?.value, item?.id)}
                                                        />}
                                                    </td>
                                                )
                                            ))}
                                        </tr>
                                    ))}
                                </>
                                ))}
                            </tbody>
                        </table>}
                        {CSVForm?.csvName && < div className='flex items-center justify-end pb-4 px-3' >
                            <button type="submit" disabled={DisabledButton} onClick={e => MappedKeySaver(e)}
                                className="!px-4  mt-3 text-sm font-normal text-white h-10 flex items-center justify-center gap-2 bg-primary rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">Save</button>
                        </div>}
                    </div>
                </div>
            </Layout >
        </div >
    )
}
