import React from 'react';
import Layout from '../../components/global/layout';
import Pagination from "react-pagination-js";
import './style.scss';
import { Link } from 'react-router-dom';
import dynamicPriceModel from '../../models/dynamicPrice.model';
import datepipeModel from '../../models/datepipemodel';
import SelectDropdown from '../../components/common/SelectDropdown';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Tooltip } from "antd";
import { PiFileCsv } from "react-icons/pi";
import { HiOutlineArrowDown } from "react-icons/hi";
import { FiEdit3, FiPlus } from "react-icons/fi";
import { AiOutlineEye } from "react-icons/ai";
import { BsTrash3 } from "react-icons/bs";
import { FaCopy } from "react-icons/fa";


const Html = ({
    sortClass,
    sorting,
    ChangeStatus,
    statusChange,
    EnablestatusChange,
    deleteItem,
    edit,
    pageChange,
    filters,
    EnableChangeStatus,
    filter,
    loaging,
    data,
    exportfun,
    dynamicStyle = false,
    className = null,
    country,
    setFilter,
    years,
    type,
    total,
    reset
}) => {

    const countyName = (p) => {
        let ext = country?.find(itm => itm.id.toLowerCase() == p)
        
        return ext ? ext.name : p + ''
    }


    return (
        <Layout>
                 <div className="d-flex justify-content-between align-items-center mb-6">


        <div>
          <h3 className="text-2xl font-semibold text-[#111827]">
            Dynamic Pricing
          </h3>
          <p class="text-sm font-normal text-[#75757A]">Here you can see all about your  Dynamic Pricing</p>
        </div>
      </div>
            <div className="flex justify-between items-center mb-6">
                <div>
                    <h3 className="text-2xl font-semibold text-[#111827]">
                        {dynamicPriceModel.name(type)}
                    </h3>

                </div>


                <div className="flex gap-3">
                    <button className="!px-3 text-[#3C3E49] text-sm font-normal h-10 flex items-center justify-center gap-2 bg-[#fff] rounded-lg shadow-btn hover:bg-[#F3F2F5] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed " onClick={() => exportfun()}>
                        <PiFileCsv className="text-typo text-xl" />  Export
                    </button>

                    <Link to={`/dynamicprice/add`} className='!px-3 text-sm font-normal hover:!text-white hover:no-underline text-white h-10 flex items-center justify-center gap-2 bg-primary rounded-lg shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed'> <FiPlus className="text-xl text-white" />Create New</Link>





                </div>

            </div>

            <div className='bg-white shadow-box rounded-large w-full border border-grey'>

                <div className="flex justify-between items-center p-4">

                    <span className='flex'>
                        <div className='w-80'>
                            <SelectDropdown
                                isSingle={true}
                                id="statusDropdown"
                                displayValue="name"
                                placeholder='All Countries'
                                intialValue={filters.country}
                                theme='search'
                                result={e => filter({ country: e.value })}
                                options={country}
                            />
                        </div>
                        {filters.status || filters.country||filters.isEnabled ? <button className="bg-primary ml-3 leading-10 mr-3 h-10 shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-lg mr-2" onClick={() => reset()}>
                            Reset
                        </button> : <></>}
                    </span>

                    <div className='flex gap-4'>

                   
  <div className="dropdown1 addDropdown1 mr-2">
                        <Menu as="div" className="relative list_box_active_state ml-auto">
                            <div>
                                <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 border rounded-large bg-white px-3 py-2.5 text-sm font-normal text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200">
                                    {filters.isEnabled=='false' ?"Disable" : filters.isEnabled=='true'?'Enable': 'All'}

                                    <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                                </Menu.Button>
                            </div>

                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className={`${dynamicStyle ? "" : "max-h-60"}  focus:!outline-[#481196] focus:!outline text-sm absolute z-40 ${className ? className : " min-w-[260px]"
                                    }  right-0 shadow-lg !py-2 !mt-1.5 overflow-auto bg-white  rounded-large scrollbar`}>
                                    <div className="mt-2">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a className={filters.isEnabled == '' ? 'text-gray-700 block px-4 py-2 text-sm active' : 'text-gray-700 block px-4 py-2 text-sm'} onClick={() => EnableChangeStatus("")}>All</a>

                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a className={filters.isEnabled ? 'text-gray-700 block px-4 py-2 text-sm active' : 'text-gray-700 block px-4 py-2 text-sm'} onClick={() => EnableChangeStatus('true')} >Enable</a>

                                            )}
                                        </Menu.Item>
                                        <Menu.Item className="divide-y-1 divide-gray-800 pt-1  mt-2">
                                            <p className="border-t"></p>
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a className={!filters.isEnabled? 'text-gray-700 block px-4 py-2 text-sm active' : 'text-gray-700 block px-4 py-2 text-sm'} onClick={() => EnableChangeStatus("false")} >Disable</a>

                                            )}
                                        </Menu.Item>


                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>

                    </div>
                    <div className="dropdown1 addDropdown1 mr-2">
                        <Menu as="div" className="relative list_box_active_state ml-auto">
                            <div>
                                <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 border rounded-large bg-white px-3 py-2.5 text-sm font-normal text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200">
                                    {filters.status ? filters.status == "deactive" ? "Inactive" : filters.status : 'All Status'}

                                    <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                                </Menu.Button>
                            </div>

                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className={`${dynamicStyle ? "" : "max-h-60"}  focus:!outline-[#481196] focus:!outline text-sm absolute z-40 ${className ? className : " min-w-[260px]"
                                    }  right-0 shadow-lg !py-2 !mt-1.5 overflow-auto bg-white  rounded-large scrollbar`}>
                                    <div className="mt-2">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a className={filters.status == '' ? 'text-gray-700 block px-4 py-2 text-sm active' : 'text-gray-700 block px-4 py-2 text-sm'} onClick={() => ChangeStatus("")}>All Status</a>

                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a className={filters.status == 'active' ? 'text-gray-700 block px-4 py-2 text-sm active' : 'text-gray-700 block px-4 py-2 text-sm'} onClick={() => ChangeStatus("active")} >Active</a>

                                            )}
                                        </Menu.Item>
                                        <Menu.Item className="divide-y-1 divide-gray-800 pt-1  mt-2">
                                            <p className="border-t"></p>
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a className={filters.status == 'Inactive' ? 'text-gray-700 block px-4 py-2 text-sm active' : 'text-gray-700 block px-4 py-2 text-sm'} onClick={() => ChangeStatus("deactive")} >Inactive</a>

                                            )}
                                        </Menu.Item>


                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>

                    </div>
                     </div>
                </div>


                <div className=" w-full bg-white rounded-lg">
                    <div className="scrollbar w-full overflow-auto">


                        <table class="w-full">
                            <thead className='table_head'>
                                <tr className='heading_row'>
                                    <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3' onClick={e => sorting('name')}>Name<span className='ml-2'> <HiOutlineArrowDown className="shrink-0 inline text-sm" /></span></th>
                                    <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3' onClick={e => sorting('type')}>Rule Type<span className='ml-2'> <HiOutlineArrowDown className="shrink-0 inline text-sm" /></span></th>
                                    <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3' onClick={e => sorting('createdAt')}>Date Created <span className='ml-2'> <HiOutlineArrowDown className="shrink-0 inline text-sm" /></span></th>
                                    <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3' onClick={e => sorting('updatedAt')}>Date Updated <span className='ml-2'> <HiOutlineArrowDown className="shrink-0 inline text-sm" /></span></th>
                                    <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3' onClick={e => sorting('country')}>Country<span className='ml-2'> <HiOutlineArrowDown className="shrink-0 inline text-sm" /></span></th>
                                    <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3'>Enable/Disable</th>
                                    <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3'>Status</th>
                                    <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3' onClick={e => sorting('ModifyBy')}>Updated By <span className='ml-2'> <HiOutlineArrowDown className="shrink-0 inline text-sm" /></span></th>
                                    <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!loaging && data && data.map((itm, i) => {
                                    return <tr className=''>
                                        <td className='!text-typo hover:text-blue-800 hover:underline !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]' onClick={e => edit(itm, false)}>{itm.name}</td>
                                        <td className='!text-typo !border-l-0  !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]' >{itm.type}</td>
                                        <td className='!text-typo !border-l-0  !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]'>{datepipeModel.date(itm.createdAt)}</td>
                                        <td className='!text-typo !border-l-0  !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]'>{datepipeModel.date(itm.updatedAt)}</td>
                                        <td className='!text-typo !border-l-0  !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]'>{countyName(itm.country)}</td>
<td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]'>
                                            {/* <div className={`user_hours ${itm.status}`} */}
                                            <div className={`user_hours ${itm.isEnabled}`}
                                                onClick={() => EnablestatusChange(itm)}>
                                                <Tooltip placement="top" title="Enable / Disable">
                                                    <span className='contract'>
                                                        {itm.isEnabled ? 'Enable' : 'Disable'}
                                                    </span>
                                                </Tooltip>
                                            </div></td>


                                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]'>
                                            {/* <div className={`user_hours ${itm.status}`} */}
                                            <div className={`user_hours ${itm.status}`}
                                                onClick={() => statusChange(itm)}>
                                                <Tooltip placement="top" title="Active / Inactive">
                                                    <span className='contract'>
                                                        {itm.status == 'deactive' ? 'Inactive' : 'Active'}
                                                    </span>
                                                </Tooltip>
                                            </div></td>
                                        <td className='!text-typo !border-l-0  !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]'>{itm.updatedBy?.fullName}</td>
                                        <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                                            <div className="">
                                                <div className="flex items-center justify-center gap-1.5">
                                                    <Tooltip placement="top" title="Edit">
                                                        <a className='border cursor-pointer border-primary hover:opacity-70 rounded-large bg-[#48119614] w-10 h-10 !text-primary flex items-center justify-center text-xl' onClick={e => edit(itm, false)}>
                                                            <FiEdit3 />
                                                        </a>
                                                    </Tooltip>
                                                    <Tooltip placement="top" title="Delete">
                                                        <span className='border cursor-pointer !border-[#E9253129] hover:opacity-70 rounded-lg bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl ' onClick={() => deleteItem(itm.id)}>
                                                            <BsTrash3 />
                                                        </span>
                                                    </Tooltip>
                                                    <Tooltip placement="top" title="Copy">
                                                  
                                                        <a className="border !border-[#94D5AE] rounded-lg bg-[#ECF4EF] hover:opacity-70 w-10 h-10 text-[#b4b7fb] flex items-center justify-center text-xls" onClick={e => edit(itm, true)}>
                                                            <FaCopy className='text-[#b4b7fb]' />
                                                        </a>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                </div >
                {!loaging && total == 0 ? <div className="py-3 text-center">No Data</div> : <></>}
                {
                    !loaging && total > filters.count ? <div className='paginationWrapper'>
                        {/* <span className='text-sm text-gray-600'>Show {filters.count} from {total} Dynamic Price</span> */}
                        <Pagination
                            currentPage={filters.page}
                            totalSize={total}
                            sizePerPage={filters.count}
                            changeCurrentPage={pageChange}
                        />
                    </div> : <></>
                }
                {
                    loaging ? <div className="text-center py-4">
                        <img src="/assets/img/loader.gif" className="pageLoader" />
                    </div> : <></>
                }

            </div>

        </Layout >
    );
};

export default Html;
