import React from 'react';
import Layout from '../../components/global/layout';
import Pagination from "react-pagination-js";
import './style.scss';
import { Link } from 'react-router-dom';
import userTableModel from '../../models/table.model';
import datepipeModel from '../../models/datepipemodel';
import methodModel from '../../methods/methods';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Tooltip } from "antd";
import { PiFileCsv } from 'react-icons/pi';
import { HiOutlineArrowDown } from 'react-icons/hi';
import { BsTrash3 } from 'react-icons/bs';
import { FiEdit3, FiPlus } from 'react-icons/fi';

const Html = ({
    sortClass,
    sorting,
    tab,
    edit,
    tabChange,
    colClick,
    openModal,
    pageChange,
    dynamicStyle = false,
    className = null,
    ChangeStatus,
    addCol,
    deleteItem,
    statusChange,
    exportCsv,
    uTableCols,
    removeCol,
    filters,
    tableCols,
    loaging,
    data,
    types,
    isAllow,
    exportfun,
    total = { total }
}) => {
    return (
        <Layout>
            <div className="flex justify-between items-center ">

                <div>
                    <h3 className="text-2xl font-semibold text-[#111827]">
                        Continents
                    </h3>
                    <p class="text-sm font-normal text-[#75757A]">Here you can see all about your  Continents</p>
                </div>



                <div className="flex gap-2">
                    <button className="!px-2.5 text-[#3C3E49] text-sm font-normal py-2.5 flex items-center justify-center gap-2 bg-[#fff] rounded-large shadow-btn hover:bg-[#F3F2F5] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed" onClick={() => exportfun()}>
                        <PiFileCsv className="text-typo text-xl" />  Export CSV
                    </button>


                    {isAllow('addContinents') ?
                        <Link className="bg-primary leading-10 h-10 flex items-center shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-large gap-2" to="/continents/add">
                            <FiPlus className="text-xl text-white" /> Add Continent
                        </Link>
                        : <></>}


                    {/* <div className='icons_tab'>
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class={`nav-link ${tab == 'grid' ? 'active' : ''}`} id="employee-tab" onClick={e => tabChange('grid')}>
                                    <i className="fa fa-th"></i>
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class={`nav-link staff ${tab == 'list' ? 'active' : ''}`} id="staff-tab" onClick={e => tabChange('list')}>
                                    <i className="fa fa-list"></i>
                                </button>
                            </li>
                        </ul>
                    </div> */}
                </div>
            </div>

            <div className='shadow-box w-full bg-white rounded-lg mt-6 border border-grey'>
                <div className='flex justify-end items-center p-4'>
                    <div className="dropdown1 w-40 addDropdown1 mr-2">

                        <Menu as="div" className="relative list_box_active_state ml-auto">
                            <div>
                                <Menu.Button className="inline-flex w-full border justify-center gap-x-1.5 rounded-large bg-white px-3 py-2.5 text-sm font-normal  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200">
                                    {filters.status ? filters.status == "deactive" ? "Inactive" : filters.status : 'All Status'}
                                    <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                                </Menu.Button>
                            </div>

                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className={`${dynamicStyle ? "" : "max-h-60"}  focus:!outline-[#481196] focus:!outline text-sm absolute z-40 ${className ? className : " min-w-[260px]"
                                    }  right-0 shadow-lg !py-2 !mt-2 overflow-auto bg-white  rounded-lg scrollbar`}>
                                    <div className="mt-2">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a className={filters.status == '' ? 'text-gray-700 block px-4 py-2 text-sm active' : 'text-gray-700 block px-4 py-2 text-sm'} onClick={() => ChangeStatus("")}>All Status</a>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a className={filters.status == 'active' ? 'text-gray-700 block px-4 py-2 text-sm active' : 'text-gray-700 block px-4 py-2 text-sm'} onClick={() => ChangeStatus("active")} >Active</a>
                                            )}
                                        </Menu.Item>

                                        <Menu.Item className="divide-y-1 divide-gray-800 pt-1  mt-2">
                                            <p className="border-t"></p>
                                        </Menu.Item>


                                        <Menu.Item className="">
                                            {({ active }) => (
                                                <a className={filters.status == 'Inactive' ? 'text-gray-700 block px-4 py-2 text-sm active' : 'text-gray-700 block px-4 py-2 text-sm'} onClick={() => ChangeStatus("deactive")} >Inactive</a>
                                            )}
                                        </Menu.Item>

                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>




                    </div>
                </div>


                {tab == 'grid' ? <>
                    <div className="cardList">
                        <div className='grid grid-cols-12 gap-4'>
                            {!loaging && data && data.map((itm, i) => {
                                return <div className='col-span-12 md:col-span-4'>
                                    <div className='new_cards'>
                                        <div className='user_card'>
                                            <div className='user_detail' onClick={e => view(itm.id)}>
                                                <img src={methodModel.userImg(itm.image)} className="user_imgs" />
                                                <div className='user_name'>
                                                    <h4 className='user'>
                                                        {itm.name}
                                                    </h4>
                                                    {/* <p className='user_info'>
                                                    {itm.email}
                                                </p> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='user_proff'>
                                            <div className='id_name'>
                                                <ul className='user_list'>
                                                    <li className='list_name'>
                                                        <a className='id'>
                                                            Date
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className='detail_list'>
                                                <ul className='user_list'>
                                                    <li className='list_names'>
                                                        <a className='id_name' onClick={e => view(itm.id)}>
                                                            {datepipeModel.datetime(itm.createdAt)}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </> : <>

                    <div className="w-full bg-white rounded-lg">
                        <div className="scrollbar w-full overflow-auto">


                            <table class="w-full">
                                <thead className='border-b border-[#EAECF0]'>
                                    <tr className='border-b border-[#EAECF0]'>
                                        <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3' onClick={e => sorting('name')}>Continent Name<span className='ml-2'><HiOutlineArrowDown className="shrink-0 inline text-sm" /></span></th>
                                        <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3'>Status</th>
                                        <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!loaging && data && data.map((itm, i) => {
                                        return <tr className=''>
                                            <td className='!text-typo hover:text-blue-800 hover:underline !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                                                <div className='user_detail'>
                                                    <div className='user_name'>
                                                        <h4 className='user' onClick={e => edit(itm.id)}>
                                                            {itm.name}
                                                        </h4>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                                                <div className={`user_hours ${itm.status}`} onClick={() => statusChange(itm)}>
                                                    <Tooltip placement="top" title="Acive / Inactive">
                                                        <span className='contract '>
                                                            {itm.status == 'deactive' ? 'inactive' : 'active'}
                                                        </span>
                                                    </Tooltip>
                                                </div>
                                            </td>
                                            <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                                                <div className="flex items-center justify-start gap-1.5">
                                                    {isAllow('editContinents') ?
                                                        <Tooltip placement="top" title="Edit">
                                                              <a className="border cursor-pointer border-primary hover:opacity-70 rounded-large bg-[#48119614] w-10 h-10 !text-primary flex items-center justify-center text-xl" title='Edit' onClick={e => edit(itm.id)}>
                                                        <FiEdit3 />
                                                    </a>
                                                        </Tooltip>
                                                        : <></>}
                                                    {isAllow('deleteContinents') ?
                                                        <Tooltip placement="top" title="Delete">
                                                            <span className='border cursor-pointer !border-[#E9253129] hover:opacity-70 rounded-large bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl' onClick={() => deleteItem(itm.id)}>
                                                                <BsTrash3 />
                                                            </span>
                                                        </Tooltip>
                                                        : <></>}
                                                </div>
                                            </td>
                                            {/* end */}
                                        </tr>
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>}
                {!loaging && total == 0 ? <div className="py-3 text-center">No Data</div> : <></>}
                {
                    !loaging && total > filters.count ? <div className='paginationWrapper flex items-center justify-between mt-15'>
                        <span>Show {filters.count} from {total} Continents</span>
                        <Pagination
                            currentPage={filters.page}
                            totalSize={total}
                            sizePerPage={filters.count}
                            changeCurrentPage={pageChange}
                        />
                    </div> : <></>
                }
                {loaging ? <div className="text-center py-4">
                    <img src="/assets/img/loader.gif" className="pageLoader" />
                </div> : <></>}

            </div>
        </Layout>
    );
};

export default Html;
