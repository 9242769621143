import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import Layout from '../../../components/global/layout';
import ApiClient from '../../../methods/api/apiClient';
import loader from '../../../methods/loader';
import './style.scss';
import { useSelector } from 'react-redux';
import datepipeModel from "../../../models/datepipemodel";
import SelectDropdown from '../../../components/common/SelectDropdown';
import { toast } from 'react-toastify';
import moment from 'moment';
import methodModel from '../../../methods/methods';
import pipeModel from '../../../models/pipeModel';
import { HiOutlineArrowDown } from 'react-icons/hi';
import { Tooltip } from 'antd';
import { AiOutlineEye } from 'react-icons/ai';
import { FiEdit3 } from 'react-icons/fi';
import { BsDownload } from 'react-icons/bs';
import environment from '../../../environment';
import axios from 'axios';

const UserDetail = (p) => {
    const history = useNavigate()
    const user = useSelector(state => state.user)
    const { id, userId } = useParams()
    const [data, setData] = useState()
    const [planDetails, setplanDetails] = useState()
    const [trailDays, settrailDays] = useState()
    const [invoices, setInvoices] = useState([])
    const [CustomerUsers, setCustomerUsers] = useState([]);

    const getDetail = (did) => {
        loader(true)
        ApiClient.get(`api/user/profile`, { id: did }).then(res => {
            if (res.success) {
                setData(res.data)
                settrailDays('')
            }
            loader(false)
        })
    }
    const back = () => {
        history(-1)
    }

    const statusChange = (itm) => {
        let modal = 'users'
        let status = 'active'
        if (itm.status == 'active') status = 'deactive'

        if (window.confirm(`Do you want to ${status == 'active' ? 'Activate' : 'Deactivate'} this user`)) {
            loader(true)
            ApiClient.put(`api/user/status/change`, { status, id: itm.id }).then(res => {
                if (res.success) {
                    GetCustomerUsers()
                }
                loader(false)
            })
        }
    }
    const getPlanDetails = () => {
        ApiClient.get(`api/my/plan?id=${id}`).then(res => {
            if (res.success) {
                setplanDetails(res.data)
            }
        })
    }

    useEffect(() => {
        getDetail(userId ? userId : id)
        getPlanDetails()
        getInvoices()
    }, [id, userId])

    const cancelPlan = () => {
        if (window.confirm('Do you really want to cancel the plan')) {
            ApiClient.delete(`api/cancel/subscription?id=${planDetails?.id}&userId=${id}`).then(res => {
                if (res.success) {
                    getDetail(userId ? userId : id)
                    getPlanDetails()
                }
            })
        }
    }



    const view = (id) => {
        history("/customer/view/" + id)

        window.scrollTo(0, 0)
        setTimeout(() => {
            getDetail(id)
            getPlanDetails()
            GetCustomerUsers();
            getInvoices()

        }, 100);
    }
    const handleTrailDays = () => {
        let payload = {
            user_id: userId ? userId : id,
            extend_days: Number(trailDays)
        }
        ApiClient.put(`api/extend-trial`, payload).then(res => {
            if (res.success) {
                // toast.success(res.message)
                getPlanDetails()
                getDetail(userId ? userId : id)
            }
        })
    }

    const getInvoices = () => {
        let f = { userId: userId ? userId : id }
        ApiClient.get('api/invoices/list', f).then(res => {
            if (res.success) {
                setInvoices(res.data)
            }
        })
    }


    const GetCustomerUsers = () => {
        ApiClient.get(`api/account/users?addedBy=${id}`).then(res => {
            if (res.success) {
                setCustomerUsers([...res?.data]);
            }
        })
    }
    useEffect(() => {
        GetCustomerUsers()
    }, [id])

    const edit = (id) => {
        history(`/customer/edit/${id}`)
    }

    const handleInvoiceDownloader = async (e, data) => {
        loader(true);
        e.preventDefault();

        const token = await localStorage.getItem("token");
        const req = await axios({
            method: "get",
            url: `${environment.api}api/invoice/download?id=${data?.id || data?._id}`,
            responseType: "blob",
            body: { token: token }
        });
        var blob = new Blob([req.data], {
            type: req.headers["content-type"],
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${data?.invoiceNumber}.pdf`;
        loader(false)
        link.click();
    }


    return (
        <Layout>
            <div className='p-6 shadow-box border !border-grey  rounded-large bg-white mt-6'>

                <div className="flex items-center mb-8">
                    <div>
                        <a className='!px-4  py-2 flex items-center justify-center  rounded-full shadow-btn hover:bg-[#F3F2F5] border border-grey  transition-all    mr-3 h-12 w-12' to="/users" onClick={back}>   <i className="fa fa-angle-left text-lg"></i></a>
                    </div>
                    <div>
                        <h3 className="text-2xl font-semibold text-[#111827]">Dazhboards Customer Details</h3>
                        <p className="text-sm font-normal text-[#75757A]">Here you can see all about your  Dazhboards Customer Details</p>
                    </div>
                </div>


                <div className="grid grid-cols-12 gap-4">
                    <div className="sideclass col-span-12 md:col-span-12">

                        <div className="grid grid-cols-12 gap-4">
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Name</label>
                                <div className='profiledetailscls'>{data && data.fullName}</div>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Email</label>
                                <div className='profiledetailscls'>{data && data.email || '--'}</div>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Mobile No</label>
                                <div className='profiledetailscls'>{data?.dialCode ? <>({data?.dialCode && data?.dialCode}) {data && data.mobileNo}</> : <>--</>}</div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>


            <div className='p-6 shadow-box border !border-grey  rounded-large bg-white mt-4'>
                <div className="">
                    <h3 className="text-2xl font-semibold text-[#111827] ">Company Profile</h3>
                    <div className="grid grid-cols-12 gap-4 mt-4">
                        <div className="col-span-12 md:col-span-6">
                            <label className="profileheddingcls">Company Name</label>
                            <div className='profiledetailscls'>{data && data?.companyName || '--'}</div>
                        </div>
                        <div className="col-span-12 md:col-span-6">
                            <label className="profileheddingcls">Contact Phone Number</label>
                            <div className='profiledetailscls'> {data?.companymobileno ? `+${data?.companymobileno}` : '--'}</div>
                        </div>
                        <div className="col-span-12 md:col-span-6">
                            <label className="profileheddingcls">Company Email</label>
                            <div className='profiledetailscls'>{data && data.contactemail || '--'}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='p-6 shadow-box border !border-grey  rounded-large bg-white mt-4'>
                <div className="">
                    <h3 className="text-2xl font-semibold text-[#111827] ">Company Information</h3>
                    <div className="grid grid-cols-12 gap-4 mt-4">
                        <div className="col-span-12 md:col-span-6">
                            <label className="profileheddingcls">Address</label>
                            <div className='profiledetailscls'>{data && data?.companyAddress || '--'}</div>
                        </div>
                        <div className="col-span-12 md:col-span-6">
                            <label className="profileheddingcls">Contact Name</label>
                            <div className='profiledetailscls'>{data && data.contactName || '--'}</div>
                        </div>
                        <div className="col-span-12 md:col-span-6">
                            <label className="profileheddingcls">Time Zone</label>
                            <div className='profiledetailscls'>{data && data.companytimezone || '--'}</div>
                        </div>
                        <div className="col-span-12 md:col-span-6">
                            <label className="profileheddingcls">Website</label>
                            <div className='profiledetailscls'>{data && data.website || '--'}</div>
                        </div>
                        <div className="col-span-12 md:col-span-6">
                            <label className="profileheddingcls">Financial Year</label>
                            <div className='profiledetailscls'>{data && datepipeModel.monthname(data.financialYear)}</div>
                        </div>
                    </div>
                </div>
            </div>


            <div className='p-6 shadow-box border !border-grey  rounded-large bg-white mt-4'>
                <div className="">
                    <div className='flex items-center justify-between'>
                    <h3 className='text-2xl font-semibold text-[#111827] '>Plan Details </h3>
                    {planDetails?.isActive ? <p className='bg-red-600 px-4 py-2 rounded-large cursor-pointer text-sm text-white'><i className='fa fa-trash text-sm text-white' onClick={e => cancelPlan()}></i> Delete</p> : null}
                    </div>
                    <div className='grid grid-cols-12 gap-4 mt-4'>
                        <div className='col-span-12 md:col-span-6'>
                            <label className="profileheddingcls">Plan Active</label>
                            <div className='profiledetailscls'>{planDetails && planDetails?.isActive ? 'Yes' : 'No'}</div>
                        </div>
                        {planDetails?.on_trial ?
                            <>

                                <div className='col-span-12 md:col-span-6 '>
                                <div className='flex items-end gap-4'>

                                    <div className=''>
                                        <label className="profileheddingcls">Extend Trail Period (Days)</label>
                                        <input type='text' value={trailDays} minLength="1" maxLength="3" onChange={e => settrailDays(methodModel.isNumber(e))} className="w-full shadow-box bg-white w-full text-sm border border-grey placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary" placeholder='Enter days to Extend Trail Period' />
                                   
                                    </div>
                                
                                   
                                    <div className=''>
                                         <button className='bg-primary rounded-large px-4 py-2 text-sm text-white mt-4' onClick={e => handleTrailDays()}>Save</button>
                                    </div>
                               
                                </div>
                                
                                </div>
                              
                                <div className='col-span-12 md:col-span-6'>
                                    <label className='profileheddingcls'>Valid Upto</label>
                                    <div className='profiledetailscls'>{moment(planDetails && planDetails?.validUpTo).format('DD MMM YYYY')}</div>
                                </div>
                            </>
                            :
                            null
                        }
                        {planDetails?.isActive ?
                            <>
                                <div className='col-span-12 md:col-span-6'>
                                    <label className="profileheddingcls">Plan</label>
                                    <div className='profiledetailscls'>{planDetails && planDetails?.planId?.name || '--'}</div>
                                </div>
                                {!planDetails?.on_trial ?
                                    <div className='col-span-12 md:col-span-6'>
                                        <label className="profileheddingcls">Months</label>
                                        <div className='profiledetailscls'>{planDetails && planDetails?.planInterval || '--'}</div>
                                    </div>
                                    : null}
                                <div className='col-span-12 md:col-span-6'>
                                    <label className="profileheddingcls">Payments Made</label>
                                    <div className='profiledetailscls'>{planDetails && datepipeModel.date(planDetails?.updatedAt)}</div>
                                </div>

                                <div className='col-span-12 md:col-span-6'>
                                    <label className="profileheddingcls">Next Payment Due</label>
                                    <div className='profiledetailscls'>{planDetails && datepipeModel.date(planDetails?.validUpTo)}</div>
                                </div>
                            </>
                            :
                            null
                        }
                    </div>
                </div>
            </div>


            <div className=' shadow-box border !border-grey overflow-hidden rounded-large bg-white mt-4'>
                <div className="">
                    <h3 className='text-2xl font-semibold text-[#111827] p-3'>Plan Invoices</h3>
                    <table className='table table-striped'>
                        <thead>
                            <tr>
                                <th>Invoice Number</th>
                                <th>Paid At</th>
                                <th>Paid Amount</th>
                                <th>Description</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoices.map(itm => {
                                return <tr>
                                    <td>{itm.invoiceNumber}</td>
                                    <td>{datepipeModel.date(itm.createdAt)}</td>
                                    <td>{pipeModel.currency(itm.paidAmount)}</td>
                                    <td>{itm.description}</td>
                                    <td ><BsDownload className="!mt-3 flex gap-1.5 items-center text-sm font-normal !text-primary" title='Download Invoice' onClick={e => handleInvoiceDownloader(e, itm)} /></td>
                                </tr>
                            })}
                        </tbody>
                    </table>

                    {invoices.length ? <></> : <>
                        <div className='text-center mt-3 mb-3'>No Data</div>
                    </>}
                </div>
            </div>


            <div className=' shadow-box border !border-grey  rounded-large bg-white mt-4'>
                <div className="">
                    {/* Here is the Data for the  */}
                    <h3 className='text-2xl font-semibold text-[#111827] p-3 p-3'>Users</h3>
                    <table class="w-full">
                        <thead className='border-b border-[#EAECF0]'>
                            <tr className='border-b border-[#EAECF0]'>
                                <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3' onClick={e => sorting('fullName')}>Contact Person<span className='ml-2'><HiOutlineArrowDown className="shrink-0 inline text-sm" /></span></th>
                                <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3' >Create Date</th>
                                <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3' >Status</th>
                                <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3' >Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {CustomerUsers.map((itm, i) => {
                                return <tr className=''>
                                    <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]' onClick={e => view(itm.id)}>
                                        <div className='user_detail'>
                                            <img src={methodModel.userImg(itm.image)} className="user_imgs" />
                                            <div className='user_name'>
                                                <h4 className='user'>
                                                    {itm.fullName}
                                                </h4>
                                                <p className='user_info'>
                                                    {itm.email}
                                                </p>
                                                <p className='user_info'>
                                                    {itm.mobileNo ? <>+{itm.mobileNo}</> : ''}
                                                </p>
                                            </div>
                                        </div>
                                    </td>

                                    <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]'>{datepipeModel.date(itm?.createdAt)}</td>
                                    <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]'> <div className={`user_hours ${itm.status}`} onClick={() => statusChange(itm)}>
                                        <span className='contract'>
                                            {itm.status == 'deactive' ? 'Inactive' : 'Active'}
                                        </span>
                                    </div></td>
                                    {/* <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>{itm.companyAddress || '--'}</td> */}
                                    {/* <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>{itm.website||'--'}</td> */}
                                    <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]'>
                                        <div className="flex items-center justify-center gap-1.5">
                                            <Tooltip placement="top" title="View">
                                                <a className='border border-[#EBEBEB] rounded-large bg-[#FAFAFA] hover:opacity-70 w-10 h-10 text-typo flex items-center justify-center text-xl' title="View" onClick={e => view(itm.id)}>
                                                    <AiOutlineEye />
                                                </a>
                                            </Tooltip>
                                            <Tooltip placement="top" title="Edit">
                                                <a className='border cursor-pointer border-primary hover:opacity-70 rounded-large bg-[#48119614] w-10 h-10 !text-primary flex items-center justify-center text-xl' title="Edit" onClick={e => edit(itm.id)}>
                                                    <FiEdit3 />
                                                </a>
                                            </Tooltip>
                                        </div>
                                    </td>
                                    {/* end */}
                                </tr>
                            })
                            }
                        </tbody>
                    </table>
                    {CustomerUsers.length ? <></> : <>
                        <div className='text-center mt-3 mb-3'>No Data</div>
                    </>}
                </div>
            </div>





        </Layout >

    );
};

export default UserDetail;
