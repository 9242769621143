const list = [
    { id: 'active', name: 'Active' },
    { id: 'deactive', name: 'Inactive' },
]

const verifiedStatus = [
    { id: 'pending', name: 'Pending' },
    { id: 'approved', name: 'Approved' },
    { id: 'unapproved', name: 'Unapproved' },
]

const list2=[
    {id: 'Potential', name:'Potential'},
    { id: 'Qualified', name: 'Qualified' },
    { id: 'Contact Made', name: 'Contact Made' },
    { id: 'Demo Scheduled', name: 'Demo Scheduled' },
    { id: 'Proposal Made', name: 'Proposal Made' },
    { id: 'Not Interested', name: 'Not Interested' },
    
]

const find = (key) => {
    let ext = list.find(itm=>itm.id == key)
    return ext
}

const name = (key) => {
    let ext = list.find(itm=>itm.id == key)
    return ext?ext.name:key
}

const statusModel = { list,verifiedStatus, find,name,list2 }
export default statusModel