export const CostingAPIS={
    list:'api/template/product/costing/all',
    statusChange:'api/template/product/costing/status',
    delete:'api/template/product/costing',
    add:'api/template/product/costing',
    edit:'api/template/product/costing',
    view:'api/template/product/costing',
    export:'',
}
export const CostingURL={
    add:'/costing/add',
    edit:'/costing/edit',
    copy:'/costing/add',
    list:'/costing/template'
}
export const CostingConfig={
    ListHeader:'Budget Per Tour Templates',
    AddHear:' Budget Per Tour Template'
}

export const CostingNormalInputs=[
    { name: 'title', required: true, placeHolder: 'Enter Title here', outerClass:'col-span-2 mb-2 lg:col-span-12', label: 'Title', type: 'text', className: 'shadow-box bg-white w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary' },
    // { name: 'total_pax',required:true,placeHolder:'Enter No of People', label: 'No of People', type: 'number', className:'shadow-box bg-white w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary'},
    // { name: 'total_cars', required: true, placeHolder:'Enter Total Cars', label: 'No of Cars', type: 'number', className: 'shadow-box bg-white w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary' },
    // { name: 'total_tour_price', required: true, placeHolder: 'Enter Tour Price', label: 'Tour Price', type: 'number', className: 'shadow-box bg-white w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary' }
]