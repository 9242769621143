import React from 'react';
import Layout from '../../components/global/layout';
import Pagination from "react-pagination-js";
import './style.scss';
import { Link } from 'react-router-dom';
import methodModel from '../../methods/methods';
// import DatePicker from "react-datepicker";
import datepipeModel from '../../models/datepipemodel';
import { DatePicker, Space } from 'antd';
import moment from 'moment';
const { RangePicker } = DatePicker;
import { Fragment } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Tooltip } from "antd";
import { PiFileCsv, PiFileDoc } from 'react-icons/pi';
import { HiOutlineArrowDown } from 'react-icons/hi';
import { FiEdit3, FiPlus, FiUpload } from 'react-icons/fi';
import { BsTrash3 } from 'react-icons/bs';
import { useState } from 'react';
import { useEffect } from 'react';
import ApiClient from '../../methods/api/apiClient';
import loader from '../../methods/loader';
import { toast } from 'react-toastify';
import statusModel from '../../models/status.model';



const Html = ({
    sortClass,
    sorting,
    tab,
    minDate,
    filter,
    reset,
    edit,
    view,
    tabChange,
    colClick,
    ChangeRole,
    ChangeStatus,
    openModal,
    statusChange,
    pageChange,
    addCol,
    deleteItem,
    dynamicStyle = false,
    className = null,
    exportCsv,
    getData,
    uTableCols,
    removeCol,
    filters,
    tableCols,
    loaging,
    data,
    dateconvert,
    types,
    exportfun,
    total,
    isAllow
   
}) => {
    const [isOpen,setisOpen]=useState(false);
    const handleFileChange=(e)=>{
        const files=e.target.files
        const file=files.item(0);
                loader(true)
    ApiClient.postFormData('api/import/leads/data', { file }).then(res => {
      if (res.success) {
        toast.success('Leads Imported Successfully')
getData()
setisOpen(false)
      }
      loader(false)
    })
    }
    return (
        <Layout>
            <div className="flex justify-between items-center">

                <div>
                    <h3 className="text-2xl font-semibold text-[#111827]">
                        Leads
                    </h3>
                    <p class="text-sm font-normal text-[#75757A]">Here you can see all about your  Leads</p>
                </div>
                <div className="flex filterFlex">

<button className="!px-2.5 text-[#3C3E49] text-sm font-normal py-2.5 flex items-center justify-center gap-2 bg-[#fff] rounded-large shadow-btn hover:bg-[#F3F2F5] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed" onClick={() => setisOpen(true)}>
                        <PiFileDoc className="text-typo text-xl" />  Import Leads
                    </button>
                    <button className="!px-2.5 text-[#3C3E49] text-sm font-normal py-2.5 flex items-center justify-center gap-2 bg-[#fff] rounded-large shadow-btn hover:bg-[#F3F2F5] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed" onClick={() => exportfun()}>
                        <PiFileCsv className="text-typo text-xl" />  Export CSV
                    </button>

                    {/* {isAllow('addCoupons') ? */}
                        <Link className="bg-primary leading-10 mr-3 h-10 flex items-center shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-large gap-2" to="/leads/add">
                            <FiPlus className="text-xl text-white" /> Add Lead
                       </Link>
                        {/* : <></>} */}

                </div>



            </div>


            <div className="shadow-box w-full bg-white rounded-lg mt-6 border border-grey">
                <div className='flex justify-between p-4'>
                    <div className='dropdown addDropdown  chnages_date'>

                        {/* <RangePicker
                            format="MM-DD-YYYY"
                            isClearable={filters?.dateTo ? false : true}
                            onChange={(date) => dateconvert(date)}
                            placeholder={["Start Date", "End Date"]}
                        /> */}

                        {/* <DatePicker
                        selected={datepipeModel.stringToDate(filters.dateFrom)}
                        className="form-control"
                        minDate={minDate}
                        placeholderText="Date From & Date To"
                        onChange={(date) => filter({ dateFrom: datepipeModel.datetostring(date) })}
                        onKeyDown={(e) => {
                            e.preventDefault();
                        }}
                    /> */}
                        {/* <DatePicker
                        selected={datepipeModel.stringToDate(filters.dateTo)}
                        className="form-control"
                        placeholderText="Date To"
                        minDate={datepipeModel.stringToDate(filters.dateFrom) || minDate}
                        onChange={(date) => filter({ dateTo: datepipeModel.datetostring(date) })}
                        onKeyDown={(e) => {
                            e.preventDefault();
                        }}
                    /> */}
                    
                    </div>


                    <div className="dropdown1 addDropdown1 inline-flex ">

                        <Menu as="div" className="relative  list_box_active_state ml-auto">
                            <div>
                                <Menu.Button className="inline-flex w-full border justify-center gap-x-1.5 rounded-large bg-white px-3 py-2.5 text-sm font-normal  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200">
                                    {filters?.status ? filters?.status : 'All Status'}

                                    <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                                </Menu.Button>
                            </div>

                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className={`${dynamicStyle ? "" : "max-h-60"}  focus:!outline-[#481196] focus:!outline text-sm absolute z-40 ${className ? className : " min-w-[260px]"
                                    }  right-0 shadow-lg !py-2 !mt-1.5 overflow-auto bg-white  rounded-large scrollbar`}>
                                    <div className="mt-2">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a className={filters?.status == '' ? 'text-gray-700 block px-4 py-2 text-sm active' : 'text-gray-700 block px-4 py-2 text-sm'} onClick={() => ChangeStatus("")}>All Status</a>
                                            )}
                                        </Menu.Item>
                                        {statusModel.list2.map((itm)=>( <Menu.Item>
                                            {({ active }) => (
                                                <a className={filters?.status ==itm?.id ? 'text-gray-700 block px-4 py-2 text-sm active' : 'text-gray-700 block px-4 py-2 text-sm'} onClick={() => ChangeStatus(itm?.id)} >{itm?.id}</a>
                                            )}
                                        </Menu.Item>))}

                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>

    { filters?.status ? <>
                            <button className="bg-primary leading-10  h-10 shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-large ml-2" onClick={e => reset()}>
                                Reset
                            </button>
                        </> : <></>}


                    </div>

                </div>

                  <div className=" w-full bg-white rounded-lg ">
                        <div className="scrollbar w-full overflow-auto">


                            <table class="w-full">
                                <thead className='border-b border-[#EAECF0]'>
                                    <tr className='border-b border-[#EAECF0]'>
                                        <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3 ' onClick={e => sorting('name')}>Business Name <span className='ml-2'><HiOutlineArrowDown className="shrink-0 inline text-sm" /></span></th>
                                        <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3 ' onClick={e => sorting('address')}>Address<span className='ml-2'> <HiOutlineArrowDown className="shrink-0 inline text-sm" /></span></th>
                                        <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3 ' onClick={e => sorting('lastLogin')}>Timezone<span className='ml-2'> <HiOutlineArrowDown className="shrink-0 inline text-sm" /></span></th>
                                        <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3 '> Status</th>
                                        <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3 ' onClick={e => sorting('createdAt')}>Date Created<span className='ml-2'> <HiOutlineArrowDown className="shrink-0 inline text-sm" /></span></th>
                                        <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3 ' onClick={e => sorting('updatedAt')}>Last Modified <span className='ml-2'><HiOutlineArrowDown className="shrink-0 inline text-sm" /></span></th>
                                        <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3 '>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!loaging && data && data.map((itm, i) => {
                                        return <tr className=''>


                                            <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]' onClick={e => edit(itm.id)}>

                                                <div className='user_detail'>
                                                    <img src={methodModel.noImg(itm?.logo)} className="user_imgs" />

                                                    <div className='user_name'>
                                                        <h4 className='user'>
                                                            {itm.name}
                                                        </h4>
                                                    </div>
                                                </div></td>
                                            <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]'>{itm?.address}</td>
                                            <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]'>{itm?.timezone}</td>
                                            <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm min-w-64 font-normal !py-4 !border text-center border-[#EAECF0]'>
                                                <div className={`user_hours ${itm.status}`} onClick={() => statusChange(itm)}>
                                                    <Tooltip placement="top" title={itm?.status}>
                                                        <span className='contract'>
                                                            {itm.status }
                                                        </span>
                                                    </Tooltip>
                                                </div></td>
                                            <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]'>{datepipeModel.date(itm.createdAt)}</td>
                                            <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]'>{datepipeModel.date(itm.updatedAt)}</td>
                                  



                                            {/* dropdown */}
                                            <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                                                <div className="flex items-center justify-center gap-1.5">
                                               
                                                        <Tooltip placement="top" title="Edit">
                                                           

                                                            <a className="border cursor-pointer border-primary hover:opacity-70 rounded-large bg-[#48119614] w-10 h-10 !text-primary flex items-center justify-center text-xl" onClick={e => edit(itm.id)}>
                                                        <FiEdit3 />
                                                    </a>
                                                        </Tooltip> 
<>
                                                        <Tooltip placement="top" title="Delete">
                                                            <span className='border cursor-pointer !border-[#E9253129] hover:opacity-70 rounded-large bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl' onClick={() => deleteItem(itm.id)}>
                                                                <BsTrash3 />
                                                            </span>
                                                        </Tooltip>
                                                    </>
                                                </div>
                                            </td>

                                        </tr>

                                    })
                                    }
                                </tbody>
                            </table>



                        </div>
                    </div>


                {!loaging && total == 0 ? <div className="py-3 text-center">No Data</div> : <></>}
                {
                    !loaging && total > filters?.count ? <div className='paginationWrapper p-4 mt-15'>
                        {/* <span>Show {filters.count} from {total} Categories</span> */}
                        <Pagination
                            currentPage={filters.page}
                            totalSize={total}
                            sizePerPage={filters?.count}
                            changeCurrentPage={pageChange}
                        />
                    </div> : <></>
                }
                {loaging ? <div className="text-center py-4">
                    <img src="/assets/img/loader.gif" className="pageLoader" />
                </div> : <></>}

                
            </div>
             <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[99]" onClose={e=>setisOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="bg-black/40 fixed inset-0" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="rounded-xl w-full max-w-2xl overflow-hidden transition-all transform bg-white">
                  {/* {isHeader?<> */}
                    <div className="p-6 border-b flex items-center justify-between">
                    <h4 className="text-2xl font-medium text-typo">
                      Upload File
                    </h4>
                  </div>
                  {/* </>:<></>} */}
                
                  <div className="p-6">
                      <div
                className={`flex items-center justify-center w-full h-56 border-[1.5px] rounded-lg cursor-pointer `}
            >
                <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-full">
                    <div className="flex flex-col items-center justify-center gap-2 pt-5 pb-6">
                        <FiUpload className="text-xl text-[#1C1B1F]" />
                        <p className="text-sm font-light text-[#585858]">
                             Click to select file
                        </p>
                    </div>
                    <input id="dropzone-file" type="file" multiple className="hidden" onChange={handleFileChange}  accept=".pdf,.doc,.docx,.xls"/>
                </label>
            </div>
                  </div>
                  
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
        </Layout>
    );
};

export default Html;
