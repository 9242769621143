import React, { useEffect, useState } from 'react';
import Pagination from "react-pagination-js";
import Layout from '../../components/global/layout';
import { useNavigate } from 'react-router';
import moment from 'moment';
import methodModel from '../../methods/methods';
import SelectDropdown from '../../components/common/SelectDropdown';
import ApiClient from '../../methods/api/apiClient';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Tooltip } from "antd";
import { HiOutlineArrowDown } from 'react-icons/hi';
import { PiFileCsv } from 'react-icons/pi';
import { FiPlus } from 'react-icons/fi';


const Html = ({
    sortClass,
    sorting,
    tab,
    edit,
    statusChange,
    dynamicStyle = false,
    className = null,
    pageChange,
    deleteItem,
    filters,
    ChangeStatus,
    loaging,
    data,
    exportfun,
    total,
    setData,
    UpdateResellerType
}) => {
    const Navigate = useNavigate();

    const [categories, setCategories] = useState([]);
    const GetCategories = () => {
        ApiClient.get(`api/categories/listing?catType=64b23b7d690d1d5f7ae76102&sortBy=order%20asc&status=active`).then(res => {
            let newarray = [];
            res.data.map((item, index) => {
                newarray.push({ id: item.id, name: item.name })
            })
            setCategories(newarray)
        })
    }
    useEffect(() => {
        GetCategories();
    }, [])
    return (
        <Layout>
            <div className="flex justify-between items-center">

                <div>
                    <h3 className="text-2xl font-semibold text-[#111827]">
                        Resellers / Agents
                    </h3>
                    <p class="text-sm font-normal text-[#75757A]">Here you can see all about your  Resellers / Agents</p>
                </div>



                <a id='downloadFile'></a>
                <div className="flex gap-2">
                    <button className="!px-2.5 text-[#3C3E49] text-sm font-normal py-2.5 flex items-center justify-center gap-2 bg-[#fff] rounded-large shadow-btn hover:bg-[#F3F2F5] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed" onClick={() => exportfun()}>
                        <PiFileCsv className="text-typo text-xl" />  Export CSV
                    </button>
                    <button className='bg-primary leading-10 h-10 flex items-center shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-large gap-2' onClick={e => Navigate("/reseller/addedit")}>   <FiPlus className="text-xl text-white" /> Add Reseller</button>

                    <div className="dropdown addDropdown chnagesg">

                        <Menu as="div" className="relative list_box_active_state ml-auto">
                            <div>
                                <Menu.Button className="inline-flex w-full border justify-center gap-x-1.5 rounded-large bg-white px-3 py-2.5 text-sm font-normal text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200">
                                    {filters.status ? filters.status=='deactive'?'Inactive':filters.status : 'All Status'}
                                    <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                                </Menu.Button>
                            </div>

                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className={`${dynamicStyle ? "" : "max-h-60"}  focus:!outline-[#481196] focus:!outline text-sm absolute z-40 ${className ? className : " min-w-[260px]"
                                    }  right-0 shadow-lg !py-2 !mt-3 overflow-auto bg-white  rounded-lg scrollbar`}>
                                    <div className="mt-2">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a className={filters.status == '' ? 'text-gray-700 block px-4 py-2 text-sm active' : 'text-gray-700 block px-4 py-2 text-sm'} onClick={() => ChangeStatus("")}>All Status</a>

                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a className={filters.status == 'active' ? 'text-gray-700 block px-4 py-2 text-sm active' : 'text-gray-700 block px-4 py-2 text-sm'} onClick={() => ChangeStatus("active")} >Active</a>

                                            )}
                                        </Menu.Item>
                                        <Menu.Item className="divide-y-1 divide-gray-800 pt-1  mt-2">
                                            <p className="border-t"></p>
                                        </Menu.Item>

                                        <Menu.Item>
                                            {({ active }) => (
                                                <a className={filters.status == 'deactive' ? 'text-gray-700 block px-4 py-2 text-sm active' : 'text-gray-700 block px-4 py-2 text-sm'} onClick={() => ChangeStatus("deactive")} >Inactive</a>

                                            )}
                                        </Menu.Item>

                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>



                    </div>
                </div>
            </div>


            <div className="shadow-box w-full bg-white rounded-lg mt-6 border border-grey">

                {tab == 'grid' ? <>
                    <div className="cardList">
                        <div className='grid grid-cols-12 gap-4'>
                            {!loaging && data && data.map((itm, i) => {
                                return <div className='col-span-12 md:col-span-6 xl:col-span-4 mb-4'>
                                    <div className='new_cards'>
                                        <div className='user_card'>
                                            <div className='user_detail' onClick={e => edit(itm.id)}>
                                                <img src={methodModel.userImg(itm.image)} className="user_imgs" />
                                                <div className='user_name'>
                                                    <h4 className='user'>
                                                        {itm.name}
                                                    </h4>
                                                    <p className='user_info'>
                                                        {itm.contractDate}
                                                    </p>
                                                    <p className='user_info'>
                                                        {itm.booking}
                                                    </p>
                                                    <p className='user_info'>
                                                        {itm.pax}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className={`user_hours ${itm.status}`} onClick={() => statusChange(itm)}>
                                                <span className='contract'>
                                                    {itm.status == 'deactive' ? 'Inactive' : 'Active'}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </> : <>
                    <div className=" w-full bg-white rounded-lg ">
                        <div className="scrollbar w-full overflow-auto">


                            <table class="w-full">
                                <thead className='border-b border-[#EAECF0]'>
                                    <tr className='border-b border-[#EAECF0]'>
                                        <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3 ' onClick={e => sorting('name')}>Name <span className='ml-2'><HiOutlineArrowDown className="shrink-0 inline text-sm" /></span></th>
                                        <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3 ' onClick={e => sorting('ResellerType')}>Reseller Type <span className='ml-2'><HiOutlineArrowDown className="shrink-0 inline text-sm" /></span></th>
                                        {/* <th scope='col' className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3 '>Category</th> */}
                                        {/* <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3 '>Contract Date</th> */}
                                        <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3 ' onClick={e => sorting('createdAt')}>Created At <span className='ml-2'><HiOutlineArrowDown className="shrink-0 inline text-sm" /></span></th>
                                        <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3 ' onClick={e => sorting('updatedAt')}>Updated At <span className='ml-2'><HiOutlineArrowDown className="shrink-0 inline text-sm" /></span></th>
                                        <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3 '>Status</th>
                                        <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3 '>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!loaging && data && data.map((itm, i) => {
                                        return <tr className=''>
                                            <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]' onClick={e => edit(itm.id)}>
                                                <div className='user_detail'>
                                                    <img src={methodModel.userImg(itm.logo)} className="cat_img" />
                                                    <div className='user_name'>
                                                        <h4 className='user'>
                                                            {itm.name}
                                                        </h4>
                                                        <p className='user_info'>
                                                            {itm.email}
                                                        </p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]'>
                                                <SelectDropdown
                                                    isSingle={true}
                                                    id="statusDropdown"
                                                    displayValue="name"
                                                    name='category'
                                                    placeholder="Select Reseller Type"
                                                    intialValue={itm?.category?._id}
                                                    theme="search"
                                                    result={e => UpdateResellerType({ id: itm.id, category: e.value, name: itm.name })}
                                                    options={categories}
                                                />
                                            </td>
                                            {/* <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]' onClick={e => edit(itm.id)} >{itm?.category?.name}</td> */}
                                            {/* <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0] pointer' onClick={e => edit(itm.id)}>{moment(itm.contractDate).format("DD MMM YYYY")}</td> */}
                                            <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0] pointer'>{moment(itm.createdAt).format("DD MMM YYYY")}</td>
                                            <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0] pointer'>{moment(itm.updatedAt).format("DD MMM YYYY")}</td>
                                            <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]'> <div className={`user_hours ${itm.status}`} onClick={() => statusChange(itm)}>
                                                <span className='contract'>
                                                    {itm.status == 'deactive' ? 'Inactive' : 'Active'}
                                                </span>
                                            </div></td>
                                            {/* dropdown */}
                                            <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]'>
                                                <div className="flex items-center justify-center gap-1.5">
                                                    <Tooltip placement="top" title="Edit">
                                                        <a className="border cursor-pointer !border-[#E9253129] hover:opacity-70 rounded-large bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl" onClick={e => edit(itm.id)}>
                                                            <svg stroke="#481196" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M12 20h9"></path><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path></svg>

                                                        </a>
                                                    </Tooltip>
                                                    <Tooltip placement="top" title="Delete">
                                                        <span className='border cursor-pointer !border-[#E9253129] hover:opacity-70 rounded-large bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl' onClick={() => deleteItem(itm.id)}>
                                                            <svg stroke="#E0173C" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"></path></svg>

                                                        </span>
                                                    </Tooltip>
                                                </div>
                                            </td>
                                            {/* end */}
                                        </tr>
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>}
                {!loaging && total == 0 ? <div className="py-3 text-center">No Data</div> : <></>}
                {
                    !loaging && total > filters.count ? <div className='paginationWrapper p-4 mt-15'>
                        {/* <span className='text-sm text-gray-600'>Show {filters.count} from {total} Types</span> */}
                        <Pagination
                            currentPage={filters.page}
                            totalSize={total}
                            sizePerPage={filters.count}
                            changeCurrentPage={pageChange}
                        />
                    </div> : <></>
                }
                {loaging ? <div className="text-center py-4">
                    <img src="/assets/img/loader.gif" className="pageLoader" />
                </div> : <></>}
            </div>
        </Layout>
    );
};

export default Html;
