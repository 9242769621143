import React from 'react';
import Layout from '../../components/global/layout';
import Pagination from "react-pagination-js";
import './style.scss';
import { Link } from 'react-router-dom';
import methodModel from '../../methods/methods';
import datepipeModel from '../../models/datepipemodel';
import environment from '../../environment';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'antd';
import { HiOutlineArrowDown } from 'react-icons/hi';
import { BsTrash3 } from 'react-icons/bs';
import { FiEdit3, FiPlus } from 'react-icons/fi';

const Html = ({
    sortClass,
    sorting,
    view,
    edit,
    reset,
    add,
    colClick,
    tab,
    tabChange,
    ChangeRole,
    dynamicStyle = false,
    className = null,
    ChangeStatus,
    openModal,
    statusChange,
    pageChange,
    addCol,
    deleteItem,
    exportCsv,
    uTableCols,
    removeCol,
    filters,
    tableCols,
    blockunblock,
    loaging,
    data,
    exportfun,
    roles,
    role,
    isAllow,
    total = { total }
}) => {
    return (
        <Layout>
            <div className="flex justify-between items-center mb-6">
                <div>
                    <h3 className="text-2xl font-semibold text-[#111827]"> Roles</h3>
                    <p className="text-sm font-normal text-[#75757A]">Here you can see all about your  Roles</p>
                </div>
                <div className="flex items-center">

                    {isAllow('addRole') ? <Link className="bg-primary leading-10 mr-3 h-10 flex items-center shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-large gap-2" to="/roles/add">
                        <FiPlus className="text-xl text-white" /> Add Role
                    </Link> : <></>}


                    <Menu as="div" className="relative list_box_active_state ml-auto">
                        <div>
                            <Menu.Button className="inline-flex w-full border justify-center gap-x-1.5 rounded-large bg-white px-3 py-2.5 text-sm font-normal  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200">
                                All Status
                                <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                            </Menu.Button>
                        </div>

                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className={`${dynamicStyle ? "" : "max-h-60"}  focus:!outline-[#481196] focus:!outline text-sm absolute z-40 ${className ? className : " min-w-[260px]"
                                }  right-0 shadow-lg !py-2 !mt-1.5 overflow-auto bg-white  rounded-large scrollbar`}>
                                <div className="mt-2">
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a className={filters.status == '' ? 'text-gray-700 block px-4 py-2 text-sm active' : 'text-gray-700 block px-4 py-2 text-sm'} onClick={() => ChangeStatus("")} >
                                                All Status
                                            </a>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a

                                                className={filters.status == 'active' ? 'text-gray-700 block px-4 py-2 text-sm active' : 'text-gray-700 block px-4 py-2 text-sm'} onClick={() => ChangeStatus("active")}
                                            >
                                                Active
                                            </a>
                                        )}
                                    </Menu.Item>

                                    <Menu.Item className="divide-y-1 divide-gray-800 pt-1  mt-2">
                                        <p className="border-t"></p>
                                    </Menu.Item>


                                    <Menu.Item className="">
                                        {({ active }) => (
                                            <a

                                                className={filters.status == 'Inactive' ? 'text-gray-700 block px-4 py-2 text-sm active' : 'text-gray-700 block px-4 py-2 text-sm'} onClick={() => ChangeStatus("deactive")}
                                            >
                                                Inactive
                                            </a>
                                        )}
                                    </Menu.Item>

                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>



                    {/* <div className="dropdown addDropdown">
                        <button className="btn btn-primary dropdown-toggle removeBg" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {filters.status ? filters.status == "deactive" ? "Inactive" : filters.status : 'All Status'}
                        </button>
                        <div className="dropdown-menu shadow bg_hover" aria-labelledby="dropdownMenuButton">
                            <a className={filters.status == '' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeStatus("")}>All Status</a>
                            <a className={filters.status == 'active' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeStatus("active")} >Active</a>
                            <a className={filters.status == 'Inactive' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeStatus("deactive")} >Inactive</a>
                        </div>
                    </div> */}


                </div>


            </div>


            {tab == 'grid' ? <>

            </> : <>

                <div className="shadow-box w-full bg-white rounded-lg border border-grey overflow-hidden">
                    <div className="scrollbar w-full overflow-auto">

                        <table className="w-full">
                            <thead className='border-b border-[#EAECF0]'>
                                <tr className='border-b border-[#EAECF0]'>
                                    <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3 ' onClick={e => sorting('name')}>Role Name <span className='ml-1'><HiOutlineArrowDown className="shrink-0 inline text-sm" /></span></th>
                                    {/* <th scope="col" className='table_data'>Status</th> */}
                                    <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3'>Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                {!loaging && data && data.map((itm, i) => {
                                    if (itm.id != environment.adminRoleId && itm.id != environment.userRoleId)
                                        return <tr className=''>
                                            <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]' onClick={e => edit(itm.id)}>{itm.name}</td>
                                            {/* <td className='table_dats'> <div className={`user_hours ${itm.status}`} onClick={() => statusChange(itm)}>
                                            <span className='contract'>
                                                {itm.status == 'deactive' ? 'inactive' : 'active'}
                                            </span>
                                        </div></td> */}

                                            {/* dropdown */}
                                            <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                                                <div className="flex items-center justify-start gap-1.5">
                                                    {isAllow('editRole') ? <>
                                                        <Tooltip placement="top" title="Edit">
                                                            <a className="border cursor-pointer border-primary hover:opacity-70 rounded-large bg-[#48119614] w-10 h-10 !text-primary flex items-center justify-center text-xl" onClick={e => edit(itm.id)}>
                                                                <FiEdit3 />
                                                            </a>
                                                        </Tooltip>
                                                    </> : <></>}


                                                    {itm.id != environment.adminRoleId && itm.id != environment.userRoleId && isAllow('deleteRole') ? <> <Tooltip placement="top" title="Delete"> <span className='border cursor-pointer !border-[#E9253129] hover:opacity-70 rounded-large bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl' onClick={() => deleteItem(itm.id)}>
                                                        <BsTrash3 />
                                                    </span></Tooltip>
                                                    </> : <></>}

                                                </div>

                                            </td>

                                            {/* end */}

                                        </tr>

                                })
                                }
                            </tbody>
                        </table>

                    </div>


                </div>

            </>}


            {!loaging && total == 0 ? <div className="py-3 text-center">No Data</div> : <></>}

            {
                !loaging && total > filters.count ? <div className='paginationWrapper  mt-15 '>
                    {/* <span className='text-sm text-gray-600'>Show {filters.count} from {total} Categories</span> */}
                    <Pagination
                        currentPage={filters.page}
                        totalSize={total}
                        sizePerPage={filters.count}
                        changeCurrentPage={pageChange}
                    />
                </div> : <></>
            }

            {
                loaging ? <div className="text-center py-4">
                    <img src="/assets/img/loader.gif" className="pageLoader" />
                </div> : <></>
            }



        </Layout >
    );
};

export default Html;
