import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader"; 
import WaiverTemplate from "./ContractTemplate";


const ViewWaiver = () => {
  const user=useSelector(state=>state.user)
  const {id} =useParams()
  const [detail,setDetail]=useState()

  useEffect(()=>{
    loader(true)
    ApiClient.get('api/waiver/template/detail',{id:id}).then(res=>{
      if(res.success){
        setDetail(res.data)
      }
      loader(false)
    })
  },[])
  
  return (
    <>
      <Layout>
        <WaiverTemplate supplier={user} detail={detail} reseller={user} />
      </Layout>
    </>
  );
};

export default ViewWaiver;
