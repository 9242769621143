import React from 'react';
import Layout from '../../components/global/layout';
import Pagination from "react-pagination-js";
import './style.scss';
import { Link } from 'react-router-dom';
import userTableModel from '../../models/table.model';
import methodModel from '../../methods/methods';
import datepipeModel from '../../models/datepipemodel';
import environment from '../../environment';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Tooltip } from "antd";
import { PiFileCsv } from 'react-icons/pi';
import { FiEdit3, FiPlus } from 'react-icons/fi';
import { BsTrash3 } from 'react-icons/bs';
import { HiOutlineArrowDown } from 'react-icons/hi';

const Html = ({
    sorting,
    edit,
    statusChange,
    pageChange,
    deleteItem,
    dynamicStyle = false,
    className = null,
    filters,
    loaging,
    data,
    exportfun,
    changestatus,
    isAllow,
    clear,
    total = { total }
}) => {
    return (
        <Layout>
            <div className="flex justify-between items-center">
                <div>
                    <h3 className="text-2xl font-semibold text-[#111827]"> Refund Reasons</h3>
                    <p class="text-sm font-normal text-[#75757A]">Here you can see all about your  Refund Reasons</p>
                </div>

                <a id='downloadFile'></a>
                <div className="flex gap-2">


                    {/* <button className="!px-2.5 text-[#3C3E49] text-sm font-normal py-2.5 flex items-center justify-center gap-2 bg-[#fff] rounded-large shadow-btn hover:bg-[#F3F2F5] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed" onClick={() => exportfun()}>
                        <PiFileCsv className="text-typo text-xl" />  Export CSV
                    </button> */}

                        <Link className="bg-primary leading-10  h-10 flex items-center shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-large gap-2" to="/refund-reason/add">
                            <FiPlus className="text-xl text-white" />   Add Refund Reason
                        </Link>

                </div>


            </div>



            <div className='shadow-box w-full bg-white rounded-lg mt-6 border border-grey'>
                <div className='flex p-4 justify-end'>
                    <div className="dropdown1 addDropdown1 chnagesg flex gap-3">

                        <Menu as="div" className="relative list_box_active_state ml-auto">
                            <div>
                                <Menu.Button className="inline-flex w-full border justify-center gap-x-1.5 rounded-large bg-white px-3 py-2.5 text-sm font-normal  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200">
                                    {filters.status ? filters.status : 'All Status'}
                                    <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                                </Menu.Button>
                            </div>

                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className={`${dynamicStyle ? "" : "max-h-60"}  focus:!outline-[#481196] focus:!outline text-sm absolute z-40 ${className ? className : " min-w-[260px]"
                                    }  right-0 shadow-lg !py-2 !mt-1.5 overflow-auto bg-white  rounded-large scrollbar`}>
                                    <div className="mt-2">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a className={filters.status == '' ? 'text-gray-700 block px-4 py-2 text-sm active' : 'text-gray-700 block px-4 py-2 text-sm'} onClick={() => changestatus("")}>All Status</a>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a className={filters.status == 'active' ? 'text-gray-700 block px-4 py-2 text-sm active' : 'text-gray-700 block px-4 py-2 text-sm'} onClick={() => changestatus("active")} >Active</a>
                                            )}
                                        </Menu.Item>

                                        <Menu.Item className="divide-y-1 divide-gray-800 pt-1  mt-2">
                                            <p className="border-t"></p>
                                        </Menu.Item>


                                        <Menu.Item className="">
                                            {({ active }) => (
                                                <a className={filters.status == 'deactive' ? 'text-gray-700 block px-4 py-2 text-sm active' : 'text-gray-700 block px-4 py-2 text-sm'} onClick={() => changestatus("deactive")} >Inactive</a>
                                            )}
                                        </Menu.Item>

                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>


                        <button 
                        className="bg-primary leading-10 h-10 flex items-center shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-lg gap-2"
                        onClick={clear}
                        >
                             Clear
                        </button>

                    </div>
                </div>


                       


                        <div className=" w-full bg-white rounded-lg">
                            <div className="scrollbar w-full overflow-auto">


                                <table class="w-full">
                                    <thead className='border-b border-[#EAECF0]'>
                                        <tr className='border-b border-[#EAECF0]'>
                                            <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3 ' onClick={e => sorting('title')}>Name<span className='ml-2'><HiOutlineArrowDown className="shrink-0 inline text-sm" /></span></th>
                                            {/* <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3 '>Date</th> */}
                                            <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3 '>Status</th>
                                            <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3 '>Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!loaging && data && data.map((itm, i) => {
                                            return <tr className=''>
                                                <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0] capitalize' onClick={e => edit(itm.id)}>{itm.title}</td>
                                                {/* <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0] pointer' onClick={e => edit(itm.id)}>{datepipeModel.datetime(itm.createdAt)}</td> */}
                                                <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]'> <div className={`user_hours ${itm.status}`} onClick={() => statusChange(itm)}>
                                                    <Tooltip placement="top" title="Active / Inactive">
                                                        <span className='contract'>
                                                            {itm.status == 'deactive' ? 'inactive' : 'active'}
                                                        </span>
                                                    </Tooltip>
                                                </div></td>

                                                {/* dropdown */}
                                                <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>
                                                    <div className="flex items-center justify-start gap-1.5">
                                                            <Tooltip placement="top" title="Edit">
                                                                <a className="border cursor-pointer border-primary hover:opacity-70 rounded-large bg-[#48119614] w-10 h-10 !text-primary flex items-center justify-center text-xl" onClick={e => edit(itm.id)}>
                                                                    <FiEdit3 />
                                                                </a>
                                                            </Tooltip>
                                                        {itm.id != environment.planTypeId && itm.id != environment.resellerTypeId && itm.id != environment.productTypeId && isAllow('deleteTypes') ? <Tooltip placement="top" title="Delete"> <span className='border cursor-pointer !border-[#E9253129] hover:opacity-70 rounded-lg bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl ' onClick={() => deleteItem(itm.id)}>
                                                            <BsTrash3 />
                                                        </span> </Tooltip> : <></>}
                                                    </div>
                                                </td>
                                                {/* end */}
                                            </tr>
                                        })
                                        }
                                    </tbody>
                                </table>
                            </div>


                        </div>

                


                {!loaging && total == 0 ? <div className="py-3 text-center">No Data</div> : <></>}

                {
                    !loaging && total > filters.count ? <div className='paginationWrapper flex items-center justify-between mt-15'>
                        <span>Show {filters.count} from {total} Refund Reasons</span>
                        <Pagination
                            currentPage={filters.page}
                            totalSize={total}
                            sizePerPage={filters.count}
                            changeCurrentPage={pageChange}
                        />
                    </div> : <></>
                }

                {
                    loaging ? <div className="text-center py-4">
                        <img src="/assets/img/loader.gif" className="pageLoader" />
                    </div> : <></>
                }

            </div>
        </Layout >
    );
};

export default Html;
