import { useParams } from "react-router-dom";
import Layout from "../../components/global/layout";

import methodModel from "../../methods/methods";
import AddEditCostingTemplate from "./AddEditCostingTemplate";

const AddEditCostingTemp = () => {
  const { id } = useParams();

  return (
    <>
      <Layout>
        <div>
          <AddEditCostingTemplate templateId={methodModel.getPrams('copyId') ? methodModel.getPrams('copyId'):''} id={id} copy={methodModel.getPrams("copy")?true:false} />
        </div>
      </Layout>
    </>
  );
};

export default AddEditCostingTemp;
