import methodModel from "../../methods/methods";
import datepipeModel from "../../models/datepipemodel";
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
const WaiverTemplate = ({ detail, supplier, reseller, fullscreen =true,ShowFullScreen,setShowFullScreen}) => {
  const FUllScreenHandler=(e)=>{
    e.preventDefault();
    if(fullscreen){
    setShowFullScreen(!ShowFullScreen);
    }
  }
  const sanitizeAndParseHtml = (dirtyHtml) => {
    try {
      const trustedTypesPolicy = {
        createHTML(s) { return s; },
        createScriptURL(s) { return s; },
      };
  
      const cleanHtml = DOMPurify.sanitize(dirtyHtml, {
        TRUSTED_TYPES_POLICY: trustedTypesPolicy,
      });
  
      const parsedHtml = parse(cleanHtml);
  
      return parsedHtml;
    } catch (error) {
      console.error('Error sanitizing and parsing HTML:', error);
      return null;
    }
  };
  return <>
    <div className="container">
      <h1 className="con_heading mb-3">{detail?.externalName}</h1>
      {/* <p className="p_heading">Here you can see all about your product statistics</p> */}
      <h1 className="form_headingtop">{detail?.externalName}
        <ul>
          <li>5 pages</li>
        </ul>
        <span onClick={e => FUllScreenHandler(e)} class="material-icons">fullscreen</span>
      </h1>
      <div className="main_view">
        <div className="heading_contract">
        </div>
        <div className="Sup_div">
          <img src={methodModel.noImg(supplier.logo)}></img>
          <h1>{supplier.fullName}</h1>
          <p>{supplier.companyAddress || '--'} <strong>. +{supplier.companymobileno || '--'} .</strong> {supplier.contactemail || '--'}</p>
        </div>
        <h3 className="heading_rk"> <strong>OTA - 15% Waiver </strong> ({detail?.internalName})</h3>
        <h1 className="heading_rk2"> <strong>{supplier.fullName} </strong> ({detail?.internalName})</h1>

        <div className="div_backgeound">
          <h1>💡 Waiver rates :</h1>
          <p

          >{sanitizeAndParseHtml(detail?.firstSection)}</p>
        </div>
        <div className=" mb-4">
          <div className="row_layouts">
            <div className="row">
              <div className="col-md-5">
                <h1>Reseller details</h1>
                <p>Lorem ipsum dolor sit amet consectetur.</p>
              </div>
              <div className="col-md-7">
                <div className="data_h1">
                  
                </div>
              </div>
            </div>
          </div>
          {detail?.resellerCollectData?.map(itm => {
            return <div className="row_layouts">
              <div className="row">
                <div className="col-md-5">
                  <h1>{itm.name}</h1>
                </div>
                <div className="col-md-7">
                  <div className="data_h1">
                    <h1>--</h1>
                  </div>
                </div>
              </div>
            </div>
          })}

        </div>

        {detail?.body?.map(itm => {
          return <>
            <h1 className="heading_rk2"> <strong> {itm.name} </strong></h1>
            {/* <div className="div_backgeound">
              <h1>💡 Contract rates :</h1>
              <p>Lorem ipsum dolor sit amet consectetur. Vel dolor nulla risus convallis at vulputate adipiscing venenatis. Dictum egestas tellus donec nunc phasellus. Ac risus enim diam ut mauris blandit amet ipsum. Lectus a nulla facilisi id et.</p>
            </div> */}

            {itm?.type == 'upload' ? <embed src={methodModel.noImg(itm?.doc, 'document')} width="600" height="400" type='application/pdf'></embed>:<div className="trems_row" 
           
            >
              {sanitizeAndParseHtml(itm?.content)}</div>}
          </>
        })}


        {detail?.questions?.length > 0 && <h4 className="text-2xl font-medium text-[#3C3E49] mt-10 mb-3">Questions </h4>}
        {detail?.questions?.length > 0 && detail?.questions.map((item, index) => {
          return <>
            <div className='grid grid-cols-2 gap-x-4 items-center'>
              <div className='mb-4'>
                <label className=''>{index + 1}. {item?.question}</label>
              </div>
              <div className='mb-4'>
                {item?.type == 'text' ? <input className="shadow-box bg-white w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary disabled:!bg-gray-200 mt-2 mb-2" /> : item?.type == 'dropdown' ? <>
                  <div>
                    <div className="col-span-12">
                      <div className="flex flex-wrap items-center gap-3">
                        <select className="shadow-box bg-white w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary disabled:!bg-gray-200 mt-2 mb-2">
                          {item?.options?.map((item) => <option>{item}</option>)}
                        </select>

                      </div>

                    </div>
                  </div>
                </> :
                  <div>
                    <div className="col-span-8">
                      <div className="flex flex-wrap items-center gap-3">
                        <label className="text-typo cursor-pointer mb-0 text-sm font-medium flex items-center gap-1.5 h-8 !px-3 shadow-box !rounded-md">
                          <input
                            className="h-4 w-4 checked:!ring-primary checked:ring-0 ring-offset-1 cursor-pointer shrink-0 rounded-full border-[1.6px] border-[#3C3E4966] !text-primary"
                            type="radio"
                            value="yes"

                            name={`questiondata${index}`}
                          />
                          Yes
                        </label>
                        <label className="text-typo cursor-pointer mb-0 text-sm font-medium flex items-center gap-1.5 h-8 !px-3 shadow-box !rounded-md">
                          <input
                            className="h-4 w-4 checked:!ring-primary checked:ring-0 ring-offset-1 cursor-pointer shrink-0 rounded-full border-[1.6px] border-[#3C3E4966] !text-primary"
                            type="radio"
                            value="no"

                            name={`questiondata${index}`}
                          />
                          No
                        </label>
                      </div>

                    </div>
                  </div>
                }
              </div>
            </div>
          </>
        })}



        <h1 className="heading_rk2"> <strong> Products & Rates </strong></h1>

        {/* <div className="div_backgeound">
          <h1>💡 Contract rates :</h1>
          <p>Lorem ipsum dolor sit amet consectetur. Vel dolor nulla risus convallis at vulputate adipiscing venenatis. Dictum egestas tellus donec nunc phasellus. Ac risus enim diam ut mauris blandit amet ipsum. Lectus a nulla facilisi id et.</p>
        </div> */}

        <h1 className="table_heading">Service</h1>
        <div className="table-responsive mb-4">
          <table className="table table_div table-striped">
            <tr>
              <th rowspan="2">
                <h1>Service</h1>
                <p>Melbourne City Express</p>
              </th>
              <th colspan="5">
                <h1>All pricing is in AUD and inclusive of GST</h1>
              </th>
            </tr>
            <tr>
              <td className="td_back">Retail Price </td>
              <td className="td_back"> Tier 1 Net rate</td>
              <td className="td_back"> Tier 2 Net rate</td>
              <td className="td_back">Tier 3 Net rate </td>
              <td className="td_back">Tier 4 Net rate </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
          </table>
        </div>

        <div className="table-responsive mb-4">
          <table className="table table_div table-striped">
            <tr>
              <th rowspan="2">
                <h1>Service</h1>
                <p>Melbourne City Express</p>
              </th>
              <th colspan="5">
                <h1>All pricing is in AUD and inclusive of GST</h1>
              </th>
            </tr>
            <tr>
              <td className="td_back">Retail Price </td>
              <td className="td_back"> Tier 1 Net rate</td>
              <td className="td_back"> Tier 2 Net rate</td>
              <td className="td_back">Tier 3 Net rate </td>
              <td className="td_back">Tier 4 Net rate </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
          </table>
        </div>

        <div className="table-responsive mb-4">
          <table className="table table_div table-striped">
            <tr>
              <th rowspan="2">
                <h1>Service</h1>
                <p>Melbourne City Express</p>
              </th>
              <th colspan="5">
                <h1>All pricing is in AUD and inclusive of GST</h1>
              </th>
            </tr>
            <tr>
              <td className="td_back">Retail Price </td>
              <td className="td_back"> Tier 1 Net rate</td>
              <td className="td_back"> Tier 2 Net rate</td>
              <td className="td_back">Tier 3 Net rate </td>
              <td className="td_back">Tier 4 Net rate </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
          </table>
        </div>

        <div className="view_namelist">
          <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" id="exampleCheck1" />
            <label class="form-check-label" for="exampleCheck1">Agreed</label>
          </div>
          <p>I here by agrre above terms and condition and rates</p>
          <div className="row">
            <div className="col-md-6">
              <div className="list_page">
                <h1>{supplier?.companyName || '--'}</h1>
                {detail?.yourSignature?.map(itm => {
                  return <>
                    <h3>{itm.name}</h3>
                    <p>__</p>
                  </>
                })}


                {/* <h3>Date</h3>
      <p>Wed 27 Sep, 2023</p> */}
              </div>
            </div>
            <div className="col-md-6">
              <div className="list_page">
                <h1>{reseller?.companyName || '--'}</h1>
                {detail?.resellerSignature?.map(itm => {
                  return <>
                    <h3>{itm.name}</h3>
                    <p>__</p>
                  </>
                })}

                {/* <h3>Date</h3>
      <p>Wed 27 Sep, 2023</p> */}
              </div>
            </div>
          </div>
        </div>



        {detail?.allProducts && detail?.rateSheet ? <></> : <>
          <hr></hr>
          <div className="footer_crm">
            <img src="/assets/img/pdf-icon.jpg"></img>
            <div className="crm_list">
              <div>
                <h1>{detail?.rateSheetName || '--'}</h1>
                <p>Added {datepipeModel.date(detail?.updatedAt, supplier?.companyDateFormat)} at {datepipeModel.time(detail?.updatedAt)}</p>
              </div>
              <ul>
                <li><a href={methodModel.noImg(detail?.rateSheet, 'document')} target="_blank">Download</a></li>
                {/* <li>Delete</li> */}
              </ul>
            </div>
          </div>
        </>}



      </div>
    </div>
  </>
}

export default WaiverTemplate