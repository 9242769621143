import React from 'react';
import Layout from '../../components/global/layout';
import Pagination from "react-pagination-js";
import './style.scss';
import { Link } from 'react-router-dom';
import datepipeModel from '../../models/datepipemodel';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Tooltip } from "antd";
import { PiFileCsv } from "react-icons/pi";
import { HiOutlineArrowDown } from "react-icons/hi";
import { FiEdit3, FiPlus } from "react-icons/fi";
import { AiOutlineEye } from "react-icons/ai";
import { BsTrash3 } from "react-icons/bs";
import { FaCopy } from "react-icons/fa";
import SelectDropdown from '../../components/common/SelectDropdown';
import { MdClear } from 'react-icons/md';



const Html = ({
  sortClass,
  sorting,
  edit,
  copy,
  view,
  ChangeStatus,
  statusChange,
  pageChange,
  reset,
  deleteItem,
  EnablestatusChange,
  filters,
  EnableChangeStatus,
  loaging,
  data,
  className = null,
  exportfun,
  user,
  dynamicStyle = false,
  total = { total }
}) => {
  return (
    <Layout>
      <div className="d-flex justify-content-between align-items-center mb-6">


        <div>
          <h3 className="text-2xl font-semibold text-[#111827]">
            Contract Templates
          </h3>
          <p class="text-sm font-normal text-[#75757A]">Here you can see all about your  Contract Templates</p>
        </div>




        <div className="flex">
          <Link className="bg-primary leading-10 mr-3 h-10 flex items-center shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-large gap-2" to="/crm/add">
            <FiPlus className="text-xl text-white" />  Add Contract
          </Link>

        </div>
      </div>


      <div className='bg-white shadow-box rounded-large w-full border border-grey'>

        <div className='flex items-center justify-end gap-3 p-6'>

          <button className="!px-2.5 text-[#3C3E49] text-sm font-normal py-2.5 flex items-center justify-center gap-2 bg-[#fff] rounded-large shadow-btn hover:bg-[#F3F2F5] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed" onClick={() => exportfun()}>
            <PiFileCsv className="text-typo text-xl" />  Export CSV
          </button>
            <div className="dropdown1 addDropdown1 mr-2">
                            <SelectDropdown
                  isSingle={true}
                  id="statusDropdown2"
                  displayValue="name"
                  placeholder="All"
                  intialValue={filters.isEnabled}
                  result={(e) => EnableChangeStatus(e.value)}
                  options={[
                    { id: "true", name: "Enable" },
                    { id: "false", name: "Disable" },
                  ]}
                />

                    </div>
          <div className="dropdown1 addDropdown1 mr-2">


            <Menu as="div" className="relative list_box_active_state ml-auto">
              <div>
                <Menu.Button className="inline-flex w-full  justify-center gap-x-1.5 rounded-large bg-white px-3 py-2.5 border text-sm font-normal text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200">
                  {filters.status ? filters.status == "deactive" ? "Inactive" : filters.status : 'All Status'}
                  <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className={`${dynamicStyle ? "" : "max-h-60"}  focus:!outline-[#481196] focus:!outline text-sm absolute z-40 ${className ? className : " min-w-[260px]"
                  }  right-0 shadow-lg !py-2 !mt-1.5 overflow-auto bg-white rounded-lg scrollbar`}>
                  <div className="mt-2">
                    <Menu.Item>
                      {({ active }) => (
                        <a className={filters.status == '' ? 'bg-blue-100 text-gray-900 active' : 'text-gray-700 block px-4 py-2 text-sm'} onClick={() => ChangeStatus("")}>All Status</a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a className={filters.status == 'active' ? 'bg-blue-100 text-gray-900 active' : 'text-gray-700 block px-4 py-2 text-sm'} onClick={() => ChangeStatus("active")} >Active</a>
                      )}
                    </Menu.Item>

                    <Menu.Item className="divide-y-1 divide-gray-800 pt-1  mt-2">
                      <p className="border-t"></p>
                    </Menu.Item>


                    <Menu.Item className="">
                      {({ active }) => (
                        <a className={filters.status == 'Inactive' ? 'bg-blue-100 text-gray-900 active' : 'text-gray-700 block px-4 py-2 text-sm'} onClick={() => ChangeStatus("deactive")} >Inactive</a>
                      )}
                    </Menu.Item>

                  </div>
                </Menu.Items>
              </Transition>
            </Menu>



          </div>
               {filters?.search || filters.status||filters?.isEnabled ? (
                <>
                  <button
                    className="bg-primary leading-10 mr-3 h-10 flex items-center shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-lg gap-2"
                    onClick={(e) => reset()}>
                    <MdClear className="text-xl text-white" />
                    Reset
                  </button>
                </>
              ) : (
                <></>
              )}
        </div>






        <div className="w-full bg-white rounded-lg">
          <div className="scrollbar w-full overflow-auto">


            <table class="w-full">
              <thead className='border-b border-[#EAECF0]'>
                <tr className='border-b border-[#EAECF0]'>
                  <th
                    scope="col"
                    className="cursor-pointer flex items-center text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3"
                    onClick={(e) => sorting("internalName")}
                  >
                    {" "}
                    Internal Name <span>

                      <HiOutlineArrowDown className="shrink-0 inline text-sm" />

                      {/* <i className={sortClass("internalName")} /> */}
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3"
                    onClick={(e) => sorting("externalName")}
                  >
                    {" "}
                    External Name <span>  <HiOutlineArrowDown className="shrink-0 inline text-sm" /></span>
                  </th>

                  <th
                    scope="col"
                    className="cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3"
                    onClick={(e) => sorting("resellerTypeDetail.name")}
                  >
                    {" "}
                    Reseller Type <span> <HiOutlineArrowDown className="shrink-0 inline text-sm" /></span>
                  </th>

                  <th
                    scope="col"
                    className="cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3"
                    onClick={(e) => sorting("createdAt")}
                  >
                    Date Created <span></span>
                  </th>
                  <th
                    scope="col"
                    className="cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3"
                    onClick={(e) => sorting("createdBy")}
                  >
                    {" "}
                    Created By <span> <HiOutlineArrowDown className="shrink-0 inline text-sm" /></span>
                  </th>
                  <th
                    scope="col"
                    className="cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3"
                    onClick={(e) => sorting("updatedAt")}
                  >
                    Date Updated <span > <HiOutlineArrowDown className="shrink-0 inline text-sm" /></span>
                  </th>
                  <th scope="col" className="cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3">
                    Enable/Disable
                  </th>
                  <th scope="col" className="cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3">
                    Status{" "}
                  </th>
                  <th scope="col" className="cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3">Action</th>
                </tr>
              </thead>
              <tbody>
                {!loaging && data && data.map((itm, i) => {
                  return <tr className="">
                    <td className="hover:underline hover:opacity-80 text-primary !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border border-[#EAECF0]" onClick={(e) => view(itm.id)}>
                      {itm.internalName || "--"}
                    </td>
                    <td className="!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]" >
                      {itm.externalName || "--"}
                    </td>
                    <td className="!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]" >
                      {itm?.resellerTypeDetail?.name || "--"}
                    </td>

                    <td className="!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]">
                      {datepipeModel.date(
                        itm.createdAt,
                        user?.companyDateFormat || "--"
                      )}
                    </td>
                    <td className="!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]" onClick={(e) => view(itm.id)}>
                      {itm.addedBy?.fullName || "--"}
                    </td>
                    <td className="!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]">
                      {datepipeModel.date(
                        itm.updatedAt,
                        user?.companyDateFormat
                      )}
                    </td>
                    <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]'>
                                            {/* <div className={`user_hours ${itm.status}`} */}
                                            <div className={`user_hours ${itm.isEnabled}`}
                                                onClick={() => EnablestatusChange(itm)}>
                                                <Tooltip placement="top" title="Enable / Disable">
                                                    <span className='contract'>
                                                        {itm.isEnabled ? 'Enable' : 'Disable'}
                                                    </span>
                                                </Tooltip>
                                            </div></td>
                    <td className="!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]">
                      {" "}
                      <div
                        className={`user_hours ${itm.status}`}
                        onClick={() => statusChange(itm)}
                      >
                        <Tooltip placement="top" title="Active / Inactive">
                          <span className="contract">
                            {itm.status == "deactive" ? "Inactive" : "Active"}
                          </span>
                        </Tooltip>
                      </div>
                    </td>
                    {/* commisionRate */}
                    {/* dropdown */}
                    <td className="!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]">
                      <div className="flex items-center justify-center gap-1.5">
                       
                          <>
                            <Tooltip placement="top" title="Edit">
                              <span className="border cursor-pointer border-primary hover:opacity-70 rounded-large bg-[#48119614] w-10 h-10 !text-primary flex items-center justify-center text-xl"
                                onClick={(e) => edit(itm.id)}>
                                <FiEdit3 />
                              </span>


                            </Tooltip>
                            <Tooltip placement="top" title="Delete">
                              <span
                                className="border cursor-pointer !border-[#E9253129] hover:opacity-70 rounded-lg bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl mr-1"
                                onClick={() => deleteItem(itm.id)}
                              >
                                <BsTrash3 />
                              </span>
                            </Tooltip>

                          </> 
                        <Tooltip placement="top" title="Copy">
                          <span
                            className="border !border-[#94D5AE] rounded-lg bg-[#ECF4EF] hover:opacity-70 w-10 h-10 text-[#11a74da4] flex items-center justify-center text-xls"
                            title="Copy"
                            onClick={() => copy(itm.id)}
                          >
                            <FaCopy className='text-[#b4b7fb]' />
                          </span>
                        </Tooltip>
                        <Tooltip placement="top" title="View">
                          <span className="border border-[#EBEBEB] rounded-large bg-[#FAFAFA] hover:opacity-70 w-10 h-10 text-typo flex items-center justify-center text-xl" title="View" onClick={e => view(itm.id)}>
                            <AiOutlineEye className='' />
                          </span>
                        </Tooltip>
                      </div>
                    </td>
                    {/* end */}
                  </tr>
                })
                }
              </tbody>
            </table>
          </div>
        </div>

        {!loaging && total == 0 ? <div className="py-3 text-center">No Data</div> : <></>}
        {
          !loaging && total > filters.count ? <div className='paginationWrapper  mt-15'>
            {/* <span className='text-sm text-gray-600'>Show {filters.count} from {total} Categories</span> */}
            <Pagination
              currentPage={filters.page}
              totalSize={total}
              sizePerPage={filters.count}
              changeCurrentPage={pageChange}
            />
          </div> : <></>
        }

        {loaging ? <div className="text-center py-4">
          <img src="/assets/img/loader.gif" className="pageLoader" />
        </div> : <></>}
      </div>
    </Layout>
  );
};

export default Html;
