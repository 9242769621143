import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import Main from './main';
import * as serviceWorker from './serviceWorker';
import * as Sentry from "@sentry/react";
import environment from "../src/environment/index";

if (window.location.href.includes('prod')) {
    Sentry.init({
        dsn: environment.sentry_dsn,
        integrations: [
            Sentry.browserTracingIntegration(),
            // Replay integration
            Sentry.replayIntegration(),
            // Canvas recording with Replay
            Sentry.replayCanvasIntegration(),
        ],
        replaysOnErrorSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
    });
}
// ReactDOM.render(<Main />, document.getElementById('root'));
const root = createRoot(document.getElementById('root'));
root.render(<Main />)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
