import { useState, useEffect, useRef } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { useSelector } from "react-redux";
import { crmType } from "../../models/type.model";
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import Layout from "../../components/global/layout";
import methodModel from "../../methods/methods";
import { Editor } from "@tinymce/tinymce-react";
import environment from "../../environment";
import Breadcrumb from "../../components/common/Breadcrumb";
import SelectDropdown from "../../components/common/SelectDropdown";
import Pagination from "react-pagination-js";
// import PDFViewer from 'pdf-viewer-reactjs'
import ContractTemplate from "./ContractTemplate";


const AddEditCRM = () => {
  const user = useSelector((state) => state.user);
  const defaultvalue = () => {
    let keys = { ...crmType };
    Object.keys(crmType).map((itm) => {
      keys[itm] = "";
    });
    keys.body = [{ name: '', content: '', type: 'write' }]
    keys.resellerCollectData = []
    keys.yourSignature = [{ name: '' }]
    keys.resellerSignature = []
    keys.products = []
    keys.allProducts = false
    keys.sameAsAbove = false
    keys.applyRateForAll = false
    keys.applyRateForAllProducts = false
    keys.showAddress = false
    return keys;
  };
  const [ShowFullScreen, setShowFullScreen] = useState(false);
  const { id } = useParams();
  const [doc, setDoc] = useState('');
  const [form, setform] = useState({ ...defaultvalue() });
  const history = useNavigate();
  const param = useParams();
  const [detail, setdetail] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [resellerTypes, setResellerTypes] = useState([]);

  const formValidation = [];
  const specialChars = useRef([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [tab, setTab] = useState('detail')
  const [filters, setFilter] = useState({ page: 1, count: 20, search: '' })
  const [total, setTotal] = useState(0)
  const [productLoader, setProductLoader] = useState(false)


  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    let invalid = methodModel.getFormError(formValidation, form);
    if (invalid) return;
    let method = "post";
    let url = "api/contract/template";
    let value = {
      ...form,
      rateSheet: doc,
      isAdmin: true,
    };
    if (value.id && !methodModel.getPrams("copy")) {
      method = "put";
      url = "api/contract/template/update";
    } else {
      delete value.id;
    }

    if (!value.resellerType) delete value.resellerType

    loader(true);
    ApiClient.allApi(url, value, method).then((res) => {
      if (res.success) {
        // ToastsStore.success(res.message);
        history("/crm");
      }
      loader(false);
    });
  };

  const uploadDoc = (e) => {
    let files = e.target.files
    let file = files.item(0)
    loader(true)
    ApiClient.postFormData('api/upload/document', { file }).then(res => {
      if (res?.fileName) setDoc(res?.fileName)
      loader(false)
    })
  }


  const uploadBodyDoc = (e, i) => {
    let files = e.target.files
    let file = files.item(0)
    loader(true)
    ApiClient.postFormData('api/upload/document', { file }).then(res => {
      if (res?.fileName) {
        updateField('body', { name: 'doc', value: res?.fileName, index: i });
      }
      loader(false)
    })
  }

  const back = () => {
    history(-1);
  };

  const getProducts = (p = {}) => {
    let categories = ''
    if (form.categoryToApply.length && !form.applyRateForAll) {
      categories = form.categoryToApply.toString()
    }
    let f = {
      ...filters,
      categories: categories,
      ...p
    }

    setProductLoader(true)
    ApiClient.get("api/products/listing", f).then((res) => {
      if (res.success) {
        setProducts(res.data);
        setTotal(res.total)
      }
      setProductLoader(false)
    });
  };

  const getResellerTypes = () => {
    ApiClient.get('api/categories/listing', { catType: environment.resellerTypeId }).then(res => {
      if (res.success) {
        setResellerTypes(res.data)
      }
    })
  }

  const filter = (p = {}) => {
    setFilter({ ...filters, ...p })
    getProducts(p)
  }

  useEffect(() => {
    if (id) {
      loader(true);
      ApiClient.get("api/contract/template/detail", { id }).then((res) => {
        if (res.success) {
          let value = res.data;
          let payload = crmType;
          setdetail([res.data]);
          Object.keys(payload).map((itm) => {
            payload[itm] = value[itm];
          });

          if (value.resellerType) {
            payload.resellerType = value.resellerType._id
          }
          if (value.rateSheet) setDoc(value.rateSheet)

          if (methodModel.getPrams("copy")) payload.name = `Copy of ${payload.name}`;

          setform({
            ...payload,
          });
        }
        loader(false);
      });
    }
    getResellerTypes()
    getProducts()
    const getCategories = () => {
      ApiClient.get("api/categories/listing", {
        catType: environment.productTypeId,
        status: "active",
      }).then((res) => {
        if (res.success) {
          setCategories(res.data);
        }
      });
    };
    getCategories();
  }, [id]);

  const tabChange = (t) => {
    setTab(t)
  }

  const logoOptions = [
    { id: 'left', name: 'Left' },
    { id: 'middle', name: 'Middle' },
    { id: 'right', name: 'Right' },
  ]

  const addField = (aKey = 'resellerCollectData') => {
    let arr = form?.[aKey] || []
    let payload = {}
    if (aKey == 'resellerCollectData') {
      payload = { name: '' }
    }
    if (aKey == 'body') {
      payload['type'] = 'write'
    }
    arr.push(payload)
    setform({ ...form, [aKey]: [...arr] })
  }

  const updateField = (aKey = 'resellerCollectData', e) => {
    let index = e?.getAttribute?.('index') || e.index
    let value = e.value
    let key = e.name


    let arr = form?.[aKey] || []
    arr[index][key] = value


    setform({ ...form, [aKey]: [...arr] })
  }

  const removeField = (aKey = 'resellerCollectData', i) => {
    let arr = form?.[aKey] || []
    setform({ ...form, [aKey]: [...arr.filter((itm, index) => i != index)] })
  }


  const formChange = (e, type = 'text') => {
    let key = e.name
    let value = type == 'number' ? methodModel.isNumber({ target: e }) : e.value
    setform({ ...form, [key]: value })
  }

  const checkChange = (e) => {
    let key = e.name
    let checked = e.checked
    let value = e.value
    let arr = form?.[key] || []

    if (checked) {
      arr.push(value)
    } else {
      arr = arr.filter((itm) => itm != value)
    }
    setform({ ...form, [key]: [...arr] })
    if (key == 'categoryToApply') {
      filter({ categories: arr.toString(), page: 1 })
    }
  }

  const sameAsAbove = (e) => {
    let resellerSignature = [...form.resellerSignature]
    if (e) {
      resellerSignature = []
      form.yourSignature.map(itm => {
        resellerSignature.push({ name: itm.name })
      })
    }
    setform({ ...form, resellerSignature: [...resellerSignature], sameAsAbove: e })
  }

  const productCheck = (itm) => {
    let arr = form?.products || []
    let payload = {
      id: itm.id,
      name: itm.name,
      categoryName: itm.categoryName,
      category: itm.category,
      commission: ''
    }

    if (!arr.find(aitm => aitm.id == itm.id)) {
      arr.push(payload)
    } else {
      arr = arr.filter((aitm) => aitm.id != itm.id)
    }

    setform({ ...form, products: [...arr] })
  }

  const updateProduct = (id, value, key = 'commission') => {
    let arr = form?.products || []
    arr = arr.map(itm => {
      if (itm.id == id) itm[key] = value
      return itm
    })

    setform({ ...form, products: [...arr] })
  }

  const pageChange = (e) => {
    filter({ page: e })
  }

  return (
    <>
      <Layout>
        {!ShowFullScreen ? <div class="container">
          <Breadcrumb
            links={[
              {
                name: "Contract Templates",
                link: "/crm",
              },
            ]}
            currentPage={`${form && form.id && !methodModel.getPrams("copy") ? 'Edit' : 'Add'} Contract Template`}
          />
          <div className="grid grid-cols-12 md:grid-cols-2">
            <div class="contract_main mb-4 mt-4 ">
              <div className="ml-3  mb-6">
                <h3 className="text-2xl font-semibold text-[#111827]">
                  {form && form.id && !methodModel.getPrams("copy") ? 'Edit' : 'Add'} Contract
                </h3>
                <p class="text-sm font-normal text-[#75757A]">Here you can see all about your  Contract</p>
              </div>


              <ul class="ul_new nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button className={`btn_new nav-link ${tab == 'detail' ? 'active' : ''}`} onClick={e => tabChange('detail')}>Basic Details</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button className={`btn_new nav-link ${tab == 'section' ? 'active' : ''}`} onClick={e => tabChange('section')}>Contract Intro & Info</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button className={`btn_new nav-link ${tab == 'body' ? 'active' : ''}`} onClick={e => tabChange('body')}>Body</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button className={`btn_new nav-link ${tab == 'product' ? 'active' : ''}`} onClick={e => tabChange('product')}>Products & Rates</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button className={`btn_new nav-link ${tab == 'fields' ? 'active' : ''}`} onClick={e => tabChange('fields')}>Required Fields</button>
                </li>
              </ul>
              <form
                onSubmit={handleSubmit}
              >
                <div class="tab-content" id="pills-tabContent">
                  {tab == 'detail' ? <>
                    <div class="tab-pane fade show active" id="pills-page_1" role="tabpanel" aria-labelledby="pills-page_1-tab">
                      <div class="tab_1">

                        <div class="layout_tab">
                          <div class="grid grid-cols-12 gap-4">
                            <div class="col-span-12 md:col-span-4">
                              <h3>Show logo</h3>
                            </div>

                            <div class="col-span-12 md:col-span-8">
                              <label class="form-check form-check-inline check_inline">
                                <input class="form-check-input" type="radio" value="" checked={!form.showLogo ? true : false} onChange={e => setform({ ...form, showLogo: e.target.value })} name="showLogo" />
                                <span class="form-check-label">None</span>
                              </label>
                              {logoOptions.map(itm => {
                                return <label class="form-check form-check-inline check_inline">
                                  <input class="form-check-input" type="radio" value={itm.id} checked={form.showLogo == itm.id ? true : false} onChange={e => setform({ ...form, showLogo: e.target.value })} name="showLogo" />
                                  <span class="form-check-label">{itm.name}</span>
                                </label>
                              })}
                            </div>



                            <div class="col-span-12 md:col-span-4">
                              <h3>Show Address</h3>
                            </div>

                            <div class="col-span-12 md:col-span-8">


                              <label class="form-check form-check-inline check_inline">
                                <input class="form-check-input" type="radio" value="yes" checked={form.showAddress ? true : false} onChange={e => setform({ ...form, showAddress: true })} name="showAddress" />
                                <span class="form-check-label">Yes</span>
                              </label>
                              <label class="form-check form-check-inline check_inline">
                                <input class="form-check-input" type="radio" value="no" checked={!form.showAddress ? true : false} onChange={e => setform({ ...form, showAddress: false })} name="showAddress" />
                                <span class="form-check-label">No</span>
                              </label>
                              {form.showAddress ? <>
                                <div className="input_icons mt-4 mb-4">
                                  <input type="text" class="form-control" placeholder="" value={form.address} onChange={e => setform({ ...form, address: e.target.value })} required />
                                  <span class="material-icons email">map</span>
                                  <span class="material-icons check">check_circle</span>
                                </div>
                              </> : <></>}

                            </div>

                            <div class="col-span-12 md:col-span-4">
                              <h3>Internal Name for template <i className="fa fa-info infoIcon pointer" title="Name for internal use"></i></h3>

                            </div>

                            <div class="col-span-12 md:col-span-8">
                              <div className="mb-4">
                                <input type="text" class="form-control" placeholder="" value={form.internalName} onChange={e => setform({ ...form, internalName: e.target.value })} required />
                              </div>
                            </div>

                            <div class="col-span-12 md:col-span-4">
                              <h3>External Name for template <i className="fa fa-info infoIcon pointer" title="Name that reseller will see"></i></h3>
                            </div>

                            <div class="col-span-12 md:col-span-8">
                              <div className="mb-4">
                                <input type="text" class="form-control" placeholder="" value={form.externalName} onChange={e => setform({ ...form, externalName: e.target.value })} required />
                              </div>
                            </div>

                            <div class="col-span-12 md:col-span-4">
                              <h3>Reseller Type</h3>
                            </div>

                            <div class="col-span-12 md:col-span-8">
                              <div className="mb-4">
                                <SelectDropdown
                                  displayValue='name'
                                  placeholder=''
                                  intialValue={form.resellerType}
                                  result={e => {
                                    setform({ ...form, resellerType: e.value })
                                  }}
                                  theme="search"
                                  id="sourceDropdown"
                                  options={resellerTypes}
                                />
                              </div>
                            </div>

                            {/* <hr className="hr_class"></hr> */}

                            <div class="col-span-12 md:col-span-12">
                              <div className=" text-right">
                                <button type="button" class="btn btn-primary parimarybtn" onClick={back}>Cancel</button>
                                <button class="btn btn-primary ml-3">Save changes</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </> : <></>}


                  {tab == 'section' ? <>
                    <div class="tab-pane fade show active" id="pills-page_2" role="tabpanel" aria-labelledby="pills-page_2-tab">

                      <div class="tab_2">

                        <div class="layout_tab">
                          <div class="grid grid-cols-12 gap-4">
                            <div class="col-span-12 md:col-span-4">
                              <h3>First Section</h3>
                            </div>

                            <div class="col-span-12 md:col-span-8">
                              <Editor
                                textareaName="content"
                                value={form?.firstSection}
                                className="tuncketcls"
                                apiKey={'1caoqocqc05l8re1m7vm2ck0f4lbaqvg7w9k6a7wr7pkrbhv'}
                                onEditorChange={(newValue, editor) => {
                                  setform({ ...form, firstSection: newValue })
                                }}
                                init={{
                                  selector: "textarea#autocompleter-cardmenuitem",
                                  height: 250,
                                  setup: (editor) => {
                                    const onAction = (
                                      autocompleteApi,
                                      rng,
                                      value
                                    ) => {
                                      editor.selection.setRng(rng);
                                      editor.insertContent(value);
                                      autocompleteApi.hide();
                                    };
                                    const getMatchedChars = (pattern) => {
                                      return specialChars.current.filter(
                                        (char) => char.text.indexOf(pattern) !== -1
                                      );
                                    };

                                    editor.ui.registry.addAutocompleter(
                                      "specialchars_cardmenuitems",
                                      {
                                        trigger: "$",
                                        minChars: 0,
                                        columns: 1,
                                        highlightOn: ["char_name"],
                                        onAction: onAction,
                                        fetch: (pattern) => {
                                          return new Promise((resolve) => {
                                            const results = getMatchedChars(
                                              pattern
                                            ).map((char) => ({
                                              type: "cardmenuitem",
                                              value: "${" + char.value + "}",
                                              label: char.text,
                                              items: [
                                                {
                                                  type: "cardcontainer",
                                                  direction: "vertical",
                                                  items: [
                                                    {
                                                      type: "cardtext",
                                                      text: char.text,
                                                      name: "char_name",
                                                    },
                                                  ],
                                                },
                                              ],
                                            }));
                                            resolve(results);
                                          });
                                        },
                                      }
                                    );
                                  },
                                }}
                                required
                              />
                              {/* <p className="mt-2"><span className="color_no">275</span> characters left</p> */}
                            </div>



                            <div class="col-span-12 md:col-span-12 border-top pt-4 mb-4">
                              <h3>Add list of data you want to collect from your reseller - we have given you some examples below</h3>

                            </div>

                            {form?.resellerCollectData?.map((itm, i) => {
                              return <>
                                <div class="col-span-12 md:col-span-4 mb-3">
                                  <h3>Field Name {i + 1} <i className="fa fa-info infoIcon pointer" title="Bussiness Name or Name of Reseller"></i></h3>

                                </div>

                                <div class="col-span-12 md:col-span-8 mb-3">
                                  <div className="input_control">
                                    <input type="text" class="form-control " placeholder="" value={itm.name} name="name" index={i} onChange={e => updateField('resellerCollectData', e.target)} required />
                                    <i class="fa fa-trash" onClick={e => removeField('resellerCollectData', i)} aria-hidden="true"></i>
                                  </div>
                                  <p className="mb-0 add_input" onClick={e => addField('resellerCollectData')}> <i class="fa fa-plus mr-2" aria-hidden="true"></i>Add Another </p>
                                </div>
                              </>
                            })}

                            {!form.resellerCollectData.length ? <><div className="col-span-12 md:col-span-12 text-right mb-3">
                              <button className="btn btn-primary" type="button" onClick={e => addField('resellerCollectData')}>Add Field</button>
                            </div></> : <></>}


                            {/* <hr className="hr_class"></hr> */}

                            <div class="col-span-12 md:col-span-12">
                              <div className=" text-right">
                                <button class="btn btn-primary parimarybtn" type="button" onClick={back}>Cancel</button>
                                <button class="btn btn-primary ml-3">Save changes</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </> : <></>}

                  {tab == 'body' ? <>
                    <div class="tab-pane fade show active" id="pills-page_3" role="tabpanel" aria-labelledby="pills-page_3-tab">

                      <div class="tab_3">

                        <div class="layout_tab">
                          <div class="grid grid-cols-12 gap-4">

                            {form?.body?.map((itm, i) => {
                              return <>

                                <div class="col-span-12 md:col-span-4 mb-3">
                                  <h3>Section Name {i + 1} <i className="fa fa-info infoIcon pointer" title="Terms and Condtions"></i></h3>

                                </div>
                                <div class="col-span-12 md:col-span-8 mb-3">
                                  <div className="input_control">
                                    <input type="text" class="form-control" placeholder="" value={itm.name} name="name" index={i} onChange={e => updateField('body', e.target)} required />
                                    <i class="fa fa-trash" aria-hidden="true" onClick={e => removeField('body', i)}></i>
                                  </div>
                                  <p className="mb-0 add_input" onClick={e => addField('body')}> <i class="fa fa-plus mr-2" aria-hidden="true"></i>Add Another </p>
                                </div>


                                <div class="col-span-12 md:col-span-4 mb-3">
                                  <h3>Content</h3>
                                </div>
                                <div class="col-span-12 md:col-span-8 mb-3 flex">
                                  <div>
                                    <input name={`content${i}`} type="radio" className="" value="write" onChange={e => { updateField('body', { name: 'type', value: e.target.value, index: i }); updateField('body', { name: 'doc', value: '', index: i }) }} checked={itm?.type == 'write'} /><label className="ml-2">Write Content</label>
                                  </div>
                                  <div className="ml-3">
                                    <input name={`content${i}`} type="radio" className="" checked={itm?.type == 'upload'} value="upload" onChange={e => updateField('body', { name: 'type', value: e.target.value, index: i })} /><label className="ml-2">Upload Pdf</label>
                                  </div>
                                </div>


                                {itm?.type == 'write' ? <div class="col-span-12 md:col-span-12 mb-3">
                                  <Editor
                                    textareaName="content"
                                    value={itm?.content}
                                    className="tuncketcls"
                                    onEditorChange={(newValue, editor) => {
                                      updateField('body', { name: 'content', value: newValue, index: i });
                                    }}
                                    init={{
                                      selector: "textarea#autocompleter-cardmenuitem",
                                      height: 250,
                                      setup: (editor) => {
                                        const onAction = (
                                          autocompleteApi,
                                          rng,
                                          value
                                        ) => {
                                          editor.selection.setRng(rng);
                                          editor.insertContent(value);
                                          autocompleteApi.hide();
                                        };
                                        const getMatchedChars = (pattern) => {
                                          return specialChars.current.filter(
                                            (char) => char.text.indexOf(pattern) !== -1
                                          );
                                        };

                                        editor.ui.registry.addAutocompleter(
                                          "specialchars_cardmenuitems",
                                          {
                                            trigger: "$",
                                            minChars: 0,
                                            columns: 1,
                                            highlightOn: ["char_name"],
                                            onAction: onAction,
                                            fetch: (pattern) => {
                                              return new Promise((resolve) => {
                                                const results = getMatchedChars(
                                                  pattern
                                                ).map((char) => ({
                                                  type: "cardmenuitem",
                                                  value: "${" + char.value + "}",
                                                  label: char.text,
                                                  items: [
                                                    {
                                                      type: "cardcontainer",
                                                      direction: "vertical",
                                                      items: [
                                                        {
                                                          type: "cardtext",
                                                          text: char.text,
                                                          name: "char_name",
                                                        },
                                                      ],
                                                    },
                                                  ],
                                                }));
                                                resolve(results);
                                              });
                                            },
                                          }
                                        );
                                      },
                                    }}
                                    required
                                  />
                                  {/* <p><span className="color_no">275</span> characters left</p> */}
                                  <p className="mb-0 add_input mb-3" onClick={e => addField('body')}> <i class="fa fa-plus mr-2" aria-hidden="true"></i>Add Another </p>
                                </div> : <>
                                  {itm?.doc ? <>
                                    <div className="col-span-12 md:col-span-8 mb-3">
                                      <div style={{ height: "200px", overflow: "scroll" }}>
                                        {/* <PDFViewer
                                          document={{
                                            url: methodModel.noImg(itm?.doc, 'document'),
                                          }}
                                        /> */}
                                      </div>
                                      <i class="fa fa-trash" aria-hidden="true" onClick={e => updateField('body', { name: 'doc', value: '', index: i })}></i>
                                    </div>
                                  </> : <>
                                    <div class="form-group drag_input col-span-12 md:col-span-12 mb-3">
                                      <input type="file" accept=".pdf" class="form-control-file" id="exampleFormControlFile2" onChange={e => uploadBodyDoc(e, i)} />
                                      <div className="upload_imginput">
                                        <span class="material-icons">cloud_upload</span>
                                        <p><span className="">Click to upload</span></p>
                                        <p> Pdf (max. 100mb)</p>
                                      </div>
                                    </div>
                                  </>}


                                </>}


                              </>
                            })}

                            {!form.body.length ? <><div className="col-span-12 md:col-span-12 text-right mb-3">
                              <button className="btn btn-primary" type="button" onClick={e => addField('body')}>Add Field</button>
                            </div></> : <></>}

                            <div class="col-span-12 md:col-span-12">
                              <div className="text-right">
                                <button class="btn btn-primary parimarybtn" type="button" onClick={back}>Cancel</button>
                                <button type="submit" className="!px-4 text-sm font-medium text-primary hover:text-white hover:no-underline h-10 flex items-center justify-center gap-2 bg-white rounded-extra_large shadow-btn hover:bg-primary border border-primary transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed ml-2">Save Changes</button>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </> : <></>}


                  {tab == 'product' ? <>
                    <div class="tab-pane fade show active" id="pills-page_4" role="tabpanel" aria-labelledby="pills-page_4-tab">

                      <div class="tab_4">

                        <div class="layout_tab">
                          <div class="grid grid-cols-12 gap-4">
                            <div class="col-span-12 md:col-span-4">
                              <h3 onClick={e => setform({ ...form, allProducts: false })} className="pointer"><input type="radio" checked={!form.allProducts} /> Upload Rate Sheet <i className="fa fa-info infoIcon pointer" title="Name of Sheet"></i></h3>
                            </div>

                            <div class="col-span-12 md:col-span-8">
                              <input className="form-control mb-3" placeholder="" name="rateSheetName" disabled={form.allProducts} value={form.rateSheetName} onChange={e => formChange(e.target)} required></input>
                              <div>
                                {doc ? <>
                                  <div>
                                    <a href={methodModel.noImg(doc, 'document')} target="_blank">
                                      <span class="material-icons">note</span> {doc}
                                    </a>
                                  </div>
                                </> : <>
                                  <div class="form-group drag_input">
                                    <input type="file" class="form-control-file" disabled={form.allProducts} id="exampleFormControlFile1" onChange={uploadDoc} />
                                    <div className="upload_imginput">
                                      <span class="material-icons">cloud_upload</span>
                                      <p><span className="">Click to upload</span>or drag and drop</p>
                                      <p> XLS, CSV, TXT (max. 100mb)</p>
                                    </div>
                                  </div>
                                </>}

                                <div className=" mb-3">
                                  {doc && !form.allProducts ? <>
                                    <button class="btn btn-primary parimarybtn" type="button" onClick={e => setDoc('')}>Reset</button>
                                  </> : <></>}

                                  {/* <button class="btn btn-primary ml-3" type="button">Update</button> */}
                                </div>
                              </div>
                            </div>



                            {/* <hr className="hr_class"></hr> */}
                            <div class="col-span-12 md:col-span-12 mb-3">
                              <h3 onClick={e => setform({ ...form, allProducts: true })} className="pointer"><input type="radio" checked={form.allProducts} /> Customised Commission</h3>
                            </div>

                            <div class="col-span-12 md:col-span-4 mb-3">
                              <h3>Apply Rates to all Products & Categories</h3>
                            </div>

                            <div class="col-span-12 md:col-span-8 mb-3">
                              <div className="check_checklist">
                                <label class="form-check form-check-inline check_inline">
                                  <input class="form-check-input" type="radio" value="yes" disabled={!form.allProducts} checked={form.applyRateForAll ? true : false} onChange={e => setform({ ...form, applyRateForAll: true })} name="applyRateForAll" />
                                  <span class="form-check-label">Yes</span>
                                </label>
                                <label class="form-check form-check-inline check_inline">
                                  <input class="form-check-input" type="radio" value="no" disabled={!form.allProducts} checked={!form.applyRateForAll ? true : false} onChange={e => setform({ ...form, applyRateForAll: false })} name="applyRateForAll" />
                                  <span class="form-check-label">No</span>
                                </label>

                              </div>
                            </div>

                            {form.applyRateForAll ? <>
                              <div class="col-span-12 md:col-span-4 mb-3">
                                <h3>Add Commission rates</h3>

                              </div>

                              <div class="col-span-12 md:col-span-8 mb-3">
                                <input className="form-control" value={form.commisionRate} disabled={!form.allProducts} name="commisionRate" maxlength="3" placeholder="20%" max="100" onChange={e => formChange(e.target, 'number')} required />
                              </div>
                            </> : <>

                              <div class="col-span-12 md:col-span-4 mb-3">
                                <h3>Select Categories to apply?</h3>

                              </div>

                              <div class="col-span-12 md:col-span-8 mb-3">
                                <div className="check_checklist">

                                  {categories.map(itm => {
                                    return <label class="form-group form-check">
                                      <input type="checkbox" class="form-check-input" disabled={!form.allProducts} checked={form?.categoryToApply?.includes(itm.id) ? true : false} value={itm.id} name="categoryToApply" onChange={e => checkChange(e.target)} />
                                      <span class="form-check-label">{itm.name}</span>
                                    </label>
                                  })}
                                </div>
                              </div>

                              <div class="col-span-12 md:col-span-4 mb-3">
                                <h3>Apply rates to all products?</h3>

                              </div>

                              <div class="col-span-12 md:col-span-8 mb-3">
                                <div className="check_checklist">
                                  <label class="form-check form-check-inline check_inline">
                                    <input class="form-check-input" type="radio" value="yes" disabled={!form.allProducts} checked={form.applyRateForAllProducts ? true : false} onChange={e => setform({ ...form, applyRateForAllProducts: true })} name="applyRateForAllProducts" />
                                    <span class="form-check-label">Yes</span>
                                  </label>
                                  <label class="form-check form-check-inline check_inline">
                                    <input class="form-check-input" type="radio" value="no" disabled={!form.allProducts} checked={!form.applyRateForAllProducts ? true : false} onChange={e => setform({ ...form, applyRateForAllProducts: false })} name="applyRateForAllProducts" />
                                    <span class="form-check-label">No</span>
                                  </label>
                                </div>
                              </div>

                              {form?.applyRateForAllProducts ? <>
                                <div class="col-span-12 md:col-span-4 mb-3">
                                  <h3>Add Commission rates</h3>

                                </div>

                                <div class="col-span-12 md:col-span-8 mb-3">
                                  <input className="form-control" value={form.productCommisionRate} name="productCommisionRate" maxlength="3" placeholder="20%" max="100" onChange={e => formChange(e.target, 'number')} required />
                                </div>
                              </> : <></>}
                            </>}







                            {/* <hr className="hr_class"></hr> */}
                            <form className="filterFlex col-span-12 md:col-span-12 mb-3 flex" onSubmit={e => { e.preventDefault(); filter() }}>
                              <input type="text" className="form-control" placeholder="Search by Product Name" value={filters.search} onChange={e => filter({ search: e.target.value, page: 1 })} />
                              {/* <button className="btn btn-primary" type="button" onClick={e=>filter()}>Search</button> */}
                            </form>



                            <div className="col-span-12 md:col-span-12 mb-3">
                              {productLoader ? <>
                                {/* <div className="text-center">Loading... <i className="fa fa-spin fa-spinner"></i></div> */}
                                <div className="shine mb-2" style={{ height: '50px' }}></div>
                                <div className="shine mb-2" style={{ height: '50px' }}></div>
                                <div className="shine mb-2" style={{ height: '50px' }}></div>
                                <div className="shine mb-2" style={{ height: '50px' }}></div>
                                <div className="shine mb-2" style={{ height: '50px' }}></div>
                                <div className="shine mb-2" style={{ height: '50px' }}></div>
                                <div className="shine mb-2" style={{ height: '50px' }}></div>
                                <div className="shine mb-2" style={{ height: '50px' }}></div>
                                <div className="shine mb-2" style={{ height: '50px' }}></div>
                                <div className="shine mb-2" style={{ height: '50px' }}></div>
                              </> : <>
                                {total == 0 ? <>
                                  <div className="text-center">No Products</div>
                                </> : <></>}
                                <div className="table-responsive">
                                  <table className="table">
                                    {products.map(itm => {
                                      return <tr>
                                        <td onClick={e => productCheck(itm)}>
                                          <input type="checkbox" checked={form.products.find(pitm => pitm.id == itm.id) ? true : false} />
                                        </td>
                                        <td onClick={e => productCheck(itm)}>
                                          <div className=" flex items-center gap-3">

                                            <img className="h-10 w-10 rounded mr-2" src={methodModel.noImg(itm?.images?.length ? itm?.images[0].thumbnailUrl : '')} />
                                            <p className="mb-0">{itm.name}</p>

                                          </div>
                                        </td>
                                        <td onClick={e => productCheck(itm)}>
                                          <p className="mb-0">{itm.categoryName || '--'}</p>
                                        </td>
                                        <td>
                                          <input className="form-control" value={form.products.find(pitm => pitm.id == itm.id) ? form.products.find(pitm => pitm.id == itm.id).commission : ''} disabled={!form.products.find(pitm => pitm.id == itm.id) ? true : false} required={form.products.find(pitm => pitm.id == itm.id) ? true : false} maxLength="3" max="100" onChange={e => updateProduct(itm.id, methodModel.isNumber(e))} placeholder="20%" />
                                        </td>
                                      </tr>
                                    })}
                                  </table>
                                </div>

                                {total > filters.count ? <div className='paginationWrapper'>
                                  <span className='text-sm text-gray-600'>Show {filters.count} from {total} Products</span>
                                  <Pagination
                                    currentPage={filters.page}
                                    totalSize={total}
                                    sizePerPage={filters.count}
                                    changeCurrentPage={pageChange}
                                  />
                                </div> : <></>
                                }
                              </>}
                            </div>

                            {/* <hr className="hr_class"></hr> */}

                            <div class="col-span-12 md:col-span-12">
                              <div className=" text-right">
                                <button class="btn btn-primary parimarybtn" type="button" onClick={back}>Cancel</button>
                                <button type="submit" className="!px-4 text-sm font-medium text-primary hover:text-white hover:no-underline h-10 flex items-center justify-center gap-2 bg-white rounded-extra_large shadow-btn hover:bg-primary border border-primary transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed ml-2">Save Changes</button>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </> : <></>}

                  {tab == 'fields' ? <>
                    <div class="tab-pane fade show active" id="pills-page_5" role="tabpanel" aria-labelledby="pills-page_5-tab">

                      <div class="tab_3">

                        <div class="layout_tab">
                          <div class="grid grid-cols-12 gap-4">

                            <div class="col-span-12 md:col-span-12 mb-3">
                              <h3>Signature field for your signatories</h3>
                            </div>

                            {form?.yourSignature?.map((itm, i) => {
                              return <>
                                <div class="col-span-12 md:col-span-4 mb-3">
                                  <h3>Field Name {i + 1} <i className="fa fa-info infoIcon pointer" title="Business Name"></i></h3>

                                </div>

                                <div class="col-span-12 md:col-span-8 mb-3">
                                  <div className="input_control">
                                    <input type="text" class="form-control " placeholder="" value={itm.name} name="name" index={i} onChange={e => updateField('yourSignature', e.target)} required />
                                    <i class="fa fa-trash" onClick={e => removeField('yourSignature', i)} aria-hidden="true"></i>
                                  </div>
                                  <p className="mb-0 add_input" onClick={e => addField('yourSignature')}> <i class="fa fa-plus mr-2" aria-hidden="true"></i>Add Another </p>
                                </div>
                              </>
                            })}

                            {!form.yourSignature.length ? <><div className="col-span-12 md:col-span-12 text-right mb-3">
                              <button className="btn btn-primary" type="button" onClick={e => addField('yourSignature')}>Add Field</button>
                            </div></> : <></>}

                            {/* <hr className="hr_class"></hr> */}

                            <div className="col-span-12 md:col-span-4 mb-3">
                              <h3>Signature field for Reseller signatories</h3>
                            </div>
                            <div className="col-span-12 md:col-span-8">
                              <div className="check_checklist">
                                <div class="form-group form-check">
                                  <input type="checkbox" class="form-check-input" id="exampleCheck1sameAsAbove" checked={form.sameAsAbove ? true : false} onChange={e => sameAsAbove(e.target.checked)} />
                                  <label class="form-check-label" for="exampleCheck1sameAsAbove">Same as above</label>
                                </div>
                              </div>
                            </div>

                            {form?.resellerSignature?.map((itm, i) => {
                              return <>
                                <div class="col-span-12 md:col-span-4 mb-3">
                                  <h3>Field Name {i + 1} <i className="fa fa-info infoIcon pointer" title="Business Name"></i></h3>

                                </div>

                                <div class="col-span-12 md:col-span-8 mb-3">
                                  <div className="input_control">
                                    <input type="text" class="form-control" placeholder="" disabled={form.sameAsAbove} value={itm.name} name="name" index={i} onChange={e => updateField('resellerSignature', e.target)} required />
                                    {form.sameAsAbove ? <></> : <>
                                      <i class="fa fa-trash" onClick={e => removeField('resellerSignature', i)} aria-hidden="true"></i>
                                    </>}
                                  </div>
                                  {form.sameAsAbove ? <></> : <>
                                    <p className="mb-0 add_input" onClick={e => addField('resellerSignature')}> <i class="fa fa-plus mr-2" aria-hidden="true"></i>Add Another </p>
                                  </>}

                                </div>
                              </>
                            })}

                            {!form.resellerSignature.length ? <><div className="col-span-12 md:col-span-12 text-right mb-3">
                              <button className="btn btn-primary" type="button" onClick={e => addField('resellerSignature')}>Add Field</button>
                            </div></> : <></>}

                            {/* <hr className="hr_class"></hr> */}

                            <div class="col-span-12 md:col-span-12">
                              <div className=" text-right">
                                <button class="btn btn-primary parimarybtn" type="button" onClick={back}>Cancel</button>
                                <button type="submit" className="!px-4 text-sm font-medium text-primary hover:text-white hover:no-underline h-10 flex items-center justify-center gap-2 bg-white rounded-extra_large shadow-btn hover:bg-primary border border-primary transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed ml-2">Save Changes</button>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </> : <></>}

                </div>
              </form>

            </div>
            <div>
              <ContractTemplate supplier={user} detail={form} reseller={user} fullscreen={true} ShowFullScreen={ShowFullScreen} setShowFullScreen={setShowFullScreen} />
            </div>
          </div>
        </div> : <ContractTemplate supplier={user} detail={form} reseller={user} fullscreen={true} ShowFullScreen={ShowFullScreen} setShowFullScreen={setShowFullScreen} />}
      </Layout>
    </>
  );
};

export default AddEditCRM;
