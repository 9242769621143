import methodModel from "../../methods/methods";
import datepipeModel from "../../models/datepipemodel";
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
const ContractTemplate = ({ detail, supplier, reseller,fullscreen=false ,ShowFullScreen,setShowFullScreen  }) => {
  const ShowFullScreenHandler=(e)=>{
    e.preventDefault();
    if(fullscreen){
    setShowFullScreen(!ShowFullScreen);
    }
  }
  const sanitizeAndParseHtml = (dirtyHtml) => {
    try {
      const trustedTypesPolicy = {
        createHTML(s) { return s; },
        createScriptURL(s) { return s; },
      };
  
      const cleanHtml = DOMPurify.sanitize(dirtyHtml, {
        TRUSTED_TYPES_POLICY: trustedTypesPolicy,
      });
  
      const parsedHtml = parse(cleanHtml);
  
      return parsedHtml;
    } catch (error) {
      console.error('Error sanitizing and parsing HTML:', error);
      return null;
    }
  };
  return <>
    <div className="container">
      <h1 className="con_heading mb-3">{detail?.externalName}</h1>
      {/* <p className="p_heading">Here you can see all about your product statistics</p> */}
      <h1 className="form_headingtop">{detail?.externalName}
        <ul>
          <li>5 pages</li>
        </ul>
        <span onClick={e=>ShowFullScreenHandler(e)} class="material-icons">fullscreen</span>
      </h1>
      <div className="main_view">
        <div className="heading_contract">
        </div>
        <div className="Sup_div">
          <img src={methodModel.noImg(supplier.logo)}></img>
          <h1>{supplier.fullName}</h1>
          <p>{supplier.companyAddress || '--'} <strong>. +{supplier.companymobileno || '--'} .</strong> {supplier.contactemail || '--'}</p>
        </div>
        <h3 className="heading_rk"> <strong>OTA - 15% Contract </strong> ({detail?.internalName})</h3>
        <h1 className="heading_rk2"> <strong>{supplier.fullName} </strong> ({detail?.internalName})</h1>

        <div className="div_backgeound">
          <h1>💡 Contract rates :</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur. Vel dolor nulla risus convallis at vulputate adipiscing venenatis. Dictum egestas tellus donec nunc phasellus. Ac risus enim diam ut mauris blandit amet ipsum. Lectus a nulla facilisi id et.</p>
        </div>
        <div className=" mb-4">
          <div className="row_layouts">
            <div className="row">
              <div className="col-md-5">
                <h1>Reseller details</h1>
                <p>Lorem ipsum dolor sit amet consectetur.</p>
              </div>
              <div className="col-md-7">
                <div className="data_h1">
                  <p
               
                  >
                    {sanitizeAndParseHtml(detail?.firstSection)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {detail?.resellerCollectData?.map(itm => {
            return <div className="row_layouts">
              <div className="row">
                <div className="col-md-5">
                  <h1>{itm.name}</h1>
                </div>
                <div className="col-md-7">
                  <div className="data_h1">
                    <h1>--</h1>
                  </div>
                </div>
              </div>
            </div>
          })}

        </div>

        {detail?.body?.map(itm => {
          return <>
            <h1 className="heading_rk2"> <strong> {itm.name} </strong></h1>
            {/* <div className="div_backgeound">
              <h1>💡 Contract rates :</h1>
              <p>Lorem ipsum dolor sit amet consectetur. Vel dolor nulla risus convallis at vulputate adipiscing venenatis. Dictum egestas tellus donec nunc phasellus. Ac risus enim diam ut mauris blandit amet ipsum. Lectus a nulla facilisi id et.</p>
            </div> */}

            {itm?.type == 'upload' ? <embed src={methodModel.noImg(itm?.doc, 'document')} width="600" height="400" type='application/pdf'></embed>:<div className="trems_row" 
           
            >{sanitizeAndParseHtml(itm?.content)}</div>}
          </>
        })}






        <h1 className="heading_rk2"> <strong> Products & Rates </strong></h1>

        {/* <div className="div_backgeound">
          <h1>💡 Contract rates :</h1>
          <p>Lorem ipsum dolor sit amet consectetur. Vel dolor nulla risus convallis at vulputate adipiscing venenatis. Dictum egestas tellus donec nunc phasellus. Ac risus enim diam ut mauris blandit amet ipsum. Lectus a nulla facilisi id et.</p>
        </div> */}

        <h1 className="table_heading">Service</h1>
        <div className="table-responsive mb-4">
          <table className="table table_div table-striped">
            <tr>
              <th rowspan="2">
                <h1>Service</h1>
                <p>Melbourne City Express</p>
              </th>
              <th colspan="5">
                <h1>All pricing is in AUD and inclusive of GST</h1>
              </th>
            </tr>
            <tr>
              <td className="td_back">Retail Price </td>
              <td className="td_back"> Tier 1 Net rate</td>
              <td className="td_back"> Tier 2 Net rate</td>
              <td className="td_back">Tier 3 Net rate </td>
              <td className="td_back">Tier 4 Net rate </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
          </table>
        </div>

        <div className="table-responsive mb-4">
          <table className="table table_div table-striped">
            <tr>
              <th rowspan="2">
                <h1>Service</h1>
                <p>Melbourne City Express</p>
              </th>
              <th colspan="5">
                <h1>All pricing is in AUD and inclusive of GST</h1>
              </th>
            </tr>
            <tr>
              <td className="td_back">Retail Price </td>
              <td className="td_back"> Tier 1 Net rate</td>
              <td className="td_back"> Tier 2 Net rate</td>
              <td className="td_back">Tier 3 Net rate </td>
              <td className="td_back">Tier 4 Net rate </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
          </table>
        </div>

        <div className="table-responsive mb-4">
          <table className="table table_div table-striped">
            <tr>
              <th rowspan="2">
                <h1>Service</h1>
                <p>Melbourne City Express</p>
              </th>
              <th colspan="5">
                <h1>All pricing is in AUD and inclusive of GST</h1>
              </th>
            </tr>
            <tr>
              <td className="td_back">Retail Price </td>
              <td className="td_back"> Tier 1 Net rate</td>
              <td className="td_back"> Tier 2 Net rate</td>
              <td className="td_back">Tier 3 Net rate </td>
              <td className="td_back">Tier 4 Net rate </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
            <tr>
              <td> <h1>Adult one way</h1></td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td>$19.75 </td>
              <td> $19.75</td>
              <td>$19.75 </td>
            </tr>
          </table>
        </div>

        <div className="view_namelist">
          <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" id="exampleCheck1" />
            <label class="form-check-label" for="exampleCheck1">Agreed</label>
          </div>
          <p>I here by agrre above terms and condition and rates</p>
          <div className="row">
            <div className="col-md-6">
              <div className="list_page">
                <h1>{supplier?.companyName || '--'}</h1>
                {detail?.yourSignature?.map(itm => {
                  return <>
                    <h3>{itm.name}</h3>
                    <p>__</p>
                  </>
                })}


                {/* <h3>Date</h3>
      <p>Wed 27 Sep, 2023</p> */}
              </div>
            </div>
            <div className="col-md-6">
              <div className="list_page">
                <h1>{reseller?.companyName || '--'}</h1>
                {detail?.resellerSignature?.map(itm => {
                  return <>
                    <h3>{itm.name}</h3>
                    <p>__</p>
                  </>
                })}

                {/* <h3>Date</h3>
      <p>Wed 27 Sep, 2023</p> */}
              </div>
            </div>
          </div>
        </div>



        {detail?.allProducts && detail?.rateSheet ? <></> : <>
          <hr></hr>
          <div className="footer_crm">
            <img src="/assets/img/pdf-icon.jpg"></img>
            <div className="crm_list">
              <div>
                <h1>{detail?.rateSheetName || '--'}</h1>
                <p>Added {datepipeModel.date(detail?.updatedAt, supplier?.companyDateFormat)} at {datepipeModel.time(detail?.updatedAt)}</p>
              </div>
              <ul>
                <li><a href={methodModel.noImg(detail?.rateSheet, 'document')} target="_blank">Download</a></li>
                {/* <li>Delete</li> */}
              </ul>
            </div>
          </div>
        </>}



      </div>
    </div>
  </>
}

export default ContractTemplate