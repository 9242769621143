import { useParams } from "react-router-dom";
import Layout from "../../components/global/layout";

import methodModel from "../../methods/methods";
import AddEditCostingTourTemplate from "./AddEditCostingTemplate";

const AddEditCostingTourTemp = () => {
  const { id } = useParams();

  return (
    <>
      <Layout>
        <div>
          <AddEditCostingTourTemplate templateId={methodModel.getPrams('copyId') ? methodModel.getPrams('copyId'):''} id={id} copy={methodModel.getPrams("copy")?true:false} />
        </div>
      </Layout>
    </>
  );
};

export default AddEditCostingTourTemp;
