import React from 'react';
import Layout from '../../components/global/layout';
import Pagination from "react-pagination-js";
import './style.scss';
import { Link } from 'react-router-dom';
import methodModel from '../../methods/methods';
import SelectDropdown from '../../components/common/SelectDropdown';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Tooltip } from "antd";
import { PiFileCsv } from 'react-icons/pi';
import { HiOutlineArrowDown } from 'react-icons/hi';
import { FiEdit3, FiPlus } from 'react-icons/fi';
import { BsTrash3 } from 'react-icons/bs';


const Html = ({
    sortClass,
    sorting,
    tab,
    edit,
    view,
    tabChange,
    colClick,
    ChangeRole,
    openModal,
    statusChange,
    ChangeStatus,
    pageChange,
    addCol,
    deleteItem,
    dynamicStyle = false,
    className = null,
    exportCsv,
    uTableCols,
    removeCol,
    filters,
    tableCols,
    loaging,
    data,
    types,
    CurrencyData,
    ContinentData,
    CountryData,
    reset,
    search,
    filter,
    exportfun,
    isAllow,
    total = { total }
}) => {
    return (
        <Layout>
            <div className="flex justify-between items-center">

                <div>
                    <h3 className="text-2xl font-semibold text-[#111827]">
                        Region
                    </h3>
                    <p class="text-sm font-normal text-[#75757A]">Here you can see all about your  Region</p>
                </div>




                <div className="flex gap-2">

                    <button className="!px-2.5 text-[#3C3E49] text-sm font-normal py-2.5 flex items-center justify-center gap-2 bg-[#fff] rounded-large shadow-btn hover:bg-[#F3F2F5] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed" onClick={() => exportfun()}>
                        <PiFileCsv className="text-typo text-xl" />  Export CSV
                    </button>


                    {isAllow('addRegion') ?
                        <Link className="bg-primary leading-10 h-10 flex items-center shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-large gap-2" to="/regions/add">
                            <FiPlus className="text-xl text-white" />  Add Region
                        </Link>
                        : <></>}


                    {/* <span className='mx-2'>
                        <div className="dropdown addDropdown region_currency">
                            <SelectDropdown
                                isSingle={true}
                                id="statusDropdown"
                                placeholder='All Currency'
                                displayValue="currency"
                                theme='search'
                                intialValue={filters.currencyId}
                                result={e => { filter({ currencyId: e.value }) }}
                                options={CurrencyData}
                            />
                        </div>
                    </span> */}



                    {/* <div className="dropdown addDropdown mr-2">
                        <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Type: {filters.catType ? filters.catType : 'All'}
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a className={filters.catType == '' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeRole('')}>All</a>
                            {types && types.map(itm => {
                                return <a className={filters.catType == itm.key ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeRole(itm.key)}>{itm.name}</a>
                            })}
                        </div>
                    </div> */}
                    {/* <div className='icons_tab'>
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class={`nav-link ${tab == 'grid' ? 'active' : ''}`} id="employee-tab" onClick={e => tabChange('grid')}>
                                    <i className="fa fa-th"></i>
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class={`nav-link staff ${tab == 'list' ? 'active' : ''}`} id="staff-tab" onClick={e => tabChange('list')}>
                                    <i className="fa fa-list"></i>
                                </button>
                            </li>
                        </ul>
                    </div> */}
                    {/* 
                    <div className="dropdown addDropdown mr-2">
                        <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuColumns" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Add Columns
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuColumns">
                            {uTableCols().map(itm => {
                                return <a className="dropdown-item" onClick={() => addCol(itm)} key={itm.value}>{itm.value}</a>
                            })}
                        </div>
                    </div> */}
                    {/* <button onClick={exportCsv} className="btn btn-primary" type="button">
                        Export
                    </button> */}
                </div>
            </div>




            <div className='shadow-box w-full bg-white rounded-lg mt-6 border border-grey'>
                <div className='flex justify-between items-center items-stretch jus gap-4 p-4'>
                    <div className='flex items-center'>
                        <span className='mx-0 w-80'>
                            <div className="dropdown addDropdown chnages mr-2">
                                <div className=''>
                                    <SelectDropdown
                                        isSingle={true}
                                        id="statusDropdown"
                                        placeholder='All Continents'
                                        displayValue="name"
                                        theme='search'
                                        intialValue={filters.continentId}
                                        result={e => { filter({ continentId: e.value, countryId: '' }) }}
                                        options={ContinentData}
                                    />
                                </div>
                            </div>
                        </span>

                        <span className='mx-0 w-80'>
                            <div className="dropdown addDropdown chnages mr-2">
                                <div className=''>
                                    <SelectDropdown
                                        isSingle={true}
                                        id="statusDropdown"
                                        placeholder='All Country'
                                        displayValue="name"
                                        theme='search'
                                        intialValue={filters.countryId}
                                        result={e => { filter({ countryId: e.value }) }}
                                        options={CountryData}
                                    />
                                </div>
                            </div>
                        </span>


                        <span className='mx-2'>
                            {filters.continentId || filters.countryId || filters.currencyId || search ?
                                <a className="bg-primary leading-10 mr-3 h-10 flex items-center shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-large gap-2 btnreset" onClick={e => reset()}>
                                    Reset
                                </a>
                                : <></>}
                        </span>
                    </div>

                    <div className="dropdown1 addDropdown1 region_status w-32">

                        <Menu as="div" className="relative list_box_active_state ml-auto">
                            <div>
                                <Menu.Button className="inline-flex w-full border justify-center gap-x-1.5 rounded-large bg-white px-3 py-2.5 text-sm font-normal  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200">
                                    {filters.status ? filters.status == "deactive" ? "Inactive" : filters.status : 'All Status'}

                                    <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                                </Menu.Button>
                            </div>

                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className={`${dynamicStyle ? "" : "max-h-60"}  focus:!outline-[#481196] focus:!outline text-sm absolute z-40 ${className ? className : " min-w-[260px]"
                                    }  right-0 shadow-lg !py-2 !mt-1.5 overflow-auto bg-white  rounded-large scrollbar`}>
                                    <div className="mt-2">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a className={filters.status == '' ? 'text-gray-700 block px-4 py-2 text-sm active' : 'text-gray-700 block px-4 py-2 text-sm'} onClick={() => ChangeStatus("")}>All Status</a>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a className={filters.status == 'active' ? 'text-gray-700 block px-4 py-2 text-sm active' : 'text-gray-700 block px-4 py-2 text-sm'} onClick={() => ChangeStatus("active")} >Active</a>
                                            )}
                                        </Menu.Item>

                                        <Menu.Item className="divide-y-1 divide-gray-800 pt-1  mt-2">
                                            <p className="border-t"></p>
                                        </Menu.Item>


                                        <Menu.Item className="">
                                            {({ active }) => (
                                                <a className={filters.status == 'Inactive' ? 'text-gray-700 block px-4 py-2 text-sm active' : 'text-gray-700 block px-4 py-2 text-sm'} onClick={() => ChangeStatus("deactive")} >Inactive</a>
                                            )}
                                        </Menu.Item>

                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>




                    </div>


                </div>
                {tab == 'grid' ? <>
                    <div className="cardList">
                        <div className='grid grid-cols-12 gap-4'>
                            {!loaging && data && data.map((itm, i) => {
                                return <div className='col-span-12 md:col-span-6 xl:col-span-4 mb-4'>
                                    <div className='new_cards'>
                                        <div className='user_card'>
                                            <div className='user_detail' onClick={e => edit(itm.id)}>
                                                <img src={methodModel.userImg(itm.image)} className="user_imgs" />
                                                <div className='user_name'>
                                                    <h4 className='user'>
                                                        {itm.name}
                                                    </h4>
                                                    {/* <p className='user_info'>
                                                    {itm.email}
                                                </p> */}
                                                </div>
                                            </div>
                                            <div className={`user_hours ${itm.status}`} onClick={() => statusChange(itm)}>
                                                <span className='contract'>
                                                    {itm.status == 'deactive' ? 'inactive' : 'active'}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </> : <>
                    <div className="w-full bg-white rounded-lg">
                        <div className="scrollbar w-full overflow-auto">


                            <table class="w-full">
                                <thead className='border-b border-[#EAECF0]'>
                                    <tr className='border-b border-[#EAECF0]'>
                                        <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3' onClick={e => sorting('name')}>Region Name <HiOutlineArrowDown className="shrink-0 inline text-sm" /></th>
                                        <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3' onClick={e => sorting('countryName')}>Country<HiOutlineArrowDown className="shrink-0 inline text-sm" /></th>
                                        <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3' onClick={e => sorting('continentName')}>Continent<HiOutlineArrowDown className="shrink-0 inline text-sm" /></th>
                                        {/* <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3'onClick={e=>sorting('Currency')}>Currency <i className={sortClass('Currency')} /></th> */}
                                        <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3'>Status</th>
                                        <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!loaging && data && data.map((itm, i) => {
                                        return <tr className=''>
                                            <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]' onClick={e => edit(itm.id)}>{itm.name}</td>
                                            <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>{itm.country ? itm.country.name : ''}</td>
                                            <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'>{itm.continent ? itm.continent.name : ''}</td>
                                            {/* <td className=''>{itm.currencyId ? itm.currencyId.currency : ''}</td> */}
                                            {/* <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0] pointer'>{itm.productCount}</td> */}
                                            <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]'><div className={`user_hours ${itm.status}`} onClick={() => statusChange(itm)}>
                                                <Tooltip placement="top" title="Active / Inactive">
                                                    <span className='contract '>
                                                        {itm.status == 'deactive' ? 'inactive' : 'active'}
                                                    </span>
                                                </Tooltip>
                                            </div></td>
                                            <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]'>
                                                <div className="flex items-center justify-center gap-1.5">
                                                    {isAllow('editRegion') ?
                                                        <Tooltip placement="top" title="Edit">
                                                            <a className="border cursor-pointer border-primary hover:opacity-70 rounded-large bg-[#48119614] w-10 h-10 !text-primary flex items-center justify-center text-xl" onClick={e => edit(itm.id)}>
                                                                <FiEdit3 />
                                                            </a>
                                                        </Tooltip>
                                                        : <></>}
                                                    {isAllow('deleteRegion') ?
                                                        <Tooltip placement="top" title="Delete">
                                                            <span className='border cursor-pointer !border-[#E9253129] hover:opacity-70 rounded-lg bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl' onClick={() => deleteItem(itm.id)}>
                                                                <BsTrash3 />

                                                            </span>
                                                        </Tooltip>
                                                        : <></>}
                                                </div>
                                            </td>
                                            {/* end */}
                                        </tr>
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>}
                {!loaging && total == 0 ? <div className="py-3 text-center">No Data</div> : <></>}
                {
                    !loaging && total > filters.count ? <div className='paginationWrapper p-4 mt-15'>
                        {/* <span className='text-sm text-gray-600'>Show {filters.count} from {total} Region</span> */}
                        <Pagination
                            currentPage={filters.page}
                            totalSize={total}
                            sizePerPage={filters.count}
                            changeCurrentPage={pageChange}
                        />
                    </div> : <></>
                }
                {
                    loaging ? <div className="text-center py-4">
                        <img src="/assets/img/loader.gif" className="pageLoader" />
                    </div> : <></>
                }
            </div>
        </Layout >
    );
};

export default Html;
