import React from 'react';
import Layout from '../../components/global/layout';
import Pagination from "react-pagination-js";
import './style.scss';
import { Link } from 'react-router-dom';
import userTableModel from '../../models/table.model';
import methodModel from '../../methods/methods';
import datepipeModel from '../../models/datepipemodel';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Tooltip } from "antd";
import { HiOutlineArrowDown } from 'react-icons/hi';



const Html = ({
    sortClass,
    sorting,
    tab,
    edit,
    view,
    tabChange,
    colClick,
    ChangeRole,
    ChangeStatus,
    dynamicStyle = false,
    className = null,
    openModal,
    refresh,
    statusChange,
    pageChange,
    addCol,
    deleteItem,
    exportCsv,
    uTableCols,
    removeCol,
    filters,
    tableCols,
    loaging,
    data,
    types,
    exportfun,
    isAllow,
    total = { total }
}) => {
    return (
        <Layout>
            <div className="flex justify-between items-center">
                <div>
                    <h3 className="text-2xl font-semibold text-[#111827]">
                        Accounting System
                    </h3>
                    <p class="text-sm font-normal text-[#75757A]">Here you can see all about your     Accounting System</p>
                </div>


                <div className="flex">
                    {/* {isAllow('addBooking') ?
                        <Link className="btn btn-primary mr-2" to="/bookingSystem/add">
                            Add Booking System
                        </Link>
                        : <></>} */}

                   

                </div>


            </div>

            <div className="shadow-box w-full bg-white rounded-lg mt-6 border border-grey">
            <div className="dropdown1 addDropdown1 flex justify-end items-end p-4">

<Menu as="div" className="relative list_box_active_state ml-auto">
    <div>
        <Menu.Button className="inline-flex w-full border justify-center gap-x-1.5 rounded-large border border-grey bg-white px-3 py-2.5 text-sm font-normal text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200">
            {filters.status ? filters.status == "deactive" ? "Inactive" : filters.status : 'All Status'}
            <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
        </Menu.Button>
    </div>

    <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
    >
        <Menu.Items className={`${dynamicStyle ? "" : "max-h-60"}  focus:!outline-[#481196] focus:!outline text-sm absolute z-40 ${className ? className : " min-w-[260px]"
            }  right-0 shadow-lg !py-2 !mt-1.5 overflow-auto bg-white  rounded-large scrollbar`}>
            <div className="mt-2">
                <Menu.Item>
                    {({ active }) => (
                        <a className={filters.status == '' ? 'text-gray-700 block px-4 py-2 text-sm active' : 'text-gray-700 block px-4 py-2 text-sm'} onClick={() => ChangeStatus("")}>All Status</a>
                    )}
                </Menu.Item>
                <Menu.Item>
                    {({ active }) => (
                        <a className={filters.status == 'active' ? 'text-gray-700 block px-4 py-2 text-sm active' : 'text-gray-700 block px-4 py-2 text-sm'} onClick={() => ChangeStatus("active")} >Active</a>
                    )}
                </Menu.Item>

                <Menu.Item className="divide-y-1 divide-gray-800 pt-1  mt-2">
                    <p className="border-t"></p>
                </Menu.Item>


                <Menu.Item className="">
                    {({ active }) => (
                        <a className={filters.status == 'Inactive' ? 'text-gray-700 block px-4 py-2 text-sm active' : 'text-gray-700 block px-4 py-2 text-sm'} onClick={() => ChangeStatus("deactive")} >Inactive</a>
                    )}
                </Menu.Item>

            </div>
        </Menu.Items>
    </Transition>
</Menu>

</div>
                <div className="scrollbar w-full overflow-auto">


                    <table class="w-full">
                        <thead className='border-b border-[#EAECF0]'>
                            <tr className='border-b border-[#EAECF0]'>
                                <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-left bg-[#F7FAFF] !py-3' onClick={e => sorting('name')}>Name <span className='mr-2'><HiOutlineArrowDown className="shrink-0 inline text-sm" /></span></th>
                                <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3'>Status</th>
                                <th scope="col" className='cursor-pointer text-[#82838B] !border-l-0 font-normal text-sm !border border-[#EAECF0] px-3.5 text-center bg-[#F7FAFF] !py-3'>Action</th>

                            </tr>
                        </thead>
                        <tbody>
                            {!loaging && data && data.map((itm, i) => {
                                return <tr className=''>
                                    <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0]'><span className='flex items-center'><img width={60} height={50} src={methodModel.noImg(itm.logo)} className='cat_img ' /> <span className='ml-2'>{itm.name}</span></span></td>
                                    {/* <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-left border-[#EAECF0] pointer' onClick={e => edit(itm.id)}>{datepipeModel.datetime(itm.createdAt)}</td> */}
                                    <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]'> <div className={`user_hours ${itm.status}`} onClick={() => statusChange(itm)}>
                                        <Tooltip placement="top" title="Active / Inactive">
                                            <span className='contract'>
                                                {itm.status == 'deactive' ? 'inactive' : 'active'}
                                            </span>
                                        </Tooltip>
                                    </div></td>

                                    {/* dropdown */}
                                    <td className='!text-typo !border-l-0 cursor-pointer !px-3.5 text-sm font-normal !py-4 !border text-center border-[#EAECF0]'>
                                        <div className="flex items-center justify-center gap-1.5">
                                            <Tooltip placement="top" title="Edit">
                                                <a className="border cursor-pointer !border-[#E9253129] hover:opacity-70 rounded-large bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl" onClick={e => edit(itm.id)}>
                                                    <svg stroke="#481196" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M12 20h9"></path><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path></svg>

                                                </a>
                                            </Tooltip>
                                            {/* {isAllow('deleteBooking')?
                                            <span className='edit_icon mr-1' onClick={() => deleteItem(itm.id)}>
                                                <i class="material-icons delete" title='Delete'> delete</i>
                                            </span>
                                        :<></>} */}
                                            {/* {isAllow('refreshBooking')?
                                            <span className='edit_icon' onClick={() => refresh(itm.id)}>
                                                <i class="material-icons delete" title='Refresh'> refresh</i>
                                            </span>
                                        :<></>} */}
                                        </div>
                                    </td>
                                    {/* end */}
                                </tr>
                            })
                            }
                        </tbody>
                    </table>
                </div>
            </div>

            {!loaging && total == 0 ? <div className="py-3 text-center">No Data</div> : <></>}
            {
                !loaging && total > filters.count ? <div className='paginationWrapper mt-15'>
                    {/* <span>Show {filters.count} from {total} Booking System</span> */}
                    <Pagination
                        currentPage={filters.page}
                        totalSize={total}
                        sizePerPage={filters.count}
                        changeCurrentPage={pageChange}
                    />
                </div> : <></>
            }

            {loaging ? <div className="text-center py-4">
                <img src="/assets/img/loader.gif" className="pageLoader" />
            </div> : <></>}
        </Layout>
    );
};

export default Html;
