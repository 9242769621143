import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Layout from '../../components/global/layout';
import './style.scss';
import ApiClient from '../../methods/api/apiClient';
import datepipeModel from '../../models/datepipemodel';
import DateRangePicker from '../../components/common/DateRangePicker';
import LineChart from '../../components/common/LineChart';
import BarChart from '../../components/common/BarChart';
import pipeModel from "../../models/pipeModel";
import SelectDropdown from "../../components/common/SelectDropdown";
import loader from '../../methods/loader';
import { MdTrendingDown, MdTrendingUp } from 'react-icons/md';
import  Table from "../../components/Table";
const Dashboard = () => {
  const user = useSelector((state) => state.user);
  const [filters, setFilter] = useState({ startDate: '', endDate: '', compare: '' })
  const [TableFilter, setTableFilter] = useState({ page: 1, count: 30 })

  const [activeSubscriptions, setActiveSubscriptions] = useState([])
  const [activeSubscriptionsC, setActiveSubscriptionsC] = useState([])
  const [newSubsctiptions, setNewSubsctiptions] = useState([])
  const [newSubsctiptionsC, setNewSubsctiptionsC] = useState([])
  const [chartData, setChartData] = useState({ netRevenue: [], recurringRevenue: [], customers: [] ,netRevenueChurn:[]})
  const [chartDataC, setChartDataC] = useState({ netRevenue: [], recurringRevenue: [], customers: [],netRevenueChurn:[] })
  const [SubscriptionCount, setSubscriptionCount] = useState(0);
  const [SubscriptionCountC, setSubscriptionCountC] = useState(0);
  const [SubscriptionData, setSubscriptionData] = useState([]);
  const [SubscriptionDataC, setSubscriptionDataC] = useState([]);

  const getData = (p = {}, compare = false) => {
    let f = {
      ...filters,
      ...p,
      type: 'monthly'
    }
    ApiClient.get('api/admin/dashboard', f).then(res => {
      if (res.success) {
        const mapF = (itm) => {
          return {
            ...itm,
            date: `${datepipeModel.monthfind(itm.month - 1)}/${itm.year}`
          }
        }

        const sortF = (a, b) => {
          if (a.month + a.year < b.month + b.year) //sort string ascending
            return -1;
          if (a.month + a.year > b.month + b.year)
            return 1;
          return 0; //default return value (no sorting)
        }

        let activeSubscriptions = res.activeSubscriptions.map(mapF).sort(sortF)
        let newSubsctiptions = res.newSubsctiptions.map(mapF).sort(sortF)
        res.netRevenue = res.netRevenue.map(mapF).sort(sortF)
        res.netRevenueChurn = res.netRevenue.map((itm,i)=>{
          let begin=res.netRevenue?.[i-1]?.netRevenue||itm.netRevenue
          let end=itm.netRevenue

          let count=((begin-end)/begin)||0
          return {
            ...itm,
            count:count<0?null:count.toFixed(2),
            count2:count<0?count.toFixed(2):null
          }
        })
        res.recurringRevenue = res.recurringRevenue.map(mapF).sort(sortF)
        res.activeCustomers = res.activeCustomers.map(mapF).sort(sortF)
        res.reActiveCustomers = res.reActiveCustomers.map(mapF).sort(sortF)
        res.newCustomer = res.newCustomer.map(mapF).sort(sortF)


        let barrAll = [
          ...res.activeCustomers,
          ...res.reActiveCustomers,
          ...res.newCustomer
        ].sort(sortF)

        barrAll = [...new Set([
          ...barrAll.map(itm => itm.date),
        ])]

        barrAll = barrAll.map(itm => {
          return {
            date: itm,
            newUsers: res.newCustomer.find(sitm => sitm.date == itm)?.newUsers || 0,
            activecustomers: res.activeCustomers.find(sitm => sitm.date == itm)?.activecustomers || 0,
            reActiveCustomers: res.reActiveCustomers.find(sitm => sitm.date == itm)?.activecustomers || 0,
          }
        })

       

        res.customers = barrAll

        if (!compare) {
          setActiveSubscriptions(activeSubscriptions)
          setNewSubsctiptions(newSubsctiptions)
          setChartData(res)
        } else {
          setActiveSubscriptionsC(activeSubscriptions)
          setNewSubsctiptionsC(newSubsctiptions)
          setChartDataC(res)
        }
      }
    })
  }


  const getDataSub = (p = {}, compare = false) => {
    let f = {
      ...filters,
      ...TableFilter,
      ...p,
    }
    loader(true)
    ApiClient.get('api/all/subscriptions', f).then(res => {
      if (res.success) {

        if (!compare) {
          setSubscriptionData(res.data)
          setSubscriptionCount(res.total);
        } else {
          setSubscriptionDataC(res.data)
          setSubscriptionCountC(res.total);
        }
      }
      loader(false)
    })
  }
  const filter = (p = {}) => {
    let f = {
      ...filters,
      ...p
    }
    setFilter({...f})
    getData(f)
    getDataSub(f)

    if (f.compare) {
      let cf={
        ...f,
        startDate:f.compareStart,
        endDate:f.compareEnd,
      }
      getData(cf, true)
      getDataSub(cf, true)
    }
  }


  const yearTypeChange = (p, sfilter) => {
    let f = {
      startDate: '',
      endDate: '',
      ...sfilter,
      compare: ''
    }
    if (p == 'financial') {
      let financialYear = user.financialYear || '1'
      financialYear = Number(financialYear) < 10 ? '0' + financialYear : financialYear
      let year = new Date().getFullYear()
      let endDate = new Date(`${year}-${financialYear}-01`)
      endDate = endDate.setDate(endDate.getDate() - 1)
      f.startDate = `${year - 1}-${financialYear}-01`
      f.endDate = datepipeModel.datetostring(endDate)
    } else {
      let year = new Date().getFullYear()
      let month = new Date().getMonth() + 1
      let endDate = new Date(`${year}-${month}-1`)
      endDate.setMonth(month)
      endDate.setDate(0)
      let compareEnd = new Date(`${year}-${month}-01`).setDate(0)
      compareEnd = datepipeModel.datetostring(compareEnd)
      f.startDate = `${year}-${month}-01`
      f.endDate = datepipeModel.datetostring(endDate)
      f.compare = 'Previous Month'
      let compareStart = new Date(`${year}-${month}-01`)
      compareStart.setMonth(month - 2)

      // f.compareStart = datepipeModel.datetostring(compareStart)
      // f.compareEnd = `${compareEnd}`
      f.compare = ''
      f.compareStart = ''
      f.compareEnd = ''

    }

    filter({ ...f, yearType: p })
  }

  const reset = () => {
    let f = { forward: false, yearType: 'calendar' }
    yearTypeChange('calendar', f)
  }

  const onRangeChange = (e) => {
    let f = {
      startDate: datepipeModel.datetostring(e.startDate),
      endDate: datepipeModel.datetostring(e.endDate),
      compare: e.compare,
      compareStart: e.compareStart,
      compareEnd: e.compareEnd,
    }
    if (datepipeModel.datetostring(e.startDate) == datepipeModel.datetostring(e.endDate)) {
      setFilter({ ...filters, ...f })
    } else {
      filter({ ...f })
    }
  }

  useEffect(() => {
    reset()
  }, [])

  const Grade = ({ av, bv, check = false }) => {
    let value = 0;
    let a = Number(av || 0);
    let b = Number(bv || 0);
    let total = a + b;
    value = (a / total) * 100;
    let html = (
      <>
        <span className="text-[#047857] bg-[#04785714] p-[5px] rounded-[30px] text-xs mr-1.5">
          <MdTrendingUp className="inline-block" />+
          {value ? value.toFixed(2) : "0"}%
        </span>
      </>
    );

    if (b > a) {
      html = (
        <>
          <span className="text-[#e92531] bg-[#e9253133] p-[5px] rounded-[30px] text-xs mr-1.5">
            <MdTrendingDown className="inline-block" />-
            {value ? value.toFixed(2) : "0"}%
          </span>
        </>
      );
    }
    return html;
  };

  const columns = [
    {
        key: 'userName', name: 'Customer Name',sort:true,
        render: (row) => {
            return <span className=''>{row?.userName}</span>
        }
    },
    {
      key: 'userEmail', name: 'Customer Email',sort:true,
      render: (row) => {
          return <span className=''>{row?.userEmail}</span>
      }
  },
    {
        key: 'planDetail', name: 'Plan Name',
        render: (row) => {
            return <>{row?.planDetail?.name}</>
        }
    },
    {
      key: 'planInterval', name: 'Plan Period',
      render: (row) => {
          return <>{row.planInterval}{" "} {row?.planType}</>
      }
    },
    {
      key: 'validUpTo', name: 'Valid Upto',sort:true,
      render: (row) => {
          return <>{datepipeModel.date(row.validUpTo)}</>
      }
    },
    {
      key: 'amount', name: 'Amount',sort:true,
      render: (row) => {
          return <>{pipeModel.number(row?.amount)}</>
      }
    },
    {
      key: 'status', name: 'Status',
      render: (itm) => {
          return <div className={`user_hours ${itm.status=='active' ? 'active' : 'deactive'}`} >
       
            <span className='contract'>
              {itm.status=='active' ? 'Active' : 'Inactive'}
            </span>
        
        </div>
      }
    },
]


const filterSub=(f={})=>{
  setTableFilter({ ...TableFilter, ...f });
  getDataSub({...f })
}

const sorting = (key) => {
  let sorder = 'asc'
  if (TableFilter.key == key) {
      if (TableFilter.sorder == 'asc') {
          sorder = 'desc'
      } else {
          sorder = 'asc'
      }
  }
  let sortBy = `${key} ${sorder}`;
  let f={
    sortBy,
    key,
    sorder
  }
  filterSub(f)
}

  return (
    <Layout>

      <div className='main_dashboard'>
        <div className='flex items-center justify-between mb-4 md:mb-8 lg:mb-12'>
          <div className='text-2xl font-semibold text-[#3C3E49]'>Dashboard</div>
          <div className='flex gap-2'>
            <DateRangePicker
              value={{
                startDate: filters.startDate,
                endDate: filters.endDate,
                compare:filters.compare,
                compareStart:filters.compareStart,
                compareEnd:filters.compareEnd,
              }}
              onChange={(e) => onRangeChange(e)}
              fullWidth={false}
              isCompare={true}
            />
            <button type='button' onClick={reset} className='bg-primary leading-10 mr-3 h-10 flex items-center shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-large gap-2'>Reset</button>
          </div>
        </div>

        <div className='row_charts'>
          <div className='grid grid-cols-12'>
            <div className='col-span-12 md:col-span-6 mr-2 mb-3'>
              <div className='layout_new'>

                <div className=''>
                  <h2 className='text-typo text-md font-semibold mb-2 text-gray-500'>Monthly Recurring Revenue</h2>
                  <h4 className=' text-2xl font-extrabold	mb-2 text-gray-700'>{pipeModel.currency(chartData?.totalrecurringRevenue)}</h4>

                  {filters.compare ? <>
                    <div className='right_layout'>
                      <Grade
                        av={chartData?.totalrecurringRevenue}
                        bv={chartDataC?.totalrecurringRevenue}
                      />
                      <p className='text_light'>{filters.compare}</p>
                    </div>
                  </> : <></>}


                </div>

                <LineChart
                  legends={[{ label: 'Monthly Recurring Revenue', key: 'totalactiveUserSubscriptions' }]}
                  data={chartData.recurringRevenue}
                  hideLegends={true}
                />
              </div>

            </div>
            <div className='col-span-12 md:col-span-6 ml-2 mb-3'>
              <div className='layout_new'>
                <div className=''>
                  <h2 className='text-typo text-md font-semibold mb-2 text-gray-500'>Net Revenue</h2>
                  <h4 className=' text-2xl font-extrabold	mb-2 text-gray-700'>  {pipeModel.currency(chartData?.totalnetRevenue)}</h4>

                  {filters.compare ? <>
                    <div className='right_layout'>
                      <Grade
                        av={chartData?.totalnetRevenue}
                        bv={chartDataC?.totalnetRevenue}
                      />
                      <p className='text_light'>{filters.compare}</p>
                    </div>
                  </> : <></>}
                </div>
                <LineChart
                  legends={[{ label: 'Net Revenue', key: 'netRevenue' }]}
                  data={chartData.netRevenue}
                  hideLegends={true}
                />
              </div>
            </div>
            <div className='col-span-12 md:col-span-8 mr-2 mb-3'>
              <div className='layout_new'>
                <div className='layout_header mb-3'>
                  <h2 className='text-typo text-md font-semibold mb-2 text-gray-500'>Monthly Recurring Revenue Growth</h2>
                  {/* <div class="dropdown">
                    <button class="btn btn_dropdown dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                      12 Months
                    </button>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" href="#">Action</a>
                      <a class="dropdown-item" href="#">Another action</a>
                      <a class="dropdown-item" href="#">Something else here</a>
                    </div>
                  </div> */}
                </div>
                <LineChart
                  legends={[{ label: 'Monthly Recurring Revenue', key: 'totalactiveUserSubscriptions' }]}
                  data={chartData.recurringRevenue}
                  hideLegends={true}
                />
              </div>
            </div>
            <div className='col-span-12 md:col-span-4 ml-2 mb-3 '>
              <div className='layout_new h-full'>
                <div className='layout_header mb-3'>
                  <h2 className='text-typo text-md font-semibold mb-2 text-gray-500'>Breakdown</h2>
                  {/* <div class="dropdown">
                    <button class="btn btn_dropdown dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                      Everything
                    </button>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" href="#">Action</a>
                      <a class="dropdown-item" href="#">Another action</a>
                      <a class="dropdown-item" href="#">Something else here</a>
                    </div>
                  </div> */}
                </div>
                <div className='row_data'>
                  <div className='border_row'>
                    <div className='number_datarow'>
                      <div>
                        <span className='number_text'>{pipeModel.number(chartData?.newTrialsCount)}</span>
                        <span className='text_data'>New Trials</span>
                      </div>
                      <span className=''></span>
                    </div>
                  </div>
                  <div className='border_row'>
                    <div className='number_datarow'>
                      <div>
                        <span className='number_text'>{pipeModel.number(chartData?.newCustomerCount)}</span>
                        <span className='text_data'>New Customers</span>
                      </div>
                      <span className=''></span>
                    </div>
                  </div>
                  <div className='border_row'>
                    <div className='number_datarow'>
                      <div>
                        <span className='number_text'>{pipeModel.number(chartData?.totalactiveCustomers)}</span>
                        <span className='text_data'>Active Customers</span>
                      </div>
                      <span className=''></span>
                    </div>
                  </div>
                  <div className='border_row'>
                    <div className='number_datarow'>
                      <div>
                        <span className='number_text'>{pipeModel.number(chartData?.totalReactiveCustomers)}</span>
                        <span className='text_data'>Reactive Customers</span>
                      </div>
                      <span className=''></span>
                    </div>
                  </div>
                  {/* <div className='number_datarow number_datarow2'>
                    <div>
                      <span className='text_data'>Total change</span>
                    </div>
                    <span className='green_data'>+ $109.50</span>
                  </div> */}


                </div>
              </div>

            </div>
            <div className='col-span-12 md:col-span-4 mr-2 mb-3'>
              <div className='layout_new'>

                <div className=''>
                  <h2 className='text-typo text-md font-semibold mb-2 text-gray-500'>Revenue Churn</h2>
                  <h4 className=' text-2xl font-extrabold	mb-2 text-gray-700'> {pipeModel.currency(chartData?.totalrecurringRevenue)}</h4>

                  <div className='right_layout'>
                    {/* <h3><span class="material-icons green_icon">north_east</span>2.9%</h3>
                    <p className='text_light'>In Selected Period</p> */}
                  </div>
                </div>
                <BarChart
                  legends={[{ label: 'Revenue Churn Upgrade', key: 'count' },
                  { label: 'Revenue Churn Downgrade', key: 'count2' }
                ]}
                  data={chartData.netRevenueChurn}
                  hideLegends={true}
                />
              </div>
            </div>
            <div className='col-span-12 md:col-span-8 ml-2 mb-3'>
              <div className='layout_new h-full'>
                <div className='layout_header mb-3'>
                  <h2 className='text-typo text-md font-semibold mb-2 text-gray-500'>Customer Overview</h2>
                  {/* <div class="dropdown">
                    <button class="btn btn_dropdown dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                      Days
                    </button>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" href="#">Action</a>
                      <a class="dropdown-item" href="#">Another action</a>
                      <a class="dropdown-item" href="#">Something else here</a>
                    </div>
                  </div> */}
                </div>
                <div className='row'>
                  <div className='col-md-3'>
                    <div>
                      <div className='left_ser'>
                        <i class="fa fa-circle circle1" aria-hidden="true"></i>
                        <div>
                          <p>New Customer</p>
                          <h3>{pipeModel.number(chartData?.newCustomerCount)}</h3>
                        </div>
                      </div>
                      <div className='left_ser'>
                        <i class="fa fa-circle circle2" aria-hidden="true"></i>
                        <div>
                          <p>Active Customer</p>
                          <h3>{pipeModel.number(chartData?.totalactiveCustomers)}</h3>
                        </div>
                      </div>
                      <div className='left_ser'>
                        <i class="fa fa-circle circle3" aria-hidden="true"></i>
                        <div>
                          <p>Reactive Customer</p>
                          <h3>{pipeModel.number(chartData?.totalReactiveCustomers)}</h3>
                        </div>
                      </div>
                      <div className='left_ser'>
                        <i class="fa fa-circle circle3" aria-hidden="true"></i>
                        <div>
                          <p>Total Customer</p>
                          <h3>{pipeModel.number(chartData?.total_customers)}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-9'>
                    <LineChart
                      legends={[
                        { label: 'New Customer', key: 'newUsers' },
                        { label: 'Active Customer', key: 'activecustomers' },
                        { label: 'Reactive Customer', key: 'reActiveCustomers' },
                      ]}
                      data={chartData?.customers}
                      hideLegends={false}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-span-12 md:col-span-6 mb-3 mr-2'>
              <div className='layout_new'>
                <div className=''>
                  <h2 className='text-typo text-md font-semibold mb-2 text-gray-500'>New Subscriptions</h2>
                  <h4 className=' text-2xl font-extrabold	mb-2 text-gray-700'>{pipeModel.currency(chartData?.totalNewSubscription)}</h4>
                  {filters.compare ? <>
                    <div className='right_layout'>
                      <Grade
                        av={chartData?.totalNewSubscription}
                        bv={chartDataC?.totalNewSubscription}
                      />
                      <p className='text_light'>{filters.compare}</p>
                    </div>
                  </> : <></>}
                </div>
                <LineChart
                  legends={[{ label: 'New Subscriptions', key: 'newSubscriptions' }]}
                  data={newSubsctiptions}
                  hideLegends={true}
                />
              </div>
            </div>
            <div className='col-span-12 md:col-span-6 mb-3 ml-2'>
              <div className='layout_new'>
                <div className=''>
                  <h2 className='text-typo text-md font-semibold mb-2 text-gray-500'>Active Subscriptions</h2>
                  <h4 className=' text-2xl font-extrabold	mb-2 text-gray-700'>{pipeModel.currency(chartData?.totalActiveSubscription)}</h4>
                  {filters.compare ? <>
                    <div className='right_layout'>
                      <Grade
                        av={chartData?.totalActiveSubscription}
                        bv={chartDataC?.totalActiveSubscription}
                      />
                      <p className='text_light'>{filters.compare}</p>
                    </div>
                  </> : <></>}
                </div>
                <LineChart
                  legends={[{ label: 'Active Subscriptions', key: 'totalactiveUserSubscriptions' }]}
                  data={activeSubscriptions}
                  hideLegends={true}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='layout_new'>
          <div className=''>
            <div className='flex mb-4'>
            <h2 className='text-typo text-md font-semibold mb-0 text-gray-500 mr-auto'>All Subscription</h2>

<SelectDropdown
intialValue={TableFilter.status}
placeholder='All Status'
options={[
  {id:'active',name:'Active'},
  {id:'deactive',name:'Inactive'},
]}
result={e=>{
  filterSub({status:e.value})
}}
/>

            </div>
           

            <Table
                        className='mb-3'
                        data={SubscriptionData}
                        columns={columns}
                        page={TableFilter.page}
                        count={TableFilter.count}
                        total={SubscriptionCount}
                        result={(e) => {
                            if (e.event == 'page'){
                              setTableFilter({ ...TableFilter, page: e.value }); getDataSub({ page: e.value })
                            }
                            if (e.event == 'sort') sorting(e.value)
                        }}
                    />

          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;