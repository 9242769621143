import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ApiClient from '../../methods/api/apiClient';
// import './style.scss';


import loader from '../../methods/loader';
import userTableModel from '../../models/table.model';
import Html from './html';
import { CategoryType } from '../../models/type.model';
import {useNavigate } from 'react-router-dom';
import axios from 'axios';
import environment from '../../environment';
import { CostingAPIS, CostingURL } from './Api';

const CostingTemplates = (p) => {
  let user = useSelector(state => state.user)
  const searchState = useSelector((state) => state.search);
  const [filters, setFilter] = useState({ page: 1, count: 50, search: '', catType: ''})
  const [data, setData] = useState([])
  const [tab, setTab] = useState('list')
  const [total, setTotal] = useState(0)
  const [loaging, setLoader] = useState(false)
  const [tableCols, setTableCols] = useState([])
  const [form, setform] = useState(CategoryType)
  const history = useNavigate()
  useEffect(() => {
    let cols = []
    for (let i = 0; i <= 2; i++) {
      cols.push(userTableModel.category[i])
    }
    setTableCols(cols)
  }, [])

  useEffect(() => {
    if (user && user.loggedIn) {
      setFilter({ ...filters, search: searchState.data })
      getData({ search: searchState.data, page: 1 })
    }
  }, [searchState])


  const uTableCols = () => {
    let exp = []
    if (tableCols) exp = tableCols
    let value = []
    userTableModel.category.map(itm => {
      if (itm != exp.find(it => it.key == itm.key)) {
        value.push(itm)
      }
    })
    return value
  }

  const addCol = (itm) => {
    setTableCols([...tableCols, itm])
  }


  const removeCol = (index) => {
    let exp = tableCols
    exp.splice(index, 1);
    setTableCols([...exp])
  }

  const getData = (p = {}) => {
    loader(true)
    let filter = {
      ...filters, ...p
    }
 
 

    ApiClient.get(CostingAPIS.list,  filter).then(res=>{
      loader(false)
      if (res.success) {
        const Newdata = res.data.map(itm => {
          itm.id = itm._id
          return itm
        })
        setData([...Newdata]);
        setTotal(res.total)
      }
    })
  };
  


  const clear = () => {
    getData({ search: '', page: 1 })
    setFilter({ ...filters, search: '', page: 1 })
  }

  const deleteItem = (id) => {
    if (window.confirm("Do you want to delete this")) {
      loader(true)
      ApiClient.delete(CostingAPIS.delete, { id: id }).then(res => {
        if (res.success) {
          clear()
          // ToastsStore.success(res.message)
        }
        loader(false)
      })
    }
  }



  const pageChange = (e) => {
    setFilter({ ...filters, page: e })
    getData({ page: e })
  }

  const openModal = (itm) => {
    let extra = new Date().getTime()
    setform({ ...itm, extra })
    document.getElementById("openuserModal").click()
  }

  const ChangeRole = (e) => {
    setFilter({ ...filters, catType: e, page: 1 })
    getData({ catType: e, page: 1 })
  }
  const ChangeStatus = (e) => {
    setFilter({ ...filters, status: e, page: 1 })
    getData({ status: e, page: 1 })
  }

  const colClick = (col, itm) => {
    if (col.key == 'healthClinicId') {
    }
  }

  const statusChange = (itm) => {
    let modal = 'category'
    let status = 'active'
    if (itm.status == 'active') status = 'deactive'

    if (window.confirm(`Do you want to ${status == 'active' ? 'Activate' : 'Deactivate'} this`)) {
      loader(true)
      ApiClient.put(CostingAPIS.statusChange, { id: itm.id, status }).then(res => {
        if (res.success) {
          getData()
        }
        loader(false)
      })
    }
  }
  const view = (id) => {
    history(CostingURL.edit+"/" + id)
  }

  const edit = (id) => {
    history(`${CostingURL.edit}/${id}`)
  }

  const copy = (id) => {
    history(`${CostingURL.copy}?copyId=${id}`)
  }



  const exportfun = async () => {
    const token = await localStorage.getItem("token");
    let filter = {
      ...filters,
    }
    const req = await axios({
      method: "get",
      url: `${environment.api}${CostingAPIS.export}`,
      responseType: "blob",
      params:filter,
      body: { token: token }
    });
    var blob = new Blob([req.data], {
      type: req.headers["content-type"],
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `Costing Templates.xlsx`;
    link.click();
  }
  const tabChange = (tab) => {
    setTab(tab)
  }

  const filter = (p = {}) => {
    setFilter({ ...filters, page: 1, ...p })
    getData({ page: 1, ...p })
    // getProducts(p)
  }

  const sortClass = (key) => {
    let cls = 'fa-sort'
    if (filters.key == key && filters.sorder == 'asc') cls = 'fa-sort-up'
    else if (filters.key == key && filters.sorder == 'desc') cls = 'fa-sort-down'
    return 'fa ' + cls
  }

  const sorting = (key) => {
    let sorder = 'asc'
    if (filters.key == key) {
      if (filters.sorder == 'asc') {
        sorder = 'desc'
      } else {
        sorder = 'asc'
      }
    }

    let sortBy = `${key} ${sorder}`;
    filter({ sortBy, key, sorder })
  }

  const reset = () => {
    let f = {
      search: '',
      status: '',
      isEnabled:''
    }
    setFilter({ ...filters, ...f })
    getData(f)
  }

  useEffect(()=>{
getData()
  },[])


  const EnablestatusChange = (itm) => {
    let status = 'Enable'
    if (itm.isEnabled) status = 'Disable'

    if (window.confirm(`Do you want to ${status} this`)) {
      loader(true)
      // Status Change APi For Country
      ApiClient.put(`api/template/product/expense/enable/change`, { id: itm.id, isEnabled: !itm?.isEnabled }).then(res => {
        if (res.success) {
          getData()
        }
        loader(false)
      })
    }
  }
  const EnableChangeStatus = (e) => {
    setFilter({ ...filters, isEnabled: e, page: 1 })
    getData({ isEnabled: e, page: 1 })
  }
  return <><Html
    view={view}
    filter={filter}
    EnableChangeStatus={EnableChangeStatus}
    reset={reset}
    setFilter={setFilter}
    edit={edit}
    copy={copy}
    EnablestatusChange={EnablestatusChange}
    colClick={colClick}
    tabChange={tabChange}
    exportfun={exportfun}
    tab={tab}
    ChangeRole={ChangeRole}
    ChangeStatus={ChangeStatus}
    openModal={openModal}
    pageChange={pageChange}
    addCol={addCol}
    deleteItem={deleteItem}
    uTableCols={uTableCols}
    removeCol={removeCol}
    filters={filters}
    tableCols={tableCols}
    loaging={loaging}
    data={data}
    total={total}
    statusChange={statusChange}
    sortClass={sortClass}
    sorting={sorting}
  />
  </>;
};

export default CostingTemplates;
