import { useEffect, useState } from 'react';
import Layout from '../../components/global/layout';
import ApiClient from '../../methods/api/apiClient';
import methodModel from '../../methods/methods';
import loader from '../../methods/loader';

const LogoSettings = () => {
    const [logoPreview, setLogoPreview] = useState(null);
    const [Loading,setLoading]=useState(false)
    const [faviconPreview, setFaviconPreview] = useState(null);
    const [logoError, setLogoError] = useState('');
    const [faviconError, setFaviconError] = useState('');
    const [ButtonDisabled,setButtonDisabled]=useState(true)
    const [LogoLoading, setLogoLoading]=useState(false)
    const [IconLoading,setIconLoading]=useState(false);

    useEffect(()=>{
        loader(true)
        ApiClient.get('api/setting/site').then(res=>{
            if(res.success){
                setLogoPreview(res?.data?.logo||'')
                setFaviconPreview(res?.data?.fav_icon || '');
            }
            loader(false)
        })
    },[])
    const handleLogoChange = (event) => {
        setButtonDisabled(false)
        const file = event.currentTarget.files[0];

        if (file) {
            
            if (file.size > 1024 * 1024) {
                setLogoError('File size should be less than 1MB');
            } else {
                setLogoError('');
                setLogoLoading(true)
                ApiClient.postFormData(`api/upload/image?modelName=users`,{file:file}).then(res=>{
                    if (res.filePath){
                        setLogoPreview(res?.fileName);
                    }
                    setLogoLoading(false)
                })
                
            }
            
        }
    };

    const handleFaviconChange = (event) => {
        setButtonDisabled(false)
        const file = event.currentTarget.files[0];
        if (file) {
            
            if (file.size > 1024 * 1024) {
                setFaviconError('File size should be less than 1MB');
            } else {
                setFaviconError('');
                setIconLoading(true)
                ApiClient.postFormData(`api/upload/image?modelName=users`, { file: file }).then(res => {
                    if (res.filePath) {
                        setFaviconPreview(res?.fileName);
                    }
                    setIconLoading(false)
                })
                
            }
            
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLogoError(null)
        setFaviconError(null);
        
        if(!logoPreview||!faviconPreview){
            if(!logoPreview){
                setLogoError('Logo is required')
            }else{
                if(!faviconPreview){
                    setFaviconError('Favicon is required')
                }
            }
            return 
        }
        setLoading(true)
        localStorage.setItem('logo',logoPreview)
        ApiClient.post(`api/setting/site/set`, { logo: logoPreview, fav_icon:faviconPreview}).then(res=>{
            if(res.success){
                document.getElementById('iconData').href = methodModel.userImg(faviconPreview)
setButtonDisabled(true)
            }
            setLoading(false)
        })
    };

    return (
        <div>
            <Layout>
            <h2 className="text-xl font-semibold mb-4">Website Settings</h2>
            <div className="bg-white p-6 rounded-lg border border-gray-300">
    <form onSubmit={handleSubmit}>
        <div className="grid lg:grid-cols-2 gap-6">
            {/* Logo Upload Section */}
            <div className="mb-6 flex items-start gap-4 border-b border-gray-300 pb-6 flex-col-reverse">
                <label htmlFor="logo" className="block font-medium cursor-pointer">
                    <input
                        id="logo"
                        name="logo"
                        type="file"
                        accept="image/*"
                        onChange={handleLogoChange}
                        className="hidden"
                    />
                    <p className={`bg-[#481196] text-white px-4 py-2 rounded-md inline-flex ${LogoLoading ? 'disabled:cursor-not-allowed' : ''}`}>
                        {LogoLoading ? 'Uploading...' : 'Upload Logo'}
                    </p>
                </label>
                {logoPreview && (
                    <div className="border border-gray-400 p-4 rounded-lg mt-4">
                        <img src={methodModel.userImg(logoPreview)} alt="Logo Preview" className="h-12" />
                    </div>
                )}
                {logoError && <div className="text-red-500 mt-1">{logoError}</div>}
            </div>

            {/* Favicon Upload Section */}
            <div className="mb-6 flex items-start gap-4 border-b border-gray-300 pb-6 flex-col-reverse">
                <label htmlFor="favicon" className="block font-medium cursor-pointer">
                    <input
                        id="favicon"
                        name="favicon"
                        type="file"
                        accept="image/*"
                        onChange={handleFaviconChange}
                        className="hidden"
                    />
                    <p className={`bg-[#481196] text-white px-4 py-2 rounded-md inline-flex ${IconLoading ? 'disabled:cursor-not-allowed' : ''}`}>
                        {IconLoading ? 'Uploading...' : 'Upload Favicon'}
                    </p>
                </label>
                {faviconPreview && (
                    <div className="border border-gray-400 p-4 rounded-lg mt-4">
                        <img src={methodModel.userImg(faviconPreview)} alt="Favicon Preview" className="h-12 rounded-lg" />
                    </div>
                )}
                {faviconError && <div className="text-red-500 mt-1">{faviconError}</div>}
            </div>
        </div>

        {/* Submit Button */}
        <div className="flex justify-end">
            <button
                type="submit"
                className={`bg-[#481196] text-white px-4 py-2 rounded-md ${ButtonDisabled ? 'disabled:cursor-not-allowed' : ''}`}
                disabled={ButtonDisabled}
            >
                {Loading ? 'Saving...' : 'Save'}
            </button>
        </div>
    </form>
</div>

            </Layout>
            {Loading?<div  className="loaderDiv ">
                <div>
                    <img src="/assets/img/loader.gif" alt="logo" className="loaderlogo" />
                </div>
            </div>:null}
        </div>
    );
};

export default LogoSettings;
